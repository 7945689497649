import React, { useState, useEffect } from 'react';
import styles from './EditPasswordModal.module.scss';
import Button from '../../../components/Button/Button';
import MainHeader from '../../../components/Headers/MainHeader';
import useFormValidation from "../../../hooks/useFormValidation";
import validatePasswordReset from "../../../hooks/validatePasswordReset";
import { useAuth0 } from '../../../auth/Auth';
import { postData } from '../../../api/api';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import history from "../../../history";

Modal.setAppElement('#root');

const EditPasswordModal = (props) => {

    const INITIAL_STATE = {
        email: props.userData.Profile.Email || ""
    };

    const [type, setType] = useState("");
    const [error, setError] = useState("");

    const { toggleModal,
        showPasswordModal,
        togglePasswordModal,
        toggleEmailModal,
        fetchUser,
        token
    } = props;

    const { resetPassword, user } = useAuth0();

    const authenticateUser = async () => {
        const { email } = values
        if (type === "reset") {
            console.log("RESET")
            await resetPassword(email);
            toggleModal();
            togglePasswordModal();
        }
        else if (type === "change") {
            console.log("email props", props.userData.Profile.Email)
            console.log("email", email)
            if (props.userData.Profile.Email !== email) {
                const params = {
                    token
                }

                const postRequestBody = {
                    "email": email
                };

                await postData(postRequestBody, params, "email").then(
                    response => {
                        if (JSON.parse(response).Error) {
                            setError("Please try a valid email")
                            return "Error";
                        } else {
                            fetchUser(token);
                            toggleModal();
                            toggleEmailModal();
                        }
                    })
            } else {
                toggleModal();
            }
        }
    }

    useEffect(() => {
        if (type === "reset" || type === "change") {
            authenticateUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);


    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
    } = useFormValidation(INITIAL_STATE, validatePasswordReset, authenticateUser);


    const { text, content, input, bottomButtons,
        headerContainer, errorInput, errorText, modal, overlay, cancel, finePrint } = styles;


    return (
        <Modal isOpen={showPasswordModal} className={modal} overlayClassName={overlay}>
            <div className={styles.previewComponent}>
                <div className={content}>
                    <div className={headerContainer}>
                        <MainHeader text="Update your login" styleName="mainHeaderAltModal" closeTimeoutMS={500} />
                    </div>
                    <div className={content}>
                    {user.sub.slice(0, 4) === "auth" && <><div className={text}>Reset your password or change your email based on selection</div>
                        {errors.email && <p className={errorText}>*{errors.email}</p>}
                        {error && <div className={errorText}>{error}</div>}
                        <div className={finePrint}>*Required</div>
                        <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            value={values.email}
                            className={classNames(errors.email && errorInput, input)}
                            autoComplete="off"
                            id="login-email"
                            placeholder="Your email address"
                        />
                        <div className={bottomButtons}>
                            <Button
                                text="Save"
                                styleName="buttonSmaller"
                                onClick={(event) => {
                                    setType("change")
                                    handleSubmit(event, "change")
                                }}
                            />
                            <Button
                                onClick={(event) => {
                                    setType("reset")
                                    handleSubmit(event, "reset")
                                }}
                                text="Send Reset Email"
                                styleName="buttonAlternateSmall"
                            />
                        </div>
                        </>}
                        <div className={text}>
                        Disconnect from
                        {user.sub.slice(0, 4) === "auth" && <span> Email & Password </span>}
                        {user.sub.slice(0, 5) === "apple"  && <span> Apple </span>}
                        {user.sub.slice(0, 6) === "google" && <span> Google </span>}
                        {user.sub.slice(0, 8) === "facebook" && <span> Facebook </span>}
                        and choose a new Wellthon Login.
                        </div>
                        <div className={bottomButtons}>
                            <Button
                                text="Change Login"
                                styleName="buttonSmaller"
                                onClick={(event) => {
                                    history.push('/change-login')
                                }}
                            />
                        </div>
                        <div className={cancel} onClick={toggleModal}>
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

EditPasswordModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    showPasswordModal: PropTypes.bool.isRequired
}

export default EditPasswordModal;

