// Types
const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
const NOTIFICATION_RESET = 'NOTIFICATION_RESET';

export const setNotificationText = data => ({
  type: NOTIFICATION_SUCCESS,
  notification: data
});

export const resetNotification = error => ({
  type: NOTIFICATION_RESET,
  error
});


const initialState = {
  notification: ""
};

// Reducer
const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.notification,
      };
    case NOTIFICATION_RESET:
      return {
        ...state,
        notification: ""
      };
    default:
      return state;
  }
}

export default notificationsReducer;