const validateAuth = (values) => {
    let errors = {};
    let validations = {};

    if (!values.email) {
        errors.email = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Please enter a valid email address";
    }

    return {errors, validations};

}


export default validateAuth;