const validateResetPassword = (values) => {
    let errors = {};
    let validations = {};

    // Password Errors
    if (!values.password) {
        errors.password = "Please enter a password";
    } else if (values.password.length < 8) {
        errors.password = "Please enter a password that is at least 8 characters long";
    } else {
        validations.password = true
    }
    return { errors, validations };

}

export default validateResetPassword;