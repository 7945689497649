import React, { useContext, useState } from "react";
import auth0 from "auth0-js";
import history from "../history";
import { fetchUser, postData } from "../api/api";
import { throwError } from "../error/Error";
import config from "../config/auth_config.js";
import Bluebird from "bluebird";
import * as Sentry from "@sentry/browser";

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

const Auth0Provider = (props) => {
  const prevAuth = window.localStorage.auth || null;
  const prevUser = window.localStorage.user || null;

  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [user, setUser] = useState(prevUser);
  const [authError, setAuthError] = useState(null);
  const [authLoginError, setAuthLoginError] = useState(null);

  // initialize auth client
  const auth0Client = new auth0.WebAuth({
    domain: config.domain,
    clientID: config.clientId,
    redirectUri: `${window.location.origin}/login/callback`,
    // redirectUri: (process.env.NODE_ENV !== 'development') ? `${window.location.origin}/login/callback` : `https://webdev.wellthon.com/login/callback`,
    responseType: "token id_token",
    scope: "openid profile email",
  });

  // if (window.location.hostname === "http://localhost:3000/" || window.location.hostname === "" || window.location.hostname === "172.20.10.4") {
  //   auth0Client.crossOriginVerification();
  // }

  // login method takes email password and db connection
  const login = async (email, password) => {
    console.log("the user called login method");
    // await new Bluebird(function (resolve, reject) {
    await new Promise(function (resolve, reject) {
      auth0Client.login(
        {
          connection: "Username-Password-Authentication",
          email: email,
          password: password,
        },
        (err) => {
          console.log("ERROR", err);
          if (err) {
            // access_denied	When using web - based authentication, the resource server denies access per OAuth2 specifications
            // invalid_user_password	The username and / or password used for authentication are invalid
            // mfa_invalid_code	The multi - factor authentication(MFA) code provided by the user is invalid / expired
            // mfa_registration_required	The administrator has required multi - factor authentication, but the user has not enrolled
            // mfa_required	The user must provide the multi - factor authentication code to authenticate
            // password_leaked	If the password has been leaked and a different one needs to be used
            // PasswordHistoryError	The password provided for sign up / update has already been used(reported when password history feature is enabled)
            // PasswordStrengthError	The password provided does not match the connection's strength requirements
            // too_many_attempts	The account is blocked due to too many attempts to sign in
            // unauthorized	The user you are attempting to sign in with is blocked

            if (
              err.code === "invalid_user_password" ||
              err.code === "access_denied"
            ) {
              setAuthLoginError([
                "Your email address and password don’t match our records",
              ]);
            } else if (err.code === "too_many_attempts") {
              setAuthLoginError([
                "Account blocked for too many failed attempts.",
                "Check your email for instructions on how to unblock it.",
              ]);
            } else {
              console.log("Something went wrong: " + err.code);
              setAuthLoginError([
                "Hmm, that doesn't look right. Please try again.",
              ]);
            }
            return reject(new Error(err.code));
          } else {
            setUser(getUser());
            setAuthenticated(true);
          }
        }
      );
    });
  };

  // signup method takes email password and db connection
  const signUp = async (email, password, tempToken) => {
    return login(email, password)
      .then(() => {
        console.log("logged in");
      })
      .catch(async () => {
        console.log("the user called signup method");

        // await new Bluebird(function (resolve, reject) {
        return await new Promise(function (resolve, reject) {
          return auth0Client.redirect.signupAndLogin(
            {
              connection: "Username-Password-Authentication",
              email: email,
              password: password,
              user_metadata: { tempToken: `${tempToken}`, skipHook: "true" },
            },
            (err) => {
              if (err) {
                console.log("ERR", err);
                // invalid_password	If the password used doesn't comply with the password policy for the connection
                // invalid_signup	The user your are attempting to sign up is invalid
                // password_dictionary_error	The chosen password is too common
                // password_no_user_info_error	The chosen password is based on user information
                // password_strength_error	The chosen password is too weak
                // unauthorized	If you cannot sign up for this application.May have to do with the violation of a specific rule
                // user_exists	The user you are attempting to sign up has already signed up
                // username_exists	The username you are attempting to sign up with is already in use

                if (
                  err.code === "user_exists" ||
                  err.code === "username_exists"
                ) {
                  setAuthError([
                    "Sorry, that doesn't look right. Please try again.",
                  ]);
                  return reject("Err");
                } else if (
                  err.code === "password_dictionary_error" ||
                  err.code === "password_no_user_info_error"
                ) {
                  setAuthError([
                    "That password might be too easy to guess.",
                    "Try something that's not a common word",
                  ]);
                  return reject("Err");
                } else if (err.code === "password_strength_error") {
                  setAuthError([
                    "That password might be too easy to guess.",
                    "(Did you include a number, capital letter and special character?)",
                  ]);
                  return reject("Err");
                } else if (err.code === "invalid_signup") {
                  setAuthError([
                    "Hmm, that doesn't look right. Please try again.",
                  ]);
                  return reject("Err");
                } else {
                  console.log("Something went wrong: " + err.code);
                  setAuthError([
                    "Hmm, that doesn't look right. Please try again.",
                  ]);
                  reject(new Error(err.code));
                  return reject("Err");
                }
              } else {
                setUser(getUser());
                setAuthenticated(true);
                console.log("finished sign up");
              }
            }
          );
        });
      });
  };

  // signup method takes email password and db connection
  const signUpWithEmail = async (email, password) => {
    // debugger;
    // await new Bluebird(function (resolve, reject) {
    return await new Promise(function (resolve, reject) {
      return auth0Client.redirect.signupAndLogin(
        {
          connection: "Username-Password-Authentication",
          email: email,
          password: password,
          user_metadata: { skipHook: "true" },
        },
        (err) => {
          // debugger;
          if (err) {
            console.log("ERR", err);
            // invalid_password	If the password used doesn't comply with the password policy for the connection
            // invalid_signup	The user your are attempting to sign up is invalid
            // password_dictionary_error	The chosen password is too common
            // password_no_user_info_error	The chosen password is based on user information
            // password_strength_error	The chosen password is too weak
            // unauthorized	If you cannot sign up for this application.May have to do with the violation of a specific rule
            // user_exists	The user you are attempting to sign up has already signed up
            // username_exists	The username you are attempting to sign up with is already in use

            if (err.code === "user_exists" || err.code === "username_exists") {
              setAuthError([
                "Sorry, that doesn't look right. Please try again.",
              ]);
              return reject("Err");
            } else if (
              err.code === "password_dictionary_error" ||
              err.code === "password_no_user_info_error"
            ) {
              setAuthError([
                "That password might be too easy to guess.",
                "Try something that's not a common word",
              ]);
              return reject("Err");
            } else if (err.code === "password_strength_error") {
              setAuthError([
                "That password might be too easy to guess.",
                "(Did you include a number, capital letter and special character?)",
              ]);
              return reject("Err");
            } else if (err.code === "invalid_signup") {
              setAuthError(["Hmm, that doesn't look right. Please try again."]);
              return reject("Err");
            } else {
              console.log("Something went wrong: " + err.code);
              setAuthError(["Hmm, that doesn't look right. Please try again."]);
              reject(new Error(err.code));
              return reject("Err");
            }
          } else {
            // debugger;
            setUser(getUser());
            setAuthenticated(true);
          }
        }
      );
    });
  };

  // signup method using apple
  const signUpApple = async () => {
    // await new Bluebird(function (resolve, reject) {
    return await new Promise(function (resolve, reject) {
      return auth0Client.authorize(
        {
          connection: "apple",
        },
        (err, result) => {
          if (err) {
            console.log("ERR", err);
          } else {
            setUser(getUser());
            setAuthenticated(true);
            console.log("finished sign up");
          }
        }
      );
    });
  };

  // signup method using google
  const signUpGoogle = async () => {
    // await new Bluebird(function (resolve, reject) {
    return await new Promise(function (resolve, reject) {
      return auth0Client.authorize(
        {
          connection: "google-oauth2",
        },
        (err, result) => {
          if (err) {
            console.log("ERR", err);
          } else {
            // debugger;
            setUser(getUser());
            setAuthenticated(true);
            console.log("finished sign up");
          }
        }
      );
    });
  };

  // signup method using facebook
  const signUpFacebook = async () => {
    // debugger;
    // await new Bluebird(function (resolve, reject) {
    return await new Promise(function (resolve, reject) {
      return auth0Client.authorize(
        {
          connection: "facebook",
        },
        (err, result) => {
          if (err) {
            console.log("ERR", err);
          } else {
            setUser(getUser());
            setAuthenticated(true);
            console.log("finished sign up");
          }
        }
      );
    });
  };

  // signup method app
  // const signUpApple = async () => {
  //   auth0Client.authorize({
  //     connection: 'apple'
  //   });
  // }

  // logoout method removes all id's from local storage
  const logout = () => {
    console.log("the user is logging out");
    // Remove tokens and expiry time
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    localStorage.removeItem("tempToken");
    history.push("/login");
    setAuthenticated(null);
    setUser(null);
    window.heap.identify(null);
    // auth0Client.logout({
    //   returnTo: `${window.location.origin}`
    // });
    // Set user information, as well as tags and further extras
    Sentry.configureScope((scope) => {
      scope.clear();
    });
  };

  //forgot password
  const resetPassword = async (email) => {
    console.log("the user called password reset");
    // params that affect the reset
    const paramsReset = {
      request: email,
    };
    const postRequestBody = {};
    return await postData(postRequestBody, paramsReset, "password-reset").catch(
      (err) => {
        throwError(err);
      }
    );
  };

  //reset password piece
  const resetPasswordPassword = async (token, password) => {
    console.log("the user called password password reset");
    // params that affect the reset
    const paramsReset = {
      reset: token,
      new_password: password,
    };
    const postRequestBody = {};
    return await postData(postRequestBody, paramsReset, "password-reset").catch(
      (err) => {
        throwError(err);
      }
    );
  };

  // method called once callback initiated
  const handleAuthentication = () => {
    console.log("auth0Client", auth0Client);
    console.log("window", window);
    if (typeof window !== "undefined") {
      auth0Client.parseHash(async (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          console.log("inside the if authResult");
          await setSession(authResult);
          // history.replace('/');
          await howFarAlongIsUser();
        } else if (err) {
          console.log("error from parse hash", err);
          return err;
        }
      });
    }
  };

  const howFarAlongIsUser = async () => {
    // debugger;
    if (localStorage.getItem("access_token") !== null) {
      await new Bluebird(function (resolve, reject) {
        auth0Client.client.userInfo(
          localStorage.getItem("access_token"),
          (err, user) => {
            if (err) {
              console.log("here is error i keep getting", err);
              reject(err);
            }
            resolve(user);
          }
        );
      }).then(async (data) => {
        // debugger;
        localStorage.setItem("user", JSON.stringify(data));
        setUser(data);
        try {
          // debugger;
          // if (localStorage.getItem("old_token") === null) { throw 'changeProvider'} else {
          const data_1 = await fetchUser(data.sub);
          // debugger;
          console.log("DATA", data_1);
          if (!data_1.Error) {
            window.heap.identify(data_1.Profile.Email);
            if (
              data_1.Profile.HealthProgram !== null &&
              data_1.Profile.HealthProgram !== ""
            ) {
              return history.replace("/home");
            } else if (data_1.Profile.TermsAgreed) {
              return history.replace("/profile");
            } else if (data_1.Weight.Current > 0) {
              return history.replace("/agree-to-terms");
            } else if (
              data_1.AssessmentInfo.LastCompleteAssessmentDate !== "" &&
              data_1.AssessmentInfo.LastCompleteAssessmentDate !== null &&
              data_1.AssessmentInfo.LastCompleteAssessmentDate !== undefined
            ) {
              return history.replace("/vitals");
            } else {
              return history.replace("/pre-assessment");
            }
          } else {
            throw new Error(JSON.stringify(data_1));
          }
          // }
        } catch (error) {
          console.log("ERROR");
          console.log(error);
          // debugger;
          if (error.Msg === "sql: no rows in result set") {
            console.log("user not created yet in backend");
          } else {
            // debugger;
            if (JSON.parse(localStorage.getItem("user")).sub.length > 0) {
              // debugger;
              const tempToken = localStorage.getItem("tempToken");
              if ((tempToken || {}).length > 0) {
                const user = JSON.parse(localStorage.getItem("user"));
                if (user.sub.substring(0, 8) !== "facebook") {
                  const params = {
                    token: user.sub,
                    tempToken: tempToken,
                  };

                  const postRequestBody = {};
                  await postData(postRequestBody, params, "users")
                    .then((res) => {
                      if (
                        JSON.parse(res).Msg ===
                        "Another user already has this email address"
                      ) {
                        alert(
                          "This email address is already in use, please login with proper account"
                        );
                        localStorage.setItem("error", true)
                        logout();
                        history.push({ pathname: `/login`, state: { Error: "Please use an account with an email address not associated with another" } })
                        return "UserExistsAlready";
                      }
                      fetchUser(user.sub);
                    })
                    .then(() => {
                      fetchUser(user.sub);
                    })
                    .then(() => history.replace("/profile"))
                    .catch((error) => throwError(error));
                } else if ((user.email || {}).length > 0) {
                  const params = {
                    token: user.sub,
                    tempToken: tempToken,
                    email: user.email,
                  };
                  const postRequestBody = {};
                  await postData(postRequestBody, params, "users")
                    .then((res) => {
                      if (
                        JSON.parse(res).Msg ===
                        "Another user already has this email address"
                      ) {
                        alert(
                          "This email address is already in use, please login with proper account"
                        );
                        localStorage.setItem("error", true)
                        logout();
                        history.push({ pathname: `/login`, state: { Error: "Please use an account with an email address not associated with another" } })
                        return "UserExistsAlready";
                      }
                      fetchUser(user.sub);
                    })
                    .then(() => {
                      fetchUser(user.sub);
                    })
                    .then(() => history.replace("/profile"))
                    .catch((error) => {
                      throwError(error);
                    });
                } else {
                  return history.replace("/input-email");
                }
              } else {
                const user = JSON.parse(localStorage.getItem("user"));
                if (localStorage.getItem("old_token") !== null) {
                  let params;
                  // debugger;
                  if (user.email === "") {
                    params = {
                      oldToken: localStorage.getItem("old_token"),
                      email: localStorage.getItem("old_email"),
                      newToken: user.sub,
                    };
                  } else {
                    params = {
                      oldToken: localStorage.getItem("old_token"),
                      newToken: user.sub,
                    };
                  }

                  const postRequestBody = {};
                  return postData(postRequestBody, params, "provider")
                    .catch((err) => {
                      throwError(err);
                    })
                    .then(() => {
                      history.push("/home");
                      localStorage.removeItem("old_token");
                      localStorage.removeItem("old_email");
                    });
                } else if (user.sub.substring(0, 8) !== "facebook") {
                  // debugger;
                  const params = {
                    token: user.sub,
                  };

                  const postRequestBody = {};

                  await postData(postRequestBody, params, "users")
                    .then((res) => {
                      if (
                        JSON.parse(res).Msg ===
                        "Another user already has this email address"
                      ) {
                        alert(
                          "This email address is already in use, please login with proper account"
                        );
                        localStorage.setItem("error", true)
                        logout();
                        history.push({ pathname: `/login`, state: { Error: "Please use an account with an email address not associated with another" } })
                        return "UserExistsAlready";
                      }
                      fetchUser(user.sub);
                    })
                    .then((res) => {
                      if (
                        JSON.parse(res).Msg ===
                        "Another user already has this email address"
                      ) {
                        alert(
                          "This email address is already in use, please login with proper account"
                        );
                        localStorage.setItem("error", true)
                        logout();
                        // localStorage.removeItem("access_token");
                        // localStorage.removeItem("auth");
                        // history.push("/login");
                        history.push({ pathname: `/login`, state: { Error: "Please use an account with an email address not associated with another" } })
                        return "UserExistsAlready";
                      }
                      fetchUser(user.sub);
                    })
                    .then(() => {
                      fetchUser(user.sub);
                    })
                    .then(() => history.replace("/pre-assessment"))
                    .catch((error) => throwError(error));
                } else if ((user.email || {}).length > 0) {
                  const params = {
                    token: user.sub,
                    email: user.email,
                  };

                  const postRequestBody = {};
                  await postData(postRequestBody, params, "users")
                    .then((res) => {
                      if (
                        JSON.parse(res).Msg ===
                        "Another user already has this email address"
                      ) {
                        alert(
                          "This email address is already in use, please login with proper account"
                        );
                        localStorage.setItem("error", true)
                        logout();
                        history.push({ pathname: `/login`, state: { Error: "Please use an account with an email address not associated with another" } })
                        return "UserExistsAlready";
                      }
                      fetchUser(user.sub);
                    })
                    .then((res) => {
                      if (res !== "UserExistsAlready") {
                        history.replace("/pre-assessment");
                      }
                    })
                    .catch((error) => {
                      throwError(error);
                    });
                } else {
                  return history.replace("/input-email");
                }
              }
            } else {
              console.log("Error catch", error);
            }
          }
          if (localStorage.getItem("error") !== null) {
            return history.replace("/");
          }
        }
      });
    } else {
      if (localStorage.getItem("error") !== null) {
        return history.replace("/");
      }
    }
  };

  const isAuthenticated = () => {
    if (typeof localStorage !== "undefined") {
      const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
      // setAuthenticated(true);
      return new Date().getTime() < expiresAt;
    } else {
      localStorage.setItem("auth", false);
      return false;
    }
  };

  const setSession = async (authResult) => {
    console.log("inside set session 7");
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("auth", true);

    setAuthenticated(true);
    return await new Bluebird(function (resolve, reject) {
      console.log("inside set session 8");
      auth0Client.client.userInfo(authResult.accessToken, (err, user) => {
        console.log("inside set session 9");
        if (err) return reject(err);
        return resolve(user);
      });
    }).then((data) => {
      console.log("inside set session 10");
      localStorage.setItem("user", JSON.stringify(data));
      setUser(data);
      window.heap.identify(data.name);
      setAuthenticated(true);
    });
  };

  const renewSession = async () => {
    console.log("inside renew 1");
    return await new Bluebird(function (resolve, reject) {
      console.log("inside promise 2");
      return auth0Client.checkSession({}, async (err, authResult) => {
        if (err) {
          console.error("inside err 3", err);
          return reject(err);
        }
        console.log("resolve auth 4", authResult);
        return resolve(authResult);
      });
    })
      .then(async (authResult) => {
        console.log("auth result 5", authResult);
        if (authResult && authResult.accessToken && authResult.idToken) {
          console.log("auth result inside 6");
          return await setSession(authResult);
        }
      })
      .catch((err) => {
        console.log("loggin out inside renew session");
        console.log("error", err);
        logout();
      });
  };

  const getUser = () => {
    // debugger;
    if (localStorage.getItem("user")) {
      var result = JSON.parse(localStorage.getItem("user"));
      window.heap.identify(result.name);
      // Set user information, as well as tags and further extras
      Sentry.configureScope((scope) => {
        scope.setUser({ id: result.sub });
      });

      // LOOK HERE NOW!!!
      // debugger;

      // }
      return result;
    }
  };

  const getToken = () => {
    return localStorage.getItem("id_token");
  };

  return (
    <Auth0Context.Provider
      value={{
        login,
        signUp,
        signUpApple,
        signUpGoogle,
        signUpFacebook,
        logout,
        handleAuthentication,
        isAuthenticated,
        setSession,
        renewSession,
        getUser,
        getToken,
        authenticated,
        user,
        authError,
        authLoginError,
        howFarAlongIsUser,
        resetPassword,
        resetPasswordPassword,
        signUpWithEmail,
      }}
    >
      {props.children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
