import React from "react";
import { useAuth0 } from '../../auth/Auth';
import { withRouter } from 'react-router-dom';
import styles from './NavBarMain.module.scss';
import HamburgerButton from '../HamburgerButton/HamburgerButton';
import { animated } from "react-spring";

import PropTypes from 'prop-types';

const NavBarModal = withRouter((props) => {

    console.log("NAVBAR MODAL", props);

    const { logout } = useAuth0();

    const { toggleMenu, style } = props;
    const { modal, modalHamburger, modalLinks, modalText } = styles;
    return (
        <animated.div className={modal} style={style}>
            <div className={modalHamburger}>
                <HamburgerButton toggleMenu={toggleMenu} styleName="white" styleNameText="whiteText" />
            </div>
            <div className={modalLinks}>
                <div
                    className={modalText}
                    onClick={() => {
                        props.history.push('/home')
                        toggleMenu()
                    }
                    }
                >
                    Home
                </div>
                <div
                    className={modalText}
                    onClick={() => {
                        props.history.push('/account')
                        toggleMenu()
                    }
                    }
                >
                    Manage Account
                </div>
                <a rel="noopener noreferrer"
                    target="_blank"
                    className={modalText}
                    href="https://wellthon.com/help"
                    onClick={() => { toggleMenu() }}
                >Help</a>
                <div
                    className={modalText}
                    onClick={() => {
                        logout()
                    }}
                >
                    Sign Out
                </div>
            </div>
        </animated.div>
    );
});

NavBarModal.propTypes = {
    toggleMenu: PropTypes.func.isRequired
}

export default NavBarModal;