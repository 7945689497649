import React, {
    useState,
    useEffect
} from 'react';
// import { useAuth0 } from "../../auth/Auth";
import MainHeader from '../../components/Headers/MainHeader';
import Button from '../../components/Button/Button';
import TextArea from '../../components/TextArea/TextArea';
import { postData } from '../../api/api';
import { useAuth0 } from "../../auth/Auth";
import { throwError } from '../../error/Error';

import history from "../../history";

import styles from './Feedback.module.scss';
import SubHeader from '../../components/Headers/SubHeader';

const PostWorkout = props => {

    const { user } = useAuth0();

    // const { user } = useAuth0();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Feedback',
        });
    }, [])

    const [feedbackText, setFeedbackText] = useState("");

    console.log(feedbackText)

    const submitFeedback = async () => {

        const params = {
            token: user.sub,
        };

        const postRequestBody = {
            "type": "workout_review",
            "platform": "web",
            "text": feedbackText
        }
            ;
        // setTimeout(() => {
        postData(postRequestBody, params, "support")
            .then(() => history.push("/thank-you"))
            .catch(error => throwError(error))
        // }, 500)
    };

    const {
        container, preText, cancel, mainContainer, buttonsContainer
    } = styles;



    return (
        <div className={container}>
            <MainHeader text="Workout Feedback" />
            <SubHeader text="How Can We Improve?" />
            <div className={mainContainer}>
                <div className={preText}>We’re sorry you didn’t enjoy your workout.<br />Please tell us how we can improve your Wellthon experience.</div>
                <TextArea limit={255} value={feedbackText} setFeedbackText={setFeedbackText} />
                <div className={buttonsContainer}>
                    <Button text="Submit" styleName="buttonSmaller" onClick={() => submitFeedback()} />
                    <div className={cancel} onClick={() => { history.push('/home') }}>
                        Cancel
            </div>
                </div>
            </div>
        </div>
    );
}

export default PostWorkout;

// We’re sorry you didn’t enjoy your workout. Please tell us how we can improve your Wellthon experience.

// TEXT FIELD (255 character limit)

// BUTTON (saves ratings to user profile; proceeds to Thank You screen)
// Submit

// LINK (return to Home Screen)
// Skip This

