import React, { Component } from 'react';
import styles from './PageNotFound.module.scss';
import notFoundPicture from '../../assets/404Picture.png'

class PageNotFound extends Component {
    render() {
        const { container, header, link, image, leftColumn, rightColumn } = styles;
        return (
            <div className={container}>
                <div className={leftColumn}>
                    <div className={header}>We’re sorry but the page you<br />were looking for can’t be found.</div>
                    <a className={link} rel="noopener noreferrer" target="_blank" href="https://wellthon.com/" >Go to Wellthon homepage</a>
                    <a className={link} rel="noopener noreferrer" target="_blank" href="https://wellthon.com/help" >Find help</a>
                </div>
                <div className={rightColumn}>
                    <img src={notFoundPicture} alt="page not found" className={image} />
                </div>
            </div>
        )
    }
}

export default PageNotFound;