import React, { useEffect, useState } from 'react';
import MainHeader from '../../components/Headers/MainHeader';
import Button from '../../components/Button/Button';

import history from "../../history";
import styles from './ThankYou.module.scss';

import ThankYouModal from './ThankYouModal/ThankYouModal';


const ThankYou = props => {

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Thank You',
        });
    }, [])

    const [thankYouModal, setThankYouModal] = useState(false);

    const toggleThankYouModal = () => {
        setThankYouModal(!thankYouModal);
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('dontShow') !== "true")) {
            if (localStorage.getItem('thumbsUp') !== null) {
                const thumbs = parseInt(JSON.parse(localStorage.getItem('thumbsUp')));
                if (thumbs === 3) {
                    setThankYouModal(true)
                    localStorage.setItem('dontShow', true)
                }
            }
        }
    }, []);



    const {
        container, preText, mainContainer, buttonsContainer
    } = styles;


    return (
        <>
            <ThankYouModal showModal={thankYouModal} toggleModal={toggleThankYouModal} />
            <div className={container}>
                <MainHeader text="Thanks for your feedback!" />
                <div className={mainContainer}>
                    <div className={preText}>Your feedback helps us build you a better workout.</div>
                    <div className={buttonsContainer}>
                        <Button text="Go Home" styleName="buttonSmaller" onClick={() => history.push("/home")} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThankYou;