// These must be the first lines in src/index.js

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';

// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from '@sentry/browser';
import ErrorBoundary from './ErrorBoundary';
import * as serviceWorker from "./serviceWorker";
import Auth0Provider from "./auth/Auth";
import './index.scss';

import { Provider } from 'react-redux'
import configureStore from './redux/configureStore';

const store = configureStore();

// // disable all console logs in prod
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: "https://9ab327b3185e4605a8960d7099417396@sentry.io/1551846" });
  // console.log = () => { }
}

ReactDOM.render(
  <Auth0Provider>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </Auth0Provider>
  ,
  document.getElementById("root")
);

serviceWorker.unregister(); 