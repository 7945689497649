
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import userReducer from './ducks/user';
import workoutsReducer from './ducks/workouts';
import notificationsReducer from './ducks/notifications';

const loggerMiddleware = createLogger(); // initialize logger

let middleware = [
    thunkMiddleware,
    process.env.NODE_ENV !== 'production' && loggerMiddleware
].filter(Boolean);

const createStoreWithMiddleware = compose(applyMiddleware(...middleware))(createStore);

const reducer = combineReducers({
    userReducer,
    workoutsReducer,
    notificationsReducer
});

const configureStore = (initialState) => createStoreWithMiddleware(reducer, initialState);
export default configureStore;