import React from "react";
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '../../auth/Auth';

import styles from './NavBarBasic.module.scss';
import WellthonLogoSVG from '../../assets/WellthonLogo.svg';
import ReactRouterPropTypes from 'react-router-prop-types';

const NavBarBasic = withRouter((props) => {

    const { authenticated } = useAuth0();
    return (
        <div className={styles.border}>
            <div className={styles.container}>
                <div className={styles.leftContainer} >
                    <img src={WellthonLogoSVG} alt="wellthon logo" className={styles.basicLogo} />
                </div>

                <div className={styles.rightContainer}>
                    {!authenticated && <button
                        className={styles.statusButton}
                        onClick={() =>
                            props.history.push('/login')
                        }
                    >
                        Log in
                    </button>}
                </div>

            </div>
        </div>
    );
});

NavBarBasic.propTypes = {
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    match: ReactRouterPropTypes.match,
  }

export default NavBarBasic;


// if we decide to have logout 
/* <button className={styles.statusButton}
    onClick={(e) => { logout() }}>
    Log out
                        </button> */