import React from 'react';
import styles from './VideoModals.module.scss';
import Modal from 'react-modal';
import Button from '../../../components/Button/Button';
import history from '../../../history';
import MainHeader from '../../../components/Headers/MainHeader';

Modal.setAppElement('#root');

const ErrorWorkoutModal = (props) => {

    const { showModal, showErrorWorkoutModal, toggleMessageSentModal } = props;
    const { main, modal, overlay, cancel, center, noWorkoutText, x } = styles;


    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <section className={main}>
                <span className={x} role="img" aria-label="x" onClick={() => { showErrorWorkoutModal() }}>&#10060;</span>
                <MainHeader text="No Workout Available" name="noWorkout" styleName="mainHeaderPricingSmallerMargin" />
                <div className={noWorkoutText}>Based on your Health Assessment, Wellthon cannot offer a safe workout for you at this time. Would you like to:</div>
                <div className={center}>
                    <Button text="Update Your Assessment" styleName="buttonSmaller" onClick={() => {
                        history.push(`/health-assessment-update`)
                    }} name="updateAssessment" />
                    <div className={cancel} onClick={() => {
                        showErrorWorkoutModal()
                        toggleMessageSentModal()
                    }}>Contact Customer Service</div>
                </div>
            </section>
        </Modal>
    )
}

export default ErrorWorkoutModal;