import { useState, useEffect } from "react";

// import PlacesAutocomplete, {
//     geocodeByAddress,
//     getLatLng,
// } from 'react-places-autocomplete';
// import { throwError } from '../error/Error';

// hook to validate all forms
// accepts initial state for values, validation funtion, and method to run
// when there are no errors
const useFormValidation = (initialState, validate, runOnSubmit) => {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState({});
    // touched is an array of all fields that have been touched by the user
    const [touched, setTouched] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);

    // need to rerun after there is a changed to touched
    // this checks to see if there are any errors that should be highlighted
    useEffect(() => {
        const validation = validate(values);
        const validationErrors = validation.errors;
        const validationSuccess = validation.validations;
        const touchedErrors = Object.keys(validationErrors)
            .filter(key => touched.includes(key)) // get all touched keys
            .reduce((acc, key) => {
                if (!acc[key]) {
                    acc[key] = validationErrors[key]
                }
                return acc
            }, {})

        const touchedSuccess = Object.keys(validationSuccess)
            .filter(key => touched.includes(key)) // get all touched keys
            .reduce((acc, key) => {
                if (!acc[key]) {
                    acc[key] = validationSuccess[key]
                }
                return acc
            }, {})

        setErrors(touchedErrors);
        setValidated(touchedSuccess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [touched, values]);

    // handle change is run every time an input changes
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeSpecific = (address, city, state, zip) => {
        console.log(address, city, state, zip)
        setValues({
            ...values,
            "address1": address,
            "shipping_city": city,
            "shipping_state": state,
            "zip": zip
        });
    }

    const handleChangeAddress = (event) => {
        setValues({
            ...values,
            "address1": event
        });
    }

    const handleChangeCountry = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
            "shipping_city": "",
            "shipping_state": ""

        });
    }

    // handle change UPPERCASE is run every time an input changes
    const handleChangeUppercase = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value.toUpperCase()
        });
    }

    // handle change is run for radio buttons and check boxes
    const handleChangeChecked = (event) => {
        setValues({ ...values, [event.target.name]: event.target.checked })

        // if (!touched.includes(event.target.name)) {
        //     setTouched([
        //       ...touched,
        //       event.target.name
        //     ])
        //   }
    }

    // handle blur is run when a form is tapped or tabbed into/ 'touched'
    const handleBlur = (event) => {
        // if touched hasn't been touched before add it to the array
        if (!touched.includes(event.target.name)) {
            setTouched([
                ...touched,
                event.target.name
            ])
        }
    }

    // function for form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = await validate(values).errors;
        setErrors(validationErrors);
        const noErrors = Object.keys(validationErrors).length === 0;
        if (noErrors) {
            setTouched([]);
            setSubmitting(true);
            await runOnSubmit().then((res) => {
                console.log("RES", res);
                res === "Error" && setSubmitting(false)
            }
            ).catch(err => setSubmitting(false))
        }
    }

    const resetValues = () => {
        setValues(initialState);
    }


    // turn off submitting when change value
    const creditCardNoError = () => {
        setSubmitting(false);
    }


    // return values from hook to be used in react form component
    return {
        handleSubmit,
        handleChange,
        handleChangeAddress,
        handleChangeSpecific,
        handleChangeCountry,
        handleChangeUppercase,
        handleChangeChecked,
        handleBlur,
        values,
        errors,
        validated,
        isSubmitting,
        setSubmitting,
        creditCardNoError,
        resetValues
    };
}

export default useFormValidation;
