import React, { useState } from "react";
import { useAuth0 } from "../../auth/Auth";

import history from '../../history'

import HamburgerButton from '../HamburgerButton/HamburgerButton';
import useComponentVisible from '../../hooks/useComponentVisible';
import NavBarModal from './NavBarModal';
import NavBarModalRight from './NavBarModalRight';

import styles from './NavBarMain.module.scss';
import WellthonLogoSVG from '../../assets/WellthonLogo.svg';
import { useSpring } from 'react-spring';
import Gear from '../../assets/Gear.png';

const NavBarMain = (() => {

    const { authenticated } = useAuth0();
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);

    const [expandMenu, setExpandMenu] = useState(false);

    // const fullMenuAnimation = useSpring(
    //     {
    //         transform: isComponentVisible ? `translateY(0)` : `translateY(-100%)`,
    //         opacity: isComponentVisible ? 1 : 0,
    //         config: config.molasses
    //     },
    //     console.log("ISV", isComponentVisible));
    const fullMenuAnimation = useSpring({
        transform: isComponentVisible ? `translateY(0)` : `translateY(-300px)`,
        opacity: isComponentVisible ? 1 : 1
    });

    const rightMenuAnimation = useSpring({
        opacity: expandMenu ? 1 : 0,
        transform: expandMenu ? `translateX(0)` : `translateX(100%)`
    });

    // const [fullMenuAnimation, set, stop] = useSpring(() => ({ opacity: 0, transform: `translateY(-100%)` }))

    // // Update spring with new props
    // set({ opacity: isComponentVisible ? 1 : 0, 
    //     transform: isComponentVisible ? `translateY(0)` : `translateY(-100%)` })
    // // Stop animation
    // stop()


    const toggleMenu = () => {
        setExpandMenu(!expandMenu);
    }

    const toggleAccount = () => {
        console.log(isComponentVisible)
        setIsComponentVisible(!isComponentVisible)
    }


    return (
        <>
            <div className={styles.border}>
                <div className={styles.container}>
                    <div className={styles.leftContainer}>
                        <img src={WellthonLogoSVG} alt="wellthon logo" className={styles.basicLogo} onClick={() => { history.push('/home') }} />
                    </div>
                    {!expandMenu &&
                        <>
                            {authenticated &&
                                <div className={styles.rightContainer} ref={ref}>
                                    <div className={styles.nonMobile}>
                                        
                                        <button
                                            className={isComponentVisible ? styles.statusButtonBlue : styles.statusButton}
                                            onClick={() =>
                                                toggleAccount()
                                            }
                                        >
                                            <img src={Gear} alt="gear logo" style={{marginRight: 10}}/>
                                            {isComponentVisible ? "Close" : "Menu"}
                                            {/* <span className={isComponentVisible ? styles.triangleUp : styles.triangleDown}>&#9650;</span> */}
                                        </button>
                                    </div>
                                    < NavBarModalRight toggleMenu={toggleAccount} style={fullMenuAnimation} />
                                    <div className={styles.mobile}>
                                        <HamburgerButton toggleMenu={toggleMenu} />
                                    </div>
                                </div>
                            }
                        </>}
                </div>
            </div>
            < NavBarModal toggleMenu={toggleMenu} style={rightMenuAnimation} />
        </>
    );
});

export default NavBarMain;