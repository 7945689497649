import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button/Button';
import { useAuth0 } from "../../../auth/Auth";
import Checkbox from '../../../components/Checkbox/Checkbox';
import MainHeader from '../../../components/Headers/MainHeader';
import SubHeader from '../../../components/Headers/SubHeader';
import { postData } from '../../../api/api';
import styles from './Terms.module.scss';
import { throwError } from '../../../error/Error';
import ScrollToTopOnMount from '../../../ScrollRestoration';
import history from '../../../history';
// import { throwError } from '../../../error/Error';

// thank you for signing up for preorder
const Terms = (props) => {

    const { user } = useAuth0();

    const { termsContainer, container, termsBody, confirm } = styles;
    const [checkboxValue, setCheckboxValue] = useState(false);

    const handleChangeChecked = (event) => {
        setCheckboxValue(event.target.checked);
    }

    const submit = async () => {

        let tempTok;
        if (((user || {}).sub || {}).length > 0) {
            tempTok = user.sub
        } else {
            tempTok = localStorage.getItem('tempToken')
        }
        const params = {
            token: tempTok,
            agree: true
        };

        const postRequestBody = {};

        if (((user || {}).sub || {}).length > 0) {
            await postData(postRequestBody, params, "terms")
                .then(() => history.replace('/profile'))
                .catch(error => throwError(error))
        }
        else {
            await postData(postRequestBody, params, "terms")
                .then(() => history.replace('/signup'))
                .catch(error => throwError(error))
        }
    }


    // const PostUser = async () => {

    //     const appURL = `${process.env.REACT_APP_API_URL.slice(0, -4)}/user_metadata`
    //     const user = JSON.parse(localStorage.getItem('user'));
    //     const tempToken = user[appURL]["tempToken"]


    //     const params = {
    //         token: user.sub,
    //         tempToken: tempToken
    //     };

    //     const postRequestBody = {};
    //     await postData(postRequestBody, params, "users").then(() => {
    //         fetchUser(props.token)
    //     }
    //     ).catch(error => throwError(error))
    // }

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'TermsPage',
            //         'user': [user.sub, user.email]
        });
    }, [])
    // }, [user])

    useEffect(() => {
        const fbq = window.fbq || function () { };
        fbq('trackCustom', 'SpecificPage', { content_name: 'TermsAndConditions' });
    }, [])

    return (
        <>
            <ScrollToTopOnMount />
            <div className={container}>
                <MainHeader text="Review Terms of Service" styleName="mainHeaderBottomPadding" name="termsHeader" />
                <SubHeader text="Please review and agree to our Privacy Policy and Terms of Service" styleName="boldDarkMargin" />
                <div className={termsContainer}>
                    <div className={termsBody}>

                        <div>
                            <br />
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '3pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Terms and Conditions of Use for Wellthon Website, Mobile Apps, and Related Products</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '16pt' }}><span style={{ fontSize: '15pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>10/15/2019</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Acceptance of the Terms of Use</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>These terms of use are entered into by and between You and OhioHealth Star Ventures, Inc. ("Company," "we," or "us"). The following terms and conditions, together with any documents they expressly incorporate by reference collectively, "Terms of Use"), govern your access to and use of [Wellthon.com], including any content, functionality, and services offered on or through [Wellthon.com] (the "Website"), whether as a guest or a registered user.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Please read the Terms of Use carefully before you start to use the Website. By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at [Wellthon.com/terms-of-use-and-privacy-policy]. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>This Website is offered and available to users who are older than 16 years of age and reside in the United States or any of its territories or possessions. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.&nbsp;</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Changes to the Terms of Use</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We may revise and update these Terms of Use from time to time at our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Website.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You are responsible for both:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Making all arrangements necessary for you to have access to the Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</span></p>
                                </li>
                            </ul>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our&nbsp;</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Privacy Policy</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;[Wellthon.com/terms-of-use-and-privacy-policy], and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If you choose, or are provided with a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time at our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>License</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>All trademarks, copyright, database rights, and any other intellectual property rights contained within the Website, together with the underlying software code, are owned either directly by us or by our licensors. We grant you a worldwide, non-exclusive, royalty-free, revocable license to use the website and its contents for your personal use in accordance with these Terms.&nbsp;</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Rights and Restrictions</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You may store files that are automatically cached by your web browser for display enhancement purposes.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You may print, or download, one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You must not:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Modify copies of any materials from this site.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</span></p>
                                </li>
                            </ul>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If you wish to make any use of material on the Website other than that set out in this section, please address your request to: [support@wellthon.helpscoutapp.com].</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Prohibited Uses</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To transmit, or procure the sending of, any advertising or promotional material [without our prior written consent], including any "junk mail," "chain letter," "spam," or any other similar solicitation.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To defraud or exploit, or to attempt to defraud or exploit, the intended function of Company Coupons, Discounts, Referrals, or similar price-reduction or efforts beyond stated, reasonable purposes.</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Additionally, you agree not to:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real time activities through the Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Use any device, software, or routine that interferes with the proper working of the Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Otherwise attempt to interfere with the proper working of the Website.</span></p>
                                </li>
                            </ul>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Monitoring and Enforcement; Termination</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We have the right to:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public, or could create liability for the Company.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>However, we cannot review all material before it is posted on the Website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Reliance on Information Posted</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>This Website may include content provided by third parties, including materials provided by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Changes to the Website</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Information About You and Your Visits to the Website&nbsp;</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>All information we collect on this Website is subject to our Privacy Policy [Wellthon.com/terms-of-use-and-privacy-policy]. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Links from the Website</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Geographic Restrictions</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The owner of the Website is based in the State of Ohio in the United States. We provide this Website for use only by persons located in the United States and Canada. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States or Canada, you do so on your own initiative and are responsible for compliance with local laws.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Disclaimer of Warranties</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, CURE ANY PHYSICAL AILMENTS, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Limitation on Liability</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Indemnification</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website's content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Digital Millennium Copyright Act</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from this site (the "Website") infringe your copyright, you may request removal of those materials (or access to them) from the Website by submitting written notification to our copyright agent designated below. In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice (the "DMCA Notice") must include substantially the following:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your physical or electronic signature.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A statement that the information in the written notice is accurate.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</span></p>
                                </li>
                            </ul>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Our designated copyright agent to receive DMCA Notices is:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;[FIRST AND LAST NAME OF AGENT]</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;[NAME OF AGENT'S ORGANIZATION]</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;[PHYSICAL MAIL ADDRESS OF AGENT]</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;[TELEPHONE NUMBER OF AGENT]</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;[EMAIL ADDRESS OF AGENT FOR THIS PURPOSE]</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Please be aware that if you knowingly materially misrepresent that material or activity on the Website is infringing your copyright, you may be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Governing Law and Jurisdiction</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Ohio without giving effect to any choice or conflict of law provision or rule (whether of the State of Ohio or any other jurisdiction).</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of Ohio, in each case located in the City of Columbus and County of Franklin, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Arbitration</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>At Company's sole discretion, it may require You to submit any disputes arising from these Terms of Use or use of the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Ohio law.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Entire Agreement</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Company regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your Comments and Concerns</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>This website is operated by [OhioHealth Star Ventures Inc.] [3430 OhioHealth Parkway, Columbus OH 43202].</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: [support@wellthon.helpscoutapp.com].</span></p>
                            <h1 dir="ltr" style={{ lineHeight: '1.25', marginTop: '20pt', marginBottom: '6pt' }}><span style={{ fontSize: '20pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Website Privacy Policy</span></h1>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Introduction</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>OhioHealth Star Ventures, Inc. ("Company" or "We") respect your privacy and are committed to protecting it through our compliance with this policy.&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>This policy describes the types of information we may collect from you or that you may provide when you visit the website [Wellthon.com] (our "Website") and our practices for collecting, using, maintaining, protecting, and disclosing that information.&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>This policy applies to information we collect:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>On this Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>In email, text, and other electronic messages between you and this Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Through mobile and desktop applications and software you download from this Website, which provide dedicated non-browser-based interaction between you and this Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>This policy DOES NOT apply to information that:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We collect offline or on any other Company apps or websites, including websites you may access through this Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You provide to or is collected by any third party (see Third-Party Information Collection).</span></p>
                                </li>
                            </ul>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Our websites and/or apps, and these other third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see&nbsp;</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Changes to Our Privacy Policy</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Children Under the Age of 16</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The Website is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at [support@wellthon.helpscoutapp.com].</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see [Your California Privacy Rights] for more information.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Information We Collect and How We Collect It</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We collect several types of information from and about users of our Website, including information:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>By which you may be personally identified, such as name, postal address, email address, telephone number, or ANY OTHER INFORMATION THE WEBSITE COLLECTS THAT IS DEFINED AS PERSONAL OR PERSONALLY IDENTIFIABLE INFORMATION UNDER AN APPLICABLE LAW and any other identifier by which you may be contacted online or offline ("personal information").</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We collect information from and about users of our Website:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Directly from you when you provide it to us.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Automatically when you use the Website</span></p>
                                </li>
                            </ul>
                            <h3 dir="ltr" style={{ lineHeight: '1.25', marginTop: '16pt', marginBottom: '4pt' }}><span style={{ fontSize: '13.999999999999998pt', fontFamily: 'Verdana', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Information You Provide to Us</span></h3>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The information we collect on or through our Website may include:</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you report a problem with our Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Records and copies of your correspondence (including email addresses), if you contact us.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your responses to surveys and questionnaires that we might ask you to complete for research purposes.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website.</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You may also provide information for publication or display ("Posted") on public areas of websites you access through the website (collectively, "User Contributions"). Your User Contributions are Posted and transmitted to others at your own risk. Although you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</span></p>
                            <h3 dir="ltr" style={{ lineHeight: '1.25', marginTop: '16pt', marginBottom: '4pt' }}><span style={{ fontSize: '13.999999999999998pt', fontFamily: 'Verdana', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Automatic Information Collection and Tracking</span></h3>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Information about your computer and internet connection, including your IP address, operating system, and browser type.</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Click here INCLUDE AS LINK TO DO NOT TRACK DISCLOSURES for information on how you can opt out of behavioral tracking on this website and how we respond to web browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The information we collect automatically does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Estimate our audience size and usage patterns.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Store information about your preferences, allowing us to customize our Website according to your individual interests.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Speed up your searches.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Recognize you when you return to our Website.</span></p>
                                </li>
                            </ul>
                            <h3 dir="ltr" style={{ lineHeight: '1.25', marginTop: '16pt', marginBottom: '4pt' }}><span style={{ fontSize: '13.999999999999998pt', fontFamily: 'Verdana', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Information Collection and Tracking Technologies</span></h3>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The technologies we use for automatic information collection may include:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span></p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Cookies (or browser cookies).</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Flash Cookies.</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Web Beacons.</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;Pages of our the Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</span></p>
                                </li>
                            </ul>
                            <h3 dir="ltr" style={{ lineHeight: '1.25', marginTop: '16pt', marginBottom: '4pt' }}><span style={{ fontSize: '13.999999999999998pt', fontFamily: 'Verdana', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;Third-Party Information Collection</span></h3>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>When you use the Website or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Advertisers, ad networks, and ad servers.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Analytics companies.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Content providers</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>These third parties may use tracking technologies to collect information about you when you use this Website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.&nbsp;</span></p>
                            <h1 dir="ltr" style={{ lineHeight: '1.25', marginTop: '20pt', marginBottom: '6pt' }}><span style={{ fontSize: '20pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>How We Use Your Information</span></h1>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We use information that we collect about you or that you provide to us, including any personal information:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To present our Website and its contents to you.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To provide you with information, products, or services that you request from us.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To fulfill any other purpose for which you provide it.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To provide you with notices about your account, including expiration and renewal notices.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To notify you about changes to our Website or any products or services we offer or provide though it.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To allow you to participate in interactive features on our Website.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>In any other way we may describe when you provide the information.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>For any other purpose with your consent.</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We may also use your information to contact you about our own and third parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile. For more information, see&nbsp;</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your Choices About Our Collection, Use, and Disclosure of Your Information.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We may use the information we collect to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Disclosure of Your Information</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>In addition, we may disclose personal information that we collect or you provide:</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To our subsidiaries and affiliates.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Company’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Company about our Website users is among the assets transferred.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To third parties to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them. For more information, see&nbsp;</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your Choices About Our Collection, Use, and Disclosure of Your Information</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To fulfill the purpose for which you provide it. For example, if you give us an email address to use the "email a friend" feature of our Website, we will transmit the contents of that email and your email address to the recipients.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>For any other purpose disclosed by us when you provide the information.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>With your consent.</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We may also disclose your personal information:&nbsp;</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To enforce our rights arising from any contracts entered into between you and us, including the websites Terms and Conditions and other agreements, include for billing and collection.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Company, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.&nbsp;</span></p>
                                </li>
                            </ul>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your Choices About Our Collection, Use, and Disclosure of Your Information</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of your information.</span></p>
                            <p>
                                <br />
                            </p>
                            <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Tracking Technologies.</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. You can choose whether or not to allow the Website to collect information through other tracking technologies by sending us an email stating your request to&nbsp;</span>
                                        <a href="mailto:support@wellthon.com" style={{ textDecoration: 'none' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#1155cc', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>[support@wellthon.helpscoutapp.com]</span></a><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the Website may then be inaccessible or not function properly.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Promotion by the Company.</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;If you do not want us to use your email address or contact information to promote our own or third parties' products or services, you can opt-out by sending us an email stating your request to&nbsp;</span>
                                        <a href="mailto:support@wellthon.com" style={{ textDecoration: 'none' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#1155cc', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>[support@wellthon.helpscoutapp.com]</span></a><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>.&nbsp;</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Targeted Advertising by the Company</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>. If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt-out by sending us an email stating your request to&nbsp;</span>
                                        <a href="mailto:support@wellthon.com" style={{ textDecoration: 'none' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#1155cc', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>[support@wellthon.helpscoutapp.com]</span></a><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>.</span></p>
                                </li>
                                <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }}>
                                    <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Disclosure of Your Information for Third-Party Advertising and Marketing.</span><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;If you do not want us to share your personal information with unaffiliated or non-agent third parties for advertising and marketing purposes, you can opt-out by sending us an email stating your request to&nbsp;</span>
                                        <a href="mailto:support@wellthon.com" style={{ textDecoration: 'none' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#1155cc', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>[support@wellthon.helpscoutapp.com]</span></a><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>.&nbsp;</span></p>
                                </li>
                            </ul>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the NAI's website.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>California residents may have additional personal information rights and choices. Please see [Your California Privacy Rights] for more information.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Accessing and Correcting Your Personal Information</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>You can review and change your personal information by logging into the Website and visiting your account profile page.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other App users. Proper access and use of information provided on the App, including User Contributions, is governed by our terms of use</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>.</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>California residents may have additional personal information rights and choices. Please see [Your California Privacy Rights] for more information.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Your California Privacy Rights</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit [Wellthon.com/terms-of-use-and-privacy-policy].</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to [</span>
                                <a href="mailto:support@wellthon.helpscoutapp.com" style={{ textDecoration: 'none' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#1155cc', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>support@wellthon.helpscoutapp.com</span></a><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>].&nbsp;</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Data Security</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Changes to Our Privacy Policy</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated.</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this privacy policy to check for any changes.</span></p>
                            <h2 dir="ltr" style={{ lineHeight: '1.25', marginTop: '18pt', marginBottom: '6pt' }}><span style={{ fontSize: '16pt', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Contact Information</span></h2>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</span></p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>OhioHealth Star Ventures Inc‬, 3430 OhioHealth Parkway, Columbus OH 43202,&nbsp;</span>
                                <a href="mailto:support@wellthon.helpscoutapp.com" style={{ textDecoration: 'none' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#1155cc', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>[support@wellthon.helpscoutapp.com]</span></a><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>.&nbsp;</span></p>
                            <p>
                                <br />
                            </p>
                            <p dir="ltr" style={{ lineHeight: '1.25', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>To register a complaint or concern, please email us at [</span>
                                <a href="mailto:support@wellthon.helpscoutapp.com" style={{ textDecoration: 'none' }}><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#1155cc', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>support@wellthon.helpscoutapp.com</span></a><span style={{ fontSize: '14px', fontFamily: 'Verdana', color: '#444444', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>].&nbsp;</span></p>
                        </div>

                    </div>
                </div>
                <div className={confirm}>
                    <Checkbox
                        checkboxValue={checkboxValue}
                        checked={checkboxValue}
                        handleChangeChecked={handleChangeChecked}
                        id="termsAndConditions"
                        name="termsAndConditions"
                        label="I hereby consent to the Wellthon Privacy Policy and Terms of Service"
                        styleName="Terms" />
                    {checkboxValue ? <Button text="Next" styleName="buttonSmaller" onClick={() => submit()} name="next" /> : <Button text="Next" styleName="buttonSmaller" styleName2="Disabled" />}
                </div>
            </div>
        </>
    )
}

export default Terms;