import React from "react";
// import { Link } from "react-router-dom";
import styles from './TextArea.module.scss';
// class names line passes in additional class name and the structure changes based off this new design
const TextArea = ({ value, limit, setFeedbackText }) => {
  const [content, setContent] = React.useState(value);

  const setFormattedContent = text => {
    text.length > limit ? setContent(text.slice(0, limit)) : setContent(text);
  };

  const { textBox, characterLimit, container } = styles;

  // useEffect(() => {
  //   setFormattedContent(content);
  // }, [content, setFormattedContent]);

  return (
    <div className={container}>
      <textarea
        onChange={event => {
          setFeedbackText(event.target.value)
          setFormattedContent(event.target.value)
        }}
        value={content}
        className={textBox}
        placeholder="Enter your feedback here"
      />
      <div className={characterLimit}>
        (Character limit {content.length}/{limit})
      </div>
    </div>
  );
};

export default TextArea;