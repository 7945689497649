import React from 'react';
import styles from './PasswordModals.module.scss';
import Modal from 'react-modal';
import history from '../../../../history';

Modal.setAppElement('#root');

const CanceledUserFalse = (props) => {
    const { showModal } = props;
    const { main, headerText, text, modal, overlay, buttonSignup } = styles;

    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <section className={main}>
                <div className={headerText}>Password updated.</div>
                <div className={text}>Log in</div>
                <div className={buttonSignup}
                    onClick={
                        () => history.push('/login')
                    }
                >
                    Click here
            </div>
            </section>
        </Modal>
    )
}

export default CanceledUserFalse;