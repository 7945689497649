// import for cross browser support
import 'whatwg-fetch';
import { throwError } from '../../error/Error';

// Types
const FETCH_USER_LOADING = 'FETCH_USER_LOADING';
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

// Reusable functions
const esc = encodeURIComponent;
export const queryFunc = (params) => Object.keys(params)
  .map(k => esc(k) + '=' + esc(params[k]))
  .join('&');

// Action Creators
export const fetchUser = (token) => {
  return async dispatch => {
    // loading before fetch
    dispatch(fetchUserLoading());
    // passed in parameters
    const params = {
      token
    };
    const query = queryFunc(params);
    const url = `${process.env.REACT_APP_API_URL}/users?${query}`;

    const response = await fetch(url);
    console.log("response", response);
    if (!response.ok) {
      console.log('Could not fetch user', response);
      dispatch(fetchUserError(response));
      throwError('Could not fetch user', response);
    } else {
      response.json().then(data => {
        dispatch(fetchUserSuccess(data))
      }
      )
    }
  };
};

const fetchUserLoading = () => ({
  type: FETCH_USER_LOADING
});

const fetchUserSuccess = data => ({
  type: FETCH_USER_SUCCESS,
  user: data
});

const fetchUserError = error => ({
  type: FETCH_USER_ERROR,
  error
});


const initialState = {
  userData: null,
  userLoading: false,
  userError: null
};

// Reducer
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_LOADING:
      return { ...state, userLoading: true };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        userData: action.user,
        userLoading: false,
        userError: null
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        userData: false,
        userError: action.error
      };
    default:
      return state;
  }
}

export default userReducer;