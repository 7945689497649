import React, { useEffect, useState } from "react";
// import history from "../history";
import { Route } from "react-router-dom";
import { useAuth0 } from '../auth/Auth';
import ScrollToTopOnMount from '../ScrollRestoration';
// import { postData } from '../api/api';
// import history from "../history";
// import { throwError } from '../error/Error';

const PrivateRouteAuth = ({ component: Component, path, ...rest }) => {
  const { howFarAlongIsUser } = useAuth0();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // debugger;
    async function fetchPage() {

      // if (localStorage.getItem('old_token') !== null) {

      //   let params;
      //   if (user.email === "") {
      //     params = {
      //       oldToken: localStorage.getItem('old_token'),
      //       email: localStorage.getItem('old_email'),
      //       newToken: user.sub
      //     }
      //   } else {
      //     params = {
      //       oldToken: localStorage.getItem('old_token'),
      //       newToken: user.sub
      //     }
      //   }
        
      //   const postRequestBody = {};
      //   // debugger;
      //   return postData(postRequestBody, params, "provider").catch(
      //     err => {
      //       throwError(err)
      //     }
      //   ).then(
      //     ()=> {
      //       history.push('/home')
      //     }
      //   )

      //   }

      await howFarAlongIsUser().then(setLoaded(true))
    }
    fetchPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render = props => <Component {...props} />;
  return loaded && <><ScrollToTopOnMount /><Route path={path} render={render} {...rest} /></>
};

export default PrivateRouteAuth;
