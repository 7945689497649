import React from 'react';
import Button from '../../../components/Button/Button';
import styles from './VideoModals.module.scss';
import MainHeader from '../../../components/Headers/MainHeader';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const MessageSentModal = props => {

    const { showModal, toggleMessageSentModal } = props;

    const { noWorkoutText, center, modal, overlay, main } = styles;

    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <section className={main}>
                <MainHeader text="Message Sent" name="messageSent" styleName="mainHeaderPricingSmallerMargin" />
                <div className={noWorkoutText}>We’re sorry we could not offer you a safe workout at this time.
                <br />
                Customer Service has been notified of the problem and will contact you shortly at
                {props.email}.
            </div>
                <div className={center}>
                    <Button text="Return Home" styleName="buttonSmaller" onClick={() =>
                        toggleMessageSentModal()
                    } name="returnHome" />
                </div>
            </section>
        </Modal>
    )
}

export default MessageSentModal;