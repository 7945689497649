export const dateFormatted = (dateInput) => {
    var date = new Date(dateInput);
    var datestring =
        ("0" + (date.getMonth() + 1).toString()).substr(-2)
        + "/" + ("0" + date.getDate().toString()).substr(-2)
        + "/" + (date.getFullYear().toString()).substr(2)
    return datestring;
}

export const dateFormattedTime = (dateInput) => {
    const date = new Date(dateInput);
    let identifier;
    let hour = date.getHours();

    if (hour === 12) { //If hour is 12 then should set AM PM identifier to PM
        identifier = 'PM';
    }
    else if (hour === 0) { //If hour is 0 then set to 12 for standard time 12 AM
        hour = 12;
        identifier = 'AM';
    }
    else if (hour > 12) { //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
        hour = hour - 12;
        identifier = 'PM';
    } else {
        identifier = 'AM';
    }

    var datestringTime =
        (hour)
        + ":" + (date.getMinutes().toString().length > 1 ? date.getMinutes().toString() : "0" + date.getMinutes().toString())
        + " " + identifier
    return datestringTime;
};