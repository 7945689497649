import React, { useState } from 'react';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import styles from './EditAccount.module.scss';
import useFormValidation from "../../../hooks/useFormValidation";
import validateAuth from "../../../hooks/validateAuth";
import classNames from 'classnames/bind';
import ScrollToTopOnMount from '../../../ScrollRestoration';
import { useAuth0 } from '../../../auth/Auth';
import AppleIcon from "../../../assets/AppleIcon.png";
import GoogleIcon from "../../../assets/GoogleIcon.png";
import FacebookIcon from "../../../assets/IconFacebook.png";

// signup page for creating auth0 account
const EditAccount = () => {

    const INITIAL_STATE = {
        email: "",
        emailConfirm: "",
        password: ""
    };

    // checkbox is for showing or hiding password
    const [checkboxValue, setCheckboxValue] = useState(false);

    const [thirdParty, setThirdParty] = useState(false);

    const handleChangeChecked = (event) => {
        setCheckboxValue(event.target.checked);
        togglePasswordMask()
    }

    const { signUpWithEmail, signUpApple, signUpGoogle,
        signUpFacebook,
        authError, user } = useAuth0();

    const authenticateUser = async () => {
        const { email, password } = values;
        localStorage.setItem('old_token', user.sub)
        localStorage.setItem('old_email', user.email)

        await signUpWithEmail(
                email,
                password, 
            ).catch(err => {
                console.log(err);
                err === "Err" && setSubmitting(false)
            })
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        validated,
        isSubmitting,
        setSubmitting
    } = useFormValidation(INITIAL_STATE, validateAuth, authenticateUser);

    const { input, container, leftColumn, rightColumn,
        leftColumnMainContent,
        mainHeader,
        passwordRequirements, errorInput,
        errorText, passwordContainer, topRow, bottomRow,
        validatedInput,
        inputWrapper, iconStyle, ownInfo, horizontal, or, flexColumn,
        loginButtonApple, loginButtonFacebook, loginButtonGoogle
    } = styles;

    const [passwordIsMasked, setPasswordIsMasked] = useState(true);

    const togglePasswordMask = () => {
        setPasswordIsMasked(!passwordIsMasked)
    }

    console.log(INITIAL_STATE)

    return (
        <>
            <ScrollToTopOnMount />
            <div className={container}>
                <div className={topRow}>
                    <div className={mainHeader}>Update your account</div>
                    <br />
                </div>
                <div className={bottomRow}>
                    <div className={leftColumn}>
                        <div className={leftColumnMainContent}>
                            <form id="signup" onSubmit={handleSubmit}>
                                <div className={ownInfo}>User Your Own Information</div>
                                {authError !== null && <p className={styles.errorText}>*{authError[0]}</p>}
                                {authError !== null && authError[1] !== undefined && <p className={styles.errorText}>*{authError[1]}</p>}
                                {errors.email && !thirdParty && <p className={styles.errorText}>*{errors.email}</p>}
                                <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="email"
                                    value={values.email}
                                    className={classNames(errors.email && errorInput, validated.email && validatedInput, input)}
                                    autoComplete="off"
                                    id="signup-email"
                                    placeholder="Enter your email address here"
                                />
                                {errors.emailConfirm && !thirdParty && <p className={styles.errorText}>*{errors.emailConfirm}</p>}
                                <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="emailConfirm"
                                    value={values.emailConfirm}
                                    className={classNames(errors.emailConfirm && errorInput, validated.emailConfirm && validatedInput, input)}
                                    autoComplete="off"
                                    id="signup-email-confirm"
                                    placeholder="Confirm your email address"
                                />
                                <div className={passwordRequirements}>Password must contain at least 8 characters</div>
                                {errors.password && !thirdParty && <p className={errorText}>*{errors.password}</p>}
                                <div className={passwordContainer}>
                                    <div className={inputWrapper}>
                                        <input
                                            onChange={handleChange}
                                            type={passwordIsMasked ? 'password' : 'text'}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            className={classNames(errors.password && errorInput, validated.password && validatedInput, input)}
                                            name="password"
                                            placeholder="Create a password"
                                            id="signup-password"
                                        />
                                        {validated.password && <i className={classNames("icon-ok", iconStyle)} />}
                                    </div>
                                    <Checkbox
                                        checkboxValue={checkboxValue}
                                        handleChangeChecked={handleChangeChecked}
                                        id="showPassword"
                                        name="showPassword"
                                        label="Show Password"
                                    />
                                </div>
                                <br />
                                <Button
                                    disabled={isSubmitting}
                                    name="signup"
                                    buttonType="submit"
                                    styleName="buttonSmaller"
                                    text="Create Account" />
                            </form>
                            {/* <div className={alreadyAMember}>Already a member?&nbsp;
                        <Link to="/login" className={blueSpan}>
                                    Sign In
                        </Link>
                            </div> */}
                        </div>

                    </div>
                    <div className={flexColumn}>
                        <div className={horizontal} />
                        <div className={or}>OR</div>
                        <div className={horizontal} />
                    </div>
                    <div className={rightColumn}>
                        <div className={loginButtonApple} onClick={() => {
                            localStorage.setItem('old_token', user.sub)
                            localStorage.setItem('old_email', user.email)
                            signUpApple()}}><img src={AppleIcon} alt="apple continue" className={styles.logoContinue} />Sign In With Apple</div>

                        <div className={loginButtonFacebook} onClick={() => {
                            localStorage.setItem('old_token', user.sub)
                            localStorage.setItem('old_email', user.email)
                            setThirdParty(true)
                            signUpFacebook()
                        }}><img src={FacebookIcon} alt="apple continue" className={styles.logoContinue} />Sign In With Facebook</div>

                        <div className={loginButtonGoogle} onClick={() => {
                            localStorage.setItem('old_token', user.sub)
                            localStorage.setItem('old_email', user.email)
                            setThirdParty(true)
                            signUpGoogle()
                        }}><img src={GoogleIcon} alt="apple continue" className={styles.logoContinue} />Sign In With Google</div>
                    </div>
                </div >
            </div>
        </>
    )
}

export default EditAccount;