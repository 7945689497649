const validateAuth = (values) => {
    let errors = {};
    let validations = {};
 
    // firstName: User's first name.
    if (!values.firstName) {
      errors.firstName = "Please enter your first name";
    }

    // lastName: User's last name.  
    if (!values.lastName) {
      errors.lastName = "Please enter your last name";
    }
    
    // lastName: User's last name.  
    if (!values.healthProgram) {
      errors.healthProgram = "Please select your health program";
    }
    
    return {errors, validations};

  }
  

  export default validateAuth;