import React, { useState } from 'react';
import styles from './InputEmail.module.scss';
import { useAuth0 } from '../../../auth/Auth';
import { postData, fetchUser } from '../../../api/api';
import Button from '../../../components/Button/Button';
import useFormValidation from "../../../hooks/useFormValidation";
import validateEnterEmail from "../../../hooks/validateEnterEmail";
import classNames from 'classnames/bind';
import MainHeader from '../../../components/Headers/MainHeader';
import history from "../../../history";


const INITIAL_STATE = {
    email: ""
};

// forgot password screen- reset your password
const InputEmail = () => {

    const { user } = useAuth0();

    const [error, setError] = useState("");

    const authenticateUser = async () => {
        const { email } = values

        let params;

        const tempToken = localStorage.getItem('tempToken')

        if ((tempToken || {}).length > 0) {
            params = {
                token: user.sub,
                tempToken: tempToken,
                email: email.toLowerCase()
            };
        } else {
            params = {
                token: user.sub,
                email: email.toLowerCase()
            }
        }

        const postRequestBody = {
        };

        await postData(postRequestBody, params, "users").then(
            async response => {
                if (JSON.parse(response).Error) {
                    setError(JSON.parse(response).Msg)
                    return "Error";
                } else {
                    const data_1 = await fetchUser(user.sub);
                    if (data_1.Profile.TermsAgreed) {
                        return history.replace('/profile');
                    }
                    else if (data_1.Weight.Current > 0) {
                        return history.replace('/agree-to-terms');
                    } else if (data_1.AssessmentInfo.LastCompleteAssessmentDate !== "" && data_1.AssessmentInfo.LastCompleteAssessmentDate !== null && data_1.AssessmentInfo.LastCompleteAssessmentDate !== undefined) {
                        return history.replace('/vitals');
                    } else {
                        return history.replace('/pre-assessment')
                    }
                }
            })
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
    } = useFormValidation(INITIAL_STATE, validateEnterEmail, authenticateUser);

    const { main, text, background, content, input, bottomButtons,
        headerContainer, errorInput, errorText } = styles;

    return (
        <div className={background}>
            <section className={main}>
                <form onSubmit={handleSubmit}>
                    <div className={headerContainer}>
                        <MainHeader text="Update Your Login" styleName="mainHeaderAltModal" />
                    </div>
                    <div className={text}>Please enter your email address</div>
                    <div className={content}>
                        {error && <div className={errorText}>{error}</div>}
                        {errors.email && <p className={errorText}>*{errors.email}</p>}
                        <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            value={values.email}
                            className={classNames(errors.email && errorInput, input)}
                            autoComplete="off"
                            id="login-email"
                            placeholder="Enter your email address here"
                        />
                        <div className={bottomButtons}>
                            <Button
                                buttonType="submit"
                                onBlur={handleBlur}
                                text="Update Email Address"
                                styleName="buttonSmaller"
                            />
                        </div>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default InputEmail;