const validateWeight = (values) => {
  let errors = {};
  let validations = {};

  function isNormalInteger(str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  }

  if (!values.weight || values.weight <= 0 || values.weight >= 1000) {
    errors.weight = "Please enter a weight greater than 0 and less than 1000";
  }
  else if (!isNormalInteger(values.weight)) {
    errors.weight = "Weight must be a whole number";
  }

  return { errors, validations };

}


export default validateWeight;