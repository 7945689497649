import React from "react";
// import { Link } from "react-router-dom";
import styles from './Button.module.scss';
import Spinner from '../Spinner/Spinner';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
// class names line passes in additional class name and the structure changes based off this new design
const Button = (props) => {
  return (
    <button
      className={classNames(styles[`${props.styleName}`], styles[`${props.styleName2}`], styles[`${props.styleName3}`], styles.button)}
      type={props.buttonType}
      disabled={props.disabled}
      onClick={
        props.onClick
      }
      name={props.name}
    >
      {!props.disabled ? props.text : <Spinner loading={true} />}
      {props.trianlge && <div className={styles.arrowRight}></div>}
    </button>
  )
};

Button.propTypes = {
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
  styleName: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string
};

export default Button;