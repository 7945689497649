import 'whatwg-fetch';

const esc = encodeURIComponent;
export const queryFunc = (params) => Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');


export const postData = async (postRequestBody, params, endpoint) => {

    const query = queryFunc(params);
    const url = `${process.env.REACT_APP_API_URL}/${endpoint}?${query}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postRequestBody)
        });
        const responseText = await response.text();
        return (responseText);
    }
    catch (error) {
        console.error(error);
        return (error);
    }
};

export const fetchUser = async (token) => {

    var params = {
        token
    };

    const query = queryFunc(params);

    const url = `${process.env.REACT_APP_API_URL}/users?${query}`;

    const response = await fetch(url);
    if (!response.ok) {
        console.log('Could not fetch user', response);
        // throw new Error('Could not fetch user');
    }
    return response.json();
};

export const fetchAssessmentQuestion = async () => {

    var params = {};

    const query = queryFunc(params);

    const url = `${process.env.REACT_APP_API_URL}/v2-assessments?${query}`;

    const response = await fetch(url);
    if (!response.ok) {
        console.log('Could not fetch question', response);
        throw new Error('Could not fetch question');
    }
    return response.json();
};

export const fetchAssessmentQuestionWithToken = async (token) => {

    var params = {
        token
    };

    const query = queryFunc(params);

    const url = `${process.env.REACT_APP_API_URL}/v2-assessments?${query}`;

    const response = await fetch(url);
    if (!response.ok) {
        console.log('Could not fetch question', response);
        throw new Error('Could not fetch question');
    }
    return response.json();
};



// export const fetchProfilePic = async (token) => {

//     var params = {
//         token,
//     };

//     const query = queryFunc(params);

//     const url = `${process.env.REACT_APP_API_URL}/picture?${query}`;

//     const response = await fetch(url);
//     if (!response.ok) {
//         throw new Error('Could not fetch profile picture!');
//     }
//     return response.blob();
// };

export const fetchPlansOffer = async (offer) => {

    var params = {
        offer,
        platform: "web"
    };

    const query = queryFunc(params);

    const url = `${process.env.REACT_APP_API_URL}/plans?${query}`;

    const response = await fetch(url);
    if (!response.ok) {
        console.log('Could not fetch offer', response);
        throw new Error('Could not fetch offer');
    }
    return response.json();
};

export const fetchPreviewWorkout = async (token, minutes) => {

    var params = {
        token,
        preview: true,
        max_duration: minutes
    };

    const query = queryFunc(params);

    const url = `${process.env.REACT_APP_API_URL}/workouts?${query}`;

    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Unfortunately, based on your Health Assessment, we are unable to offer you a safe workout at this time. Please contact Wellthon Customer Support at support@wellthon.helpscoutapp.com so we can assist you further.');
    }
    return response.json();
};

// old workouts using mood
// export const fetchWorkouts = async (token) => {

//     var params = {
//         token,
//         mood: 1
//     };

//     const query = queryFunc(params);

//     const url = `${process.env.REACT_APP_API_URL}/workouts?${query}`;

//     const response = await fetch(url);
//     if (!response.ok) {
//         console.log('Could not fetch user workouts', response);
//         throw new Error('Could not fetch user workouts');
//     }
//     return response.json();
// };

// new get workouts use mood
// GET /workouts?token=TOKEN&min_duration=MINUTES&max_duration=MINUTES
// export const fetchWorkouts = async (token, max) => {
//     var params = {
//         token,
//         min_duration: 0,
//         max_duration: max
//     };

//     const query = queryFunc(params);

//     const url = `${process.env.REACT_APP_API_URL}/workouts?${query}`;

//     const response = await fetch(url);
//     if (response.status === 500) {
//         return response.text()
//     }
//     if (!response.ok) {
//         console.log('Could not fetch user workouts', response);
//         throw new Error('Could not fetch user workouts');
//     }
//     return response.json();
// };