import React from "react";
import styles from './Headers.module.scss';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const MainHeader = (props) => {
  return (
    <div
      className={classNames(styles[`${props.styleName}`], styles[`${props.styleName2}`], styles.mainHeader)}
      name={props.name}
    >
      {props.text}
    </div>
  )
};

MainHeader.propTypes = {
  styleName: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string
};

export default MainHeader;