import React from "react";
import styles from './HamburgerButton.module.scss';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Gear from '../../assets/Gear.png';

const HamburgerButton = (props) => {
  const { hamburger, hamburgerMenu, text } = styles;
  return (
    <div className={hamburgerMenu} onClick={props.toggleMenu}>
    <div className={hamburger} >
    <img src={Gear} alt="gear logo" className={styles.gearLogo}/>
    </div>
    <div className={classNames(styles[`${props.styleNameText}`], text)}>
      Menu
    </div>
    </div>
  )
};

HamburgerButton.propTypes = {
  toggleMenu: PropTypes.func.isRequired
};

export default HamburgerButton;