import React from 'react';
import styles from './EditVitalsModal.module.scss';
import Button from '../../../components/Button/Button';
import { useAuth0 } from '../../../auth/Auth';
import { postData } from '../../../api/api';
import { throwError } from '../../../error/Error';
import useFormValidation from "../../../hooks/useFormValidation";
import validateWeight from "../../../hooks/validateWeight";
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import IconScale from '../../../assets/IconScale.svg';

Modal.setAppElement('#root');

const EditLocationModal = (props) => {

    const { toggleModalVitals, showVitalsModal, userData, fetchUser, setNotificationText } = props;
    const { headerText, modal, content, input,
        bottomButtons, errorInput, overlay, weightLogo, headerContainer,
        inputHeaderText, inputContainer, inputModifier, inputTextSize, weightContainer, topFlex } = styles;

    const INITIAL_STATE = {
        weight: ""
    }

    const { user } = useAuth0();

    const updateWeight = async () => {
        const { weight } = values

        const params = {
            token: user.sub,
            "weight": weight
        };

        const postRequestBody = {};
        await postData(postRequestBody, params, "weight")
            .then(() => fetchUser(props.token))
            .then(() => {
                setSubmitting(false);
                setNotificationText("Weight has been updated")
                resetValues();
                toggleModalVitals();
            }
            )
            .catch(error => throwError(error))
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        setSubmitting,
        resetValues
    } = useFormValidation(INITIAL_STATE, validateWeight, updateWeight);

    // const checked = (selectedOption, option) => {
    //     return selectedOption === option
    // }

    return (
        <Modal isOpen={showVitalsModal} className={modal} overlayClassName={overlay}>
            <form onSubmit={handleSubmit}>
                <div className={headerContainer}>
                    <div className={headerText}>Update Your Weight</div>
                    <img src={IconScale} alt="vital logo" className={weightLogo} />
                </div>
                <div className={content}>
                    {errors.weight && <p className={styles.errorText}>*{errors.weight}</p>}
                    <div className={topFlex}>
                        <div className={inputContainer}>
                            <div className={inputHeaderText}>Previous Weight</div>
                            <div className={weightContainer}>
                                <div className={inputTextSize}>{userData.Weight.Current}</div>
                                <div className={inputModifier}>lbs.</div>
                            </div>
                        </div>
                        <div className={inputContainer}>
                            <div className={inputHeaderText}>New Weight</div>
                            <div className={weightContainer}>
                                <input className={classNames(errors.weight && errorInput, input)} name="weight" placeholder={values.weight} onChange={handleChange} value={values.weight} onBlur={handleBlur} />
                                <div className={inputModifier}>lbs.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className={bottomButtons}>
                        {values.weight !== "" ?
                            <Button
                                text="Submit"
                                buttonType="submit"
                                styleName="buttonSmaller"
                                name="submit"
                                disabled={isSubmitting}
                            />
                            :
                            <Button
                                text="Submit"
                                styleName="buttonSmaller"
                                name="submit"
                                styleName2="Disabled" />
                        }
                        <div className={styles.cancel} onClick={toggleModalVitals}>Cancel</div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

EditLocationModal.propTypes = {
    toggleModalVitals: PropTypes.func.isRequired,
    showEditLocationModal: PropTypes.bool,
    userData: PropTypes.object,
    fetchUser: PropTypes.func
}

export default EditLocationModal;
