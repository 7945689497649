import React, { useCallback, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
// import { postData } from '../api/api';
import { useAuth0 } from '../auth/Auth';
import { fetchUser } from '../api/api';
import { throwError } from '../error/Error';

const PrivateRoute = ({ component: Component, path, user, ...rest }) => {
  const { authenticated } = useAuth0();

  const render = props => <Component {...props} />;

  // need to check and see if user paid
  const userInfo = useCallback(() => {
    // debugger;
    user !== null &&
      fetchUser(user.sub)
        .catch((err) => {
          console.log("INSIDE OF ERROR BLOCK")
          throwError(err)
        });
  }, [user]);

  useEffect(() => {
    userInfo();
  }, [userInfo]);

  return (
    authenticated
      ? (<Route path={path} render={render} {...rest} />)
      : (<Redirect to={{ pathname: '/login' }} />));
};

export default PrivateRoute;