import { useEffect } from 'react';
import { useAuth0 } from '../../auth/Auth';

const Home = () => {

    const { howFarAlongIsUser } = useAuth0();

    useEffect(() => {
        // debugger;
        async function fetchPage() {
            await howFarAlongIsUser()
        }
        fetchPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        null
    );
};

export default Home;