import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../auth/Auth";
import Button from "../../components/Button/Button";
import Rectangle from "../../components/Rectangle/Rectangle";
import MainHeader from "../../components/Headers/MainHeader";
import ReactRouterPropTypes from "react-router-prop-types";
import history from "../../history";
import { dateFormattedTime, dateFormatted } from "../../api/sharedFunctions";
import ErrorWorkoutModal from "./VideoModals/ErrorWorkoutModal";
import MessageSentModal from "./VideoModals/MessageSentModal";
import ToggleModal from "./VideoModals/ToggleModal";
import IconCardio from "../../assets/IconCardio.png";
import IconStrength from "../../assets/IconStrength.png";
import IconDuration from "../../assets/IconClockBlue.png";
import PreviewWorkout from "../../assets/PreviewWorkout.png";
import IconStrengthRed from "../../assets/IconStrengthRed.png";
import IconCardioRed from "../../assets/IconCardioRed.png";
import ToggleButton from "react-toggle-button";

import styles from "./HomeScreen.module.scss";

import { connect } from "react-redux";
import { fetchUser } from "../../redux/ducks/user";
import { resetNotification } from "../../redux/ducks/notifications";
import { setTime } from "../../redux/ducks/workouts";

import { postData, fetchPreviewWorkout } from "../../api/api";
import { throwError } from "../../error/Error";

const HomeScreen = (props) => {
  const { user } = useAuth0();
  const {
    notificationsReducer: { notification },
    resetNotification,
  } = props;

  const [errorWorkoutModal, setErrorWorkoutModal] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [showToggleModal, setShowToggleModal] = useState(false);

  const showErrorWorkoutModal = () => {
    setErrorWorkoutModal(!errorWorkoutModal);
  };

  const toggleMessageSentModal = () => {
    setMessageSent(!messageSent);
  };

  const toggleToggleModal = () => {
    setShowToggleModal(!showToggleModal);
  };

  useEffect(() => {
    if (notification !== "") {
      const timer = setTimeout(() => {
        resetNotification();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification, resetNotification]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "HomeScreen",
    });
  }, []);

  useEffect(() => {
    const fbq = window.fbq || function () {};
    fbq("trackCustom", "HomeScreen", { content_name: "HomeScreen" });
  }, []);

  const {
    userReducer: { userData },
    fetchUser,
    setTime,
  } = props;

  useEffect(() => {
    !userData && fetchUser(user.sub);
    // also need to call if reset redux
    // this comes from the health assessment
  }, [userData, fetchUser, user.sub]);

  const [minutes, setMinutes] = useState(30);
  const [workoutPreview, setWorkoutPreview] = useState(null);

  useEffect(() => {
    localStorage.setItem('previewMinutes', minutes);
  }, [minutes]);

  useEffect(() => {
    localStorage.setItem('workoutPreview', JSON.stringify(workoutPreview));
  }, [workoutPreview]);

  const submit = async () => {
    let max;
    switch (value) {
      case 1:
        max = 15;
        break;
      case 2:
        max = 20;
        break;
      case 3:
        max = 30;
        break;
      case 4:
        max = 45;
        break;
      case 5:
        max = 60;
        break;
      default:
        max = 30;
    }
    setTime(max);

    if (workoutType !== userData.Workouts.NextWorkoutType) {
      const params = {
        token: user.sub,
        selection: workoutType.toLowerCase(),
      };

      const postRequestBody = {};
      postData(postRequestBody, params, "workout-type")
        .then(() => fetchUser(user.sub))
        .then(() => {
          if (userData.AssessmentInfo.WorkoutPossible) {
            history.push("/workout");
          } else {
            setErrorWorkoutModal(true);
          }
        })
        .catch((err) => throwError(err));
    } else {
      if (userData.AssessmentInfo.WorkoutPossible) {
        history.push("/workout");
      } else {
        setErrorWorkoutModal(true);
      }
    }
  };

  const setSelect = (type) => {
    const params = {
      token: user.sub,
      selection: type,
    };

    const postRequestBody = {};
    postData(postRequestBody, params, "workout-type").then(() =>
      fetchUser(user.sub)
    );
  };

  const [value, setValue] = useState(3);
  const handleChange = (value) => {
    setValue(value);

    switch (value) {
      case 1:
        setMinutes(15);
        break;
      case 2:
        setMinutes(20);
        break;
      case 3:
        setMinutes(30);
        break;
      case 4:
        setMinutes(45);
        break;
      case 5:
        setMinutes(60);
        break;
      default:
        setMinutes(30);
    }
  };

  const [workoutType, setWorkoutType] = useState(
    userData && userData.Workouts.NextWorkoutType
  );

  const toggleWorkoutType = () => {
    if (workoutType === "Strength") {
      setWorkoutType("Cardio");
      setSelect("cardio");
    } else {
      setWorkoutType("Strength");
      setSelect("strength");
    }

    if (JSON.parse(localStorage.getItem("toggleOff")) !== true) {
      toggleToggleModal();
    }
  };

  useEffect(() => {
    userData !== null &&
      setWorkoutType((userData.Workouts || {}).NextWorkoutType);
  }, [userData]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const minutesList = [
    { id: 1, minutes: "15" },
    { id: 2, minutes: "20" },
    { id: 3, minutes: "30" },
    { id: 4, minutes: "45" },
    { id: 5, minutes: "60" },
  ];

  const submitNoWorkout = async () => {
    const params = {
      token: user.sub,
    };

    const postRequestBody = {
      type: "no_workout_available",
      platform: "web",
    };

    postData(postRequestBody, params, "support")
      .then(() => toggleMessageSentModal())
      .catch((error) => throwError(error));
  };

  const previewWorkout = async () => {
    fetchPreviewWorkout(user.sub, minutes)
      .then((data) => {
        setWorkoutPreview(data);
        history.push("/preview-workout");
      })
      .catch((error) => {
        alert("Unfortunately, based on your Health Assessment, we are unable to offer you a safe workout at this time. Please contact Wellthon Customer Support at support@wellthon.helpscoutapp.com so we can assist you further.")
        throwError(error)
      })
      
      ;
  };

  const {
    dashboard,
    top,
    topHeader,
    body,
    marginLeft,
    cards,
    card,
    cardTitle,
    box,
    boxContainer,
    box2,
    box3,
    workoutText,
    workoutTextDisabled,
    minutesNumber,
    minutesText,
    minutesContainer,
    minutesTriangle,
    dropdownItem,
    dropdown,
    durationContainer,
    minutesDropdownText,
    dropdownItemText,
    minutesTriangleBig,
    minutesTriangleBigUp,
    minutesTriangleUp,
    notifyText,
    notificationContainer,
  } = styles;


  return (
    <>
      {userData && (
        <ToggleModal
          showModal={showToggleModal}
          toggleToggleModal={toggleToggleModal}
        />
      )}
      {userData && (
        <MessageSentModal
          showModal={messageSent}
          toggleMessageSentModal={toggleMessageSentModal}
          email={userData.Profile.Email}
        />
      )}
      <ErrorWorkoutModal
        showModal={errorWorkoutModal}
        showErrorWorkoutModal={showErrorWorkoutModal}
        submitNoWorkout={submitNoWorkout}
        toggleMessageSentModal={toggleMessageSentModal}
      />
      {notification !== "" && (
        <div className={notificationContainer}>
          <div className={notifyText}>{notification}</div>
        </div>
      )}

      {userData && (
        <div className={dashboard}>
          {
            <>
              <div className={top}>
                {userData && (
                  <div className={topHeader}>
                    <MainHeader
                      text="Start Workout"
                      name="progressHeader"
                    />

                  <Rectangle styleName="coachBox">
                  {userData.Workouts.LastWorkout === "" ?
                  <><div className={styles.coachHeader}>Start Your First Workout</div>
                    <div className={styles.coachText}>Hello {userData.Profile.FirstName}. Welcome to Wellthon. Let’s start your first workout!</div></>:
                    <>
                    <div className={styles.coachHeader}>Start Your Next Workout</div>
                    <div className={styles.coachText}>Hello {userData.Profile.FirstName}. You last worked out on {dateFormatted(
                          userData.Workouts.LastWorkout
                        )} at {dateFormattedTime(
                          userData.Workouts.LastWorkout
                        )}. Let’s start your next workout!</div>
                    </>}
                    </Rectangle>
                  </div>
                )}
              </div>
              <div className={body}>
                <div className={cards}>
                  {userData &&
                    userData.Workouts.NextWorkoutType !== "Parkinson's" && (
                      <div className={card}>
                        {userData && (
                          <>
                            <div className={boxContainer}>
                            <div className={cardTitle}>Type of Workout</div>
                            <div className={box}>
                              <div className={styles.flexColumn} onClick={()=> toggleWorkoutType()}>
                                <img
                                  src={
                                    workoutType === "Cardio"
                                      ? IconCardioRed
                                      : IconCardio
                                  }
                                  alt="cardio"
                                  className={styles.workoutIcon}
                                />
                                <div
                                  className={
                                    workoutType === "Cardio"
                                      ? workoutText
                                      : workoutTextDisabled
                                  }
                                >
                                  Cardio
                                </div>
                              </div>
                              <div style={{marginBottom: 20}}>
                              <ToggleButton
                                value={workoutType === "Strength" || false}
                                inactiveLabel={""}
                                activeLabel={""}
                                onToggle={() => {
                                  toggleWorkoutType();
                                }}
                                colors={{
                                  activeThumb: {
                                    base: '#FFFFFF',
                                  },
                                  inactiveThumb: {
                                    base: '#FFFFFF',
                                  },
                                  active: {
                                    base: 'rgba(120, 120, 128, 0.16)',
                                    hover: 'rgba(120, 120, 128, 0.16)',
                                  },
                                  inactive: {
                                    base: 'rgba(120, 120, 128, 0.16)',
                                    hover: 'rgba(120, 120, 128, 0.16)',
                                  }
                                }}
                              />
                              </div>

                              <div className={styles.flexColumn} onClick={()=> toggleWorkoutType()}>
                                <img
                                  src={
                                    workoutType === "Strength"
                                      ? IconStrengthRed
                                      : IconStrength
                                  }
                                  alt="strength"
                                  className={styles.workoutIcon}
                                />
                                <div
                                  className={
                                    workoutType === "Strength"
                                      ? workoutText
                                      : workoutTextDisabled
                                  }
                                >
                                  Strength
                                </div>
                              </div>
                            </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  <div className={card}>
                    {userData && (
                      <div
                        onClick={() => {
                          setDropdownOpen(!dropdownOpen);
                        }}
                        className={
                          dropdownOpen
                            ? box3
                            : box2
                        }
                      >
                        <div className={cardTitle}>Length of Workout</div>
                        <div className={durationContainer}>
                          <img
                            src={IconDuration}
                            alt="duration"
                            className={styles.durationIcon}
                          />
                          <div className={minutesContainer}>
                            <div className={minutesNumber}>
                              {minutes}{" "}
                              <span
                                className={
                                  userData.Workouts.NextWorkoutType ===
                                  "Parkinson's"
                                    ? dropdownOpen
                                      ? minutesTriangleBigUp
                                      : minutesTriangleBig
                                    : dropdownOpen
                                    ? minutesTriangleUp
                                    : minutesTriangle
                                }
                              />
                            </div>
                            <div className={minutesText}>minutes</div>
                          </div>
                        </div>
                        {dropdownOpen && (
                          <div className={dropdown}>
                            {minutesList.map((item) => (
                              <div
                                className={dropdownItem}
                                key={item.id}
                                onClick={() => {
                                  handleChange(item.id);
                                }}
                              >
                                <span className={dropdownItemText}>
                                  {item.minutes}
                                </span>{" "}
                                <span className={minutesDropdownText}>
                                  minutes
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={marginLeft}>
                  <Button
                    text="Start Workout"
                    styleName="triangle"
                    onClick={() => submit()}
                    name="startWorkout"
                  />
                    <img
                    alt="Preview Workout"
                    src={PreviewWorkout}
                    className={styles.previewWorkout}
                    onClick={() => previewWorkout()}
                    />
                </div>
              </div>
            </>
          }
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  fetchUser,
  setTime,
  resetNotification,
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  notificationsReducer: state.notificationsReducer,
  workoutsReducer: state.workoutsReducer,
});

HomeScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
