import React from 'react';
import CreateAccount from './CreateAccount/CreateAccount';
import ReactRouterPropTypes from 'react-router-prop-types';

// depending on where you are in the signup flow direct to correct step
const SignUp = () => {

    return <CreateAccount/>
}

SignUp.propTypes = {
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    match: ReactRouterPropTypes.match
}

export default SignUp;