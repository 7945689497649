import React from "react";
import styles from './Headers.module.scss';
import classNames from 'classnames/bind';

import PropTypes from 'prop-types';

const SubHeader = (props) => {
  return (
    <div
      className={classNames(styles[`${props.styleName}`], styles.subHeader)}
    >
      {props.text}
    </div>
  )
};

SubHeader.propTypes = {
  text: PropTypes.string,
  styleName: PropTypes.string
}

export default SubHeader;