import React from 'react';
import styles from './VideoModals.module.scss';
import Modal from 'react-modal';
import Button from '../../../components/Button/Button';
import history from '../../../history';

Modal.setAppElement('#root');

const ErrorWorkoutModal = (props) => {
    const { showModal,
        // workoutFetchError 
    } = props;
    const { main, headerText2, modal, overlay } = styles;

    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <section className={main}>
                <div className={headerText2}>There has been an error.  Sorry for the inconvenience. Please return home and try again.</div>
                <Button text="Return Home" styleName="buttonSmaller" onClick={() => {
                    history.push('/home')
                }} name="returnHome" />
            </section>
        </Modal>
    )
}

export default ErrorWorkoutModal;