import React, { useState } from 'react';
import RadioButton from '../../../components/RadioButton/RadioButton';
import styles from './VideoModals.module.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const SkipModal = (props) => {

    const { showModal, toggleModal, play, title, nextSegment, videoState, id } = props;
    const { main, headerText, text, modal, overlay } = styles;

    const [selection, setSelection] = useState("");

    const submitSelection = async (selectedValue) => {
        if (selectedValue === "Resume workout") {
            toggleModal()
            setSelection("")
            play()
        }
        else {

            let newVideo =
            {
              "id": id,
              "elapsed_time": Math.round(videoState.currentTime),
            }

            const videoObj = JSON.parse(localStorage.getItem('videos'));
            if (videoObj === null) {
                const arr = [];
                arr.push(newVideo);
                localStorage.setItem("videos", JSON.stringify(arr))
            } else {
                videoObj.push(newVideo)
                localStorage.setItem("videos", JSON.stringify(videoObj))
            }

            nextSegment()
            toggleModal()
            setSelection("")
        }
    }

    // handle change is run every time an input changes
    const handleChange = (event) => {
        setSelection(event.target.value);
        const selectedValue = event.target.value;
        // setTimeout(() => {
        submitSelection(selectedValue)
        // }, 700);
    }

    const checked = (selectedOption, option) => {
        console.log("SELECTED: ", selectedOption, " OPTION: ", option)
        return selectedOption === option
    }

    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <section className={main}>
                <div className={headerText}>Skip Workout?</div>
                <div className={text}>
                    Do you want to:
                    </div>
                <RadioButton
                    choices={[
                        {
                            "Choice": `Skip ${title}`,
                            "Value": 1
                        },
                        {
                            "Choice": "Resume workout",
                            "Value": 3
                        }
                    ]}
                    name="selection"
                    checked={checked}
                    onChange={handleChange}
                    styleName="verticalFlexStart"
                    selectedOption={selection}
                />
            </section>
        </Modal>
    )
}

export default SkipModal;


