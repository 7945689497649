import React, { useState } from 'react';
import styles from './ResetPassword.module.scss';
import { useAuth0 } from '../../../auth/Auth';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import useFormValidation from "../../../hooks/useFormValidation";
import validateResetPassword from "../../../hooks/validateResetPassword";
import classNames from 'classnames/bind';
import MainHeader from '../../../components/Headers/MainHeader';
import PasswordError from "./PasswordModals/PasswordError";
import PasswordSuccess from "./PasswordModals/PasswordSuccess";
import queryString from 'query-string';
import history from "../../../history";

const INITIAL_STATE = {
    password: "",
};

// forgot password screen- reset your password
const ResetPassword = () => {
    const token = queryString.parse(history.location.search).reset;

    // POST /password-reset?token=<uniqtokentoresetthisemailpassword>&new_password=<blahblahblah>.

    const { resetPasswordPassword } = useAuth0();

    const authenticateUser = async () => {
        const { password } = values;
        console.log("PASS", password, "TOKEN", token);
        await resetPasswordPassword(token, password).then(
            res => {
                console.log("RES", res)
                JSON.parse(res).Error === true ? setPasswordResetError(true) :
                setPasswordResetSuccess(true);
            }
        )
    }

    // error with password reset link
    const [passwordResetError, setPasswordResetError] = useState(false);
    // success with password reset link
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    // checkbox is for showing or hiding password
    const [checkboxValue, setCheckboxValue] = useState(false);

    const handleChangeChecked = (event) => {
        setCheckboxValue(event.target.checked);
        togglePasswordMask()
    }

    const [passwordIsMasked, setPasswordIsMasked] = useState(true);

    const togglePasswordMask = () => {
        setPasswordIsMasked(!passwordIsMasked)
    }


    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
    } = useFormValidation(INITIAL_STATE, validateResetPassword, authenticateUser);

    const { main, text, background, content, input, bottomButtons,
        headerContainer, errorInput, errorText } = styles;

    return (
        <>
        <PasswordError showModal={passwordResetError} />
        <PasswordSuccess showModal={passwordResetSuccess} />
        <div className={background}>
            <section className={main}>
                <form onSubmit={handleSubmit}>
                    <div className={headerContainer}>
                        <MainHeader text="Reset your Wellthon password" styleName="mainHeaderAltModal" />
                    </div>
                    <div className={text}>Please choose a password that is at least 8 characters long</div>
                    <div className={content}>
                        {errors.password && <p className={errorText}>*{errors.password}</p>}
                        {/* {passwordResetError !== null &&  */}
                        {/* <p className={styles.errorText}>*{passwordResetError}</p> */}
                        {/* <p className={styles.errorText}>*Please make sure you use the link from the most recent email</p> */}
                        {/* // } */}
                        <input
                            onChange={handleChange}
                            type={passwordIsMasked ? 'password' : 'text'}
                            onBlur={handleBlur}
                            value={values.password}
                            className={classNames(errors.password && errorInput, input)}
                            name="password"
                            placeholder="New Password"
                            id="signup-password"
                        />
                        <Checkbox
                            checkboxValue={checkboxValue}
                            handleChangeChecked={handleChangeChecked}
                            id="showPassword"
                            name="showPassword"
                            label="Show Password"
                        />
                        <div className={bottomButtons}>
                            <Button
                                buttonType="submit"
                                onBlur={handleBlur}
                                text="Update Password"
                                styleName="buttonSmaller"
                            />
                        </div>
                    </div>
                </form>
            </section>
        </div>
        </>
    )
}

export default ResetPassword;