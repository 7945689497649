import { useEffect } from 'react'
import { useAuth0 } from '../../auth/Auth'

const Auth0CallbackPage = (props) => {

  const { handleAuthentication } = useAuth0();

  console.log("AUTH0 CALLBACK", props);
  useEffect(() => {
    handleAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    null
  )
}

export default Auth0CallbackPage
