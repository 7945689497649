import React from 'react';
import Button from '../../components/Button/Button';
import MainHeader from '../../components/Headers/MainHeader';
import RadioButton from '../../components/RadioButton/RadioButton';
import Checkbox from '../../components/Checkbox/Checkbox';
import styles from './LifeAssessment.module.scss';
import PropTypes from 'prop-types';
import { useSpring, animated, config } from 'react-spring';

const AnswerQuestions = props => {

    const { data,
        transition,
        handleChangeChecked, values, submitAnswer,
        handleChange, submitting, assessmentError, handleChangeRadio } = props;

    const animation = useSpring({ opacity: transition ? 1 : 0, config: config.molasses })


    const renderQuestion = () => {
        const { question, checkBoxes, questionContainer, citation, input } = styles;

        const checkedAnswer = (selectedOption, option) => {
            console.log("kdsfjjsdjffdlsjk", selectedOption, "dlksfljsd", option)
            return selectedOption === option
        }

        // need to make sure not all true false
        var allFalse = (myObj) => Object.keys(myObj).every((k) => { return myObj[k] === false });
        const { backgroundStyleChecked, backgroundStyleNotChecked, errorText, paddingLeft, radioButtonPadding } = styles;

        console.log("kfdsjsfdkjl", data)
        console.log("kfdsjsfdkjl", values)
        return (
            <div className={questionContainer}
            >
                <animated.div style={animation} className={question}>{data[0].question_text}</animated.div>
                {/* <div className={question}>{data[0].question_text}</div> */}
                <div
                    // style={animation} 
                    className={checkBoxes}>
                    {data[0].question_type === "choose_many" ? data[0].choices.map(question => {
                        console.log("values[question.choice_id]", values[question.choice_id])
                        console.log("values", values)
                        console.log("question", question)
                        return (<div key={question.choice_id} className={(data[0].clarifying_answers || {}).length > 0 && values[question.choice_id] ? backgroundStyleChecked : backgroundStyleNotChecked}><Checkbox
                            checkboxValue={values[question.choice_id] || false}
                            handleChangeChecked={handleChangeChecked}
                            id={question.choice_id}
                            name={question.choice_id}
                            label={question.choice_text}
                            key={question.choice_id}
                            styleName={values["none"] ? "disabled" : "assessments"}
                            checked={values[question.choice_id]}
                        />
                            {
                                values[question.choice_id] && data[0].clarifying_answers &&
                                <div className={radioButtonPadding}>
                                    <RadioButton
                                        choices={
                                            data[0].clarifying_answers.map((plan) => {
                                                return (
                                                    {
                                                        "Value": plan.choice_id,
                                                        "Choice": plan.choice_text,
                                                        "Assessment": true
                                                    }
                                                )
                                            })}
                                        name={`clarifyingAnswer${question.choice_id}`}
                                        checked={checkedAnswer}
                                        onChange={handleChange}
                                        selectedOption={parseInt(values[`clarifyingAnswer${question.choice_id}`]) || ""}
                                        styleName="verticalPaddingAssessment"
                                        styleName3="assessment"
                                        labelStyle="verticalPrices"
                                    // styleNameError={errors.monthSelection && "errorInput"}
                                    // onBlur={handleBlur}
                                    />
                                </div>
                            }
                        </div>)
                    }
                    ) :
                        <div className={backgroundStyleNotChecked}>
                            <RadioButton
                                choices={
                                    data[0].choices.map((question) => {
                                        console.log("1", values[question.choice_id])
                                        console.log("2", values)
                                        console.log("3", question.choice_id)
                                        return (
                                            {
                                                "Value": question.choice_id,
                                                "Choice": question.choice_text,
                                                "Assessment": true
                                            }
                                        )
                                    })}
                                name="choseOneAnswer"
                                checked={checkedAnswer}
                                onChange={handleChangeRadio}
                                selectedOption={parseInt(values.choseOneAnswer) || ""}
                                styleName="vertical"
                                styleName3="assessment"
                                labelStyle="verticalPrices"
                                key={question.choice_id}
                            // styleNameError={errors.monthSelection && "errorInput"}
                            // onBlur={handleBlur}
                            />
                        </div>
                    }
                    {
                        data[0].question_type === "choose_many" &&
                        <div className={backgroundStyleNotChecked}>
                            <Checkbox
                                checkboxValue={values["none"] || false}
                                handleChangeChecked={handleChangeChecked}
                                id="none"
                                name="none"
                                label="None of the above"
                                key="none"
                                checked={values["none"]}
                                styleName={values["none"] !== true && !allFalse(values) ? "disabledNone" : "assessmentsNone"}
                            />
                        </div>
                    }
                    {
                        data[0].question_type === "range30" &&
                        <div className={backgroundStyleNotChecked}>
                        <input
                            onChange={handleChange}
                            name="range30"
                            value={values.range30}
                            className={input}
                            type="number"
                            id="Range30"
                        />  
                        </div>
                    }
                </div>
                <div className={paddingLeft}>
                    <div className={errorText}>{assessmentError}</div>
                    {submitting ? <Button
                        text="Next Question"
                        styleName="buttonSmaller"
                        onClick={() => submitAnswer()}
                        name="NextQuestion"
                    /> :
                        <Button
                            text="Next Question"
                            styleName="buttonSmaller"
                            name="NextQuestion"
                            styleName2="Disabled" />
                    }
                </div>
                {data[0].fine_print && <div className={citation}>
                *{data[0].fine_print}
                </div>}
            </div>
        )
    }
    const { mainContainer } = styles;
    return (
        <div className={mainContainer}>

            <MainHeader text={data[0].header} styleName="assessmentHeader" name="assessmentHeader" />
            {/* <div className={questionLeadIn}>
                {data[0].question_text}
            </div> */}
            {renderQuestion()}
        </div>
    )
}

AnswerQuestions.propTypes = {
    data: PropTypes.array.isRequired,
    handleOptionChange: PropTypes.func,
    radioButtonVertical: PropTypes.string,
}

export default AnswerQuestions;