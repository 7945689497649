import React, { Component } from 'react';
import Hls from 'hls.js';

export default class HLSSource extends Component {
  constructor(props, context) {
    super(props, context);

    var config = {
      // autoStartLoad: true,
      startPosition: -1,
      // debug: false,
      // capLevelOnFPSDrop: false,
      // capLevelToPlayerSize: false,
      // defaultAudioCodec: undefined,
      // initialLiveManifestSize: 1,
      // maxBufferLength: 30,
      // maxMaxBufferLength: 600,
      // maxBufferSize: 60*1000*1000,
      // maxBufferHole: 0.5,
      // lowBufferWatchdogPeriod: 0.5,
      // highBufferWatchdogPeriod: 3,
      // nudgeOffset: 0.1,
      // nudgeMaxRetry: 3,
      // maxFragLookUpTolerance: 0.25,
      // liveSyncDurationCount: 3,
      // liveMaxLatencyDurationCount: Infinity,
      // enableWorker: true,
      // enableSoftwareAES: true,
      // manifestLoadingTimeOut: 10000,
      // manifestLoadingMaxRetry: 1,
      // manifestLoadingRetryDelay: 1000,
      // manifestLoadingMaxRetryTimeout: 64000,
      // startLevel: undefined,
      // levelLoadingTimeOut: 10000,
      // levelLoadingMaxRetry: 4,
      // levelLoadingRetryDelay: 1000,
      // levelLoadingMaxRetryTimeout: 64000,
      // fragLoadingTimeOut: 20000,
      // fragLoadingMaxRetry: 6,
      // fragLoadingRetryDelay: 1000,
      // fragLoadingMaxRetryTimeout: 64000,
      // startFragPrefetch: false,
      // testBandwidth: true,
      // fpsDroppedMonitoringPeriod: 5000,
      // fpsDroppedMonitoringThreshold: 0.2,
      // appendErrorMaxRetry: 3,
      // loader: customLoader,
      // fLoader: customFragmentLoader,
      // pLoader: customPlaylistLoader,
      // xhrSetup: XMLHttpRequestSetupCallback,
      // fetchSetup: FetchSetupCallback,
      // abrController: AbrController,
      // bufferController: BufferController,
      // capLevelController: CapLevelController,
      // fpsController: FPSController,
      // timelineController: TimelineController,
      // enableWebVTT: true,
      // enableCEA708Captions: true,
      // stretchShortVideoTrack: false,
      // maxAudioFramesDrift: 1,
      // forceKeyFrameOnDiscontinuity: true,
      // abrEwmaFastLive: 3.0,
      // abrEwmaSlowLive: 9.0,
      // abrEwmaFastVoD: 3.0,
      // abrEwmaSlowVoD: 9.0,
      // abrEwmaDefaultEstimate: 500000,
      // abrBandWidthFactor: 0.95,
      // abrBandWidthUpFactor: 0.7,
      // abrMaxWithRealBitrate: false,
      // maxStarvationDelay: 4,
      // maxLoadingDelay: 4,
      // minAutoBitrate: 0,
      // emeEnabled: false,
      // widevineLicenseUrl: undefined,
      // requestMediaKeySystemAccessFunc: requestMediaKeySystemAccess
    };

    this.hls = new Hls(config);

  }

  componentDidMount() {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element
    const { src, video } = this.props;
    // load hls video source base on hls.js
    // {autoStartLoad : false}
    if (Hls.isSupported()) {

      this.hls.loadLevel = 3;
      this.hls.startLoad(this.hls.startPosition = -1)
      // this.hls.startLoad()
      // console.log("hls.levels", this.hls)
      // console.log("hls.levels2", this.hls.currentLevel)
      this.hls.loadSource(src);
      this.hls.attachMedia(video);
      // video.play();
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { src, video } = this.props;
    if (prevProps.src !== src) {
      console.log("PREVIOUS PROPS", prevProps.src)
      console.log("CURRENT PROPS", src)
      // debugger;

      this.hls.loadSource(src);
      this.hls.attachMedia(video);

      this.hls.loadLevel = 2;
      this.hls.startLoad(this.hls.startPosition = -1)
      // this.hls.startLoad()

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  }

  componentWillUnmount() {
    // destroy hls video source
    if (this.hls) {
      this.hls.destroy();
    }
  }


  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || 'application/x-mpegURL'}
      />
    );
  }
}