// need to make sure users are old enough
const _calculateAge = (birthday) => { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

// validate the vitals page
const validateHealth = (values) => {
  let errors = {};
  let validations = {};
  const birthday = new Date(values.dateOfBirthYear, values.dateOfBirthMonth, values.dateOfBirthDay);
  const age = _calculateAge(birthday);

  console.log("errors", errors)

  // DOB Errors
  if (!values.dateOfBirthDay || !values.dateOfBirthMonth || !values.dateOfBirthYear) {
    errors.dateOfBirth = "Please fill in all fields";
  }
  else if (values.dateOfBirthDay <= 0 || values.dateOfBirthDay > 31) {
    errors.dateOfBirth = "Please enter a day between 1 and 31";
  }
  else if (values.dateOfBirthMonth <= 0 || values.dateOfBirthMonth > 12) {
    errors.dateOfBirth = "Please enter a month between 1 and 12";
  }
  else if (age < 16) {
    errors.dateOfBirth = "Wellthon is intended for persons 16 years of age and older.";
  }
  else if (values.dateOfBirthYear < 1900 || values.dateOfBirthYear > 2020) {
    errors.dateOfBirth = "Please enter a valid year.";
  }

  else if (values.dateOfBirthYear > 2018) {
    errors.dateOfBirth = "Wellthon is intended for persons 16 years of age and older.";
  }

  // Last Name Errors
  if (!values.gender) {
    errors.gender = "Gender is required";
  }

  // Email Error
  if (!values.currentWeight) {
    errors.currentWeight = "Current weight is required";
  } else if ((values.currentWeight).match(/[0-9]*(\.)[0-9]*/)) {
    errors.currentWeight = "Current weight must be a whole number";
  } else if (values.currentWeight <= 0) {
    errors.currentWeight = "Please enter a valid weight";
  }

  if (!values.heightFeet && !values.heightIn) {
    errors.height = "Height is required";
  } else if (values.heightFeet < 1 || values.heightFeet > 8) {
    errors.height = "Please enter a valid height"
  } else if (values.heightIn < 0 || values.heightIn > 12) {
    errors.height = "Please enter a valid height"
  }

  return { errors, validations };
}

export default validateHealth;