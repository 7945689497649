import React, { useEffect, useState } from 'react';
import MainHeader from '../../../components/Headers/MainHeader';
import styles from './Profile.module.scss';
import { connect } from 'react-redux';
import { fetchUser } from '../../../redux/ducks/user';
import { useAuth0 } from '../../../auth/Auth';
import ScrollToTopOnMount from '../../../ScrollRestoration';
import history from '../../../history';
import SubHeader from '../../../components/Headers/SubHeader';
import useFormValidation from "../../../hooks/useFormValidation";
import validateProfile from "../../../hooks/validateProfile";
import RadioButton from '../../../components/RadioButton/RadioButton';
import Button from '../../../components/Button/Button';
import classNames from 'classnames/bind';

import { postData } from '../../../api/api';
import { throwError } from '../../../error/Error';

// import PropTypes from 'prop-types';

const { container } = styles;

// payment- need to initialize stripe provider and inject stripe elements
const Profile = (props) => {

    const { user } = useAuth0();
    const { userReducer: { userData }, fetchUser } = props;

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Profile',
            'user': [user.sub, user.email]
        });
    }, [user])

    useEffect(() => {
        const fbq = window.fbq || function () { };
        fbq('track', 'Profile');
    },
        [])




    useEffect(() => {
        !userData && fetchUser(user.sub)
    }, [userData, fetchUser, user.sub]);


    const INITIAL_STATE = {
        firstName: ((userData || {}).Profile || {}).FirstName || "",
        lastName: ((userData || {}).Profile || {}).LastName || "",
        healthProgram: "",
    }

    const [error, setError] = useState("");

    const { input, form, errorText, label,
        errorInput
    } = styles;

    // submitting the form
    const submit = async () => {
        // debugger;

        // params that affect the profile
        const profileParams = {
            token: user.sub,
            firstName: values.firstName,
            lastName: values.lastName,
            healthProgram: values.healthProgram,
        };

        const postRequestBody = {};

        return await postData(postRequestBody, profileParams, "profile").then(
            response => {
                if (JSON.parse(response).Error) {
                    setError(JSON.parse(JSON.parse(response).Msg).message);
                    return "Error";
                }
                else {
		    fetchUser(user.sub);
                    history.push('/home');
                }
            }).catch(
                error =>
                    throwError(error)
            )
    }


    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, validateProfile, submit);

    const checked = (selectedOption, option) => {
        console.log(selectedOption, " ", option)
        return selectedOption === option
    }


    return (
        <>
            <form id="profile" className={form} onSubmit={handleSubmit}>
                {userData && <>
                    <ScrollToTopOnMount />
                    <div className={container}>
                        <MainHeader text="Welcome to Wellthon" name="welcomeHeader" styleName="orangeHeader" styleName2="mainHeaderPricingSmallerMargin2" />
                        <SubHeader text="Please introduce yourself." styleName="subHeaderProfileMargin" />
                        <div className={label}>My name is:</div>
                        {errors.firstName && <p className={styles.errorText}>*{errors.firstName}</p>}
                        <input
                            className={classNames(errors.firstName && errorInput, input)}
                            type="text"
                            name="firstName"
                            id="first-name"
                            placeholder="Enter your first name"
                            onChange={handleChange}
                            value={values.firstName}
                            onBlur={handleBlur}
                        />
                        {errors.lastName && <p className={styles.errorText}>*{errors.lastName}</p>}
                        <input
                            className={classNames(errors.lastName && errorInput, input)}
                            type="text"
                            name="lastName"
                            id="last-name"
                            placeholder="Enter your last name"
                            onChange={handleChange}
                            value={values.lastName}
                            onBlur={handleBlur}
                        />
                        <div className={label}>I’m part of a health program at:</div>

                        <RadioButton
                            choices={[
                                {
                                    "Choice": "Dedicated Senior Medical Center",
                                    "Value": "ChenMed",
                                    "Assessment": true
                                },
                                {
                                    "Choice": "OhioHealth / OhioHealthy",
                                    "Value": "OhioHealth",
                                    "Assessment": true
                                },
                                {
                                    "Choice": "None of the above",
                                    "Value": "None",
                                    "Assessment": true
                                }
                            ]}
                            name="healthProgram"
                            checked={checked}
                            onChange={handleChange}
                            selectedOption={values.healthProgram}
                            styleName="vertical"
                            styleName3="radioItemNoMargin"
                            styleNameError={errors.healthProgram && "errorInput"}
                            onBlur={handleBlur}
                        />
                        {error && <div className={errorText}>{error}</div>}
                        {Object.entries(error).length === 0 && Object.entries(errors).length === 0 && errors.constructor === Object
                            ?
                            <Button
                                text="Submit"
                                styleName="buttonSmaller"
                                styleName2="moreMargin"
                                buttonType="submit"
                                name="checkout"
                                disabled={isSubmitting}
                            /> :
                            <Button
                                text="Submit"
                                styleName="buttonSmaller"
                                styleName2="moreMargin"
                                name="checkout"
                                styleName3="Disabled" />
                        }
                    </div>
                </>}
            </form>
        </>
    )
}

const mapDispatchToProps = {
    fetchUser
}

const mapStateToProps = state => ({
    userReducer: state.userReducer
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
