import React from 'react';
import styles from './ThankYouModal.module.scss';
import Modal from 'react-modal';
import facebookIcon from '../../../assets/facebookIcon2.png';
import Confetti from 'react-confetti';
// import { useWindowSize } from 'react-use';

Modal.setAppElement('#root');

const ThankYouModal = (props) => {

    const { showModal } = props;
    const { main, headerText, headerText2, modal, overlay, facebook, cancel, textAfter, facebookContainer } = styles;
    // const { width, height } = useWindowSize()
    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <Confetti
                width={'500px'}
                height={'460px'}
            />
            <section className={main}>
                <div className={headerText}>We Appreciate You!</div>
                <div className={headerText2}>Thanks for the feedback. Can we share your thoughts about Wellthon via a review on Facebook</div>

                <a className={facebookContainer} href="https://www.facebook.com/wellthon/reviews/#_=_" target="_blank" rel="noopener noreferrer">
                    <img src={facebookIcon} alt="pricingImg" className={facebook} />
                    <div className={textAfter}>Share Review</div>
                </a>
                <div className={cancel} onClick={props.toggleModal}>No Thanks</div>
            </section>
        </Modal>
    )
}

export default ThankYouModal;
