import React from 'react';
import styles from './EditPasswordModal.module.scss';
import MainHeader from '../../../components/Headers/MainHeader';
import Modal from 'react-modal';
import PropTypes from 'prop-types';


Modal.setAppElement('#root');



const PasswordChangedModal = (props) => {

    const { text, content, modal, overlay, cancel } = styles;
    const { showModal, toggleModal } = props;


    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay} closeTimeoutMS={500}>
            <div className={content}>
                <MainHeader text="Change your password" />
                <div className={text}>
                    Please check your email inbox for a message with the subject <b>Reset your password</b> from <b>support@wellthon.com</b>, open it, and click the Confirm
                    button in the message. If you don’t see a message from Wellthon,
                    please check your Spam or Junk Mail folders.
            </div>
                <div className={cancel} onClick={toggleModal}>Close</div>
            </div>
        </Modal>
    )
}

PasswordChangedModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
}

export default PasswordChangedModal;

