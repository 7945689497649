// import for cross browser support
import 'whatwg-fetch';

// Types
const SET_TIME_SUCCESS = 'SET_TIME_SUCCESS';
const SET_WORKOUT_INFO_SUCCESS = 'SET_WORKOUT_INFO_SUCCESS';
const SET_WORKOUT_INFO_ERROR = 'SET_WORKOUT_INFO_ERROR';
const RESET_WORKOUTS = 'RESET_WORKOUTS';

// Reusable functions
const esc = encodeURIComponent;
export const queryFunc = (params) => Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');

// Action Creators
export const setTime = (max) => {
    return async dispatch => {
        dispatch(timeSuccess(max));
    };
};

export const fetchWorkouts = (token, max) => {
    return async dispatch => {
        var params = {
            token,
            min_duration: 0,
            max_duration: max
        };

        console.log("FETCHING", max)

        const query = queryFunc(params);

        const url = `${process.env.REACT_APP_API_URL}/workouts?${query}`;


        await fetch(url)
            .then(response => response.json()).then(res => {
                if (res.Error === true) {
                    dispatch(workoutInfoError(res.Msg))
                } else {
                    dispatch(workoutInfoSuccess(res))
                }
            }
            )

        // console.log((response.status))
        // if (response.status === 500) {
        //     dispatch(workoutInfoError(response.text()))
        // }
        // else if (!response.ok) {
        //     console.log('Could not fetch user workouts', response);
        //     dispatch(workoutInfoError(response))
        //     throw new Error('Could not fetch user workouts');
        // } else {
        //     response.json().then(data => {
        //         dispatch(workoutInfoSuccess(data))
        //     })
        // }
    };
};

export const resetWorkouts = () => ({
    type: RESET_WORKOUTS
});


const timeSuccess = (max) => ({
    type: SET_TIME_SUCCESS,
    max
});

const workoutInfoSuccess = (workoutInfo) => ({
    type: SET_WORKOUT_INFO_SUCCESS,
    workoutInfo
});

const workoutInfoError = (err) => ({
    type: SET_WORKOUT_INFO_ERROR,
    err
});


const initialState = {
    max: 30,
    timeError: null,
    workoutInfo: null,
    workoutInfoError: null
};

// Reducer
const workoutsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIME_SUCCESS:
            return {
                ...state,
                max: action.max,
            };
        case SET_WORKOUT_INFO_SUCCESS:
            return {
                ...state,
                workoutInfo: action.workoutInfo,
                errorWorkoutModal: false
            };
        case SET_WORKOUT_INFO_ERROR:
            return {
                ...state,
                workoutInfoError: action.err,
                errorWorkoutModal: true
            };
        case RESET_WORKOUTS:
            return {
                ...state,
                workoutInfoError: null,
                workoutInfo: null,
                errorWorkoutModal: false
            }
        default:
            return state;
    }
}

export default workoutsReducer;