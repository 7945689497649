import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import history from './history';

// scroll to top is used for navigating to different pages
// put the user on the top of the page
function ScrollToTopOnMount() {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTopOnMount);


// import { useEffect } from "react";

// const ScrollToTopOnMount = () => {
      
//     useEffect(() => {
//       window.scrollTo(0, 0)
//     }, [])
    
//       return null;
//   }

// export default ScrollToTopOnMount;