import React, { useEffect, useState } from 'react';
import history from '../../history';

import IconCross from '../../assets/IconCross.svg';
import IconScale from '../../assets/IconScale.svg';
import IconAssessment from '../../assets/IconAssessment.svg';
import EditVitalsModal from '../Account/EditVitalsModal/EditVitalsModal';
import { connect } from 'react-redux';
import { fetchUser } from '../../redux/ducks/user';
import { useAuth0 } from "../../auth/Auth";
import { setNotificationText, resetNotification } from '../../redux/ducks/notifications';
import IconMood from "../../assets/Mood.png";
import * as moment from 'moment';

import styles from './UpdateScreen.module.scss';

const UpdateScreen = props => {

    const { user } = useAuth0();

    const { container, bottomCardContainer, bottomCard,
        bottomCardText, bottomCardCard, basicLogo, top, headerText,
        notificationContainer, notifyText, redicon, redicon2

    } = styles;

    const [showVitalsModal, setShowVitalsModal] = useState();

    const { userReducer: { userData }, fetchUser } = props;
    const { notificationsReducer: { notification }, setNotificationText, resetNotification } = props;

    const today = moment();
    const isAtLeastADayAgo = (dateToCheck) => { return today.diff(dateToCheck, 'd') > 1 }

    useEffect(() => {
        !userData && fetchUser(user.sub)
    }, [userData, fetchUser, user.sub]);

    const toggleModalVitals = () => {
        setShowVitalsModal(!showVitalsModal);
    }

    useEffect(() => {
        if (notification !== "") {
            const timer = setTimeout(() => {
                resetNotification()
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [notification, resetNotification]);

    return (
        <>
            {notification !== "" && <div className={notificationContainer}>
                <div className={notifyText}>{notification}</div>
            </div>}
            <div className={container}>
                {userData && <EditVitalsModal toggleModal={toggleModalVitals} showVitalsModal={showVitalsModal} userData={userData} toggleModalVitals={toggleModalVitals} fetchUser={fetchUser} token={user.sub} setNotificationText={setNotificationText} />}
                <div className={top}>
                    <div className={headerText}>Choose Your Health Update</div>
                    <img src={IconCross} alt="assessment logo" className={basicLogo} />
                </div>

                <div className={bottomCardContainer}>
                    <div className={bottomCard} onClick={() => {
                        toggleModalVitals()
                    }}>
                        <div className={bottomCardCard}>
                            <img src={IconScale} alt="scale logo" className={basicLogo}
                            />
                        </div>
                        <div className={bottomCardText}>
                            Update Weight
                    </div>
                    </div>
                    <div className={bottomCard} onClick={() => {
                        history.push(`/health-assessment-update`)
                    }}>
                        <div className={bottomCardCard}>
                            {userData && userData.AssessmentInfo.AssessmentDue && !showVitalsModal &&
                                <div className={redicon} />}
                            <img src={IconAssessment} alt="assessment logo" className={basicLogo}
                            />
                        </div>
                        <div className={bottomCardText}>
                            Update Assessments
                    </div>
                    </div>
                    <div className={bottomCard}>
                        <div
                            className={bottomCardCard}
                            onClick={() => {
                                history.push(`/health-check-in`);
                            }}
                        >
                            {userData && (!userData.LastCheckIn || isAtLeastADayAgo(userData.LastCheckIn.date)) && !showVitalsModal &&
                                <div className={redicon2} />}
                            <img
                                src={IconMood}
                                alt="mood logo"
                                className={basicLogo}
                            />
                        </div>
                        <div className={bottomCardText}>
                            Health<br />Check-In
                    </div>
                    </div>
                </div>
            </div >
        </>
    )
}

const mapDispatchToProps = {
    fetchUser,
    setNotificationText,
    resetNotification
}

const mapStateToProps = state => ({
    userReducer: state.userReducer,
    notificationsReducer: state.notificationsReducer
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateScreen);
