const validateAuth = (values) => {
    let errors = {};
    let validations = {};
    // Email Errors
    if (!values.email) {
      errors.email = "Please enter your email address first";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Please enter a valid email address";
    }
    // Password Errors
    if (!values.password) {
      errors.password = "Please enter a password";
    // } else if (values.password.length < 8) {
    //   errors.password = "Password must be at least 8 characters";
    // } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/i.test(values.password)){
    //   errors.password = "Password must contain at least one uppercase and lowercase letter, and one number"
    }
    
    return {errors, validations};

  }
  

  export default validateAuth;