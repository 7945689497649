import React from "react";
import ClipLoader from 'react-spinners/ClipLoader';


const Spinner = (props) => {
    return (
        <ClipLoader
        sizeUnit={"px"}
        size={24}
        color={'#ffffff'}
        loading={props.loading}
      />
    )
};

export default Spinner;