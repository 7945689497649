import React, { useEffect, useState } from "react";
// import React, { useEffect, useState, lazy, Suspense } from "react";
import './App.scss';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./history";
import HomeScreen from "./scenes/HomeScreen/HomeScreen";
import UpdateScreen from "./scenes/UpdateScreen/UpdateScreen";
import SignUp from "./scenes/SignUp/SignUp";
import EditAccount from "./scenes/SignUp/EditAccount/EditAccount";
import Login from "./scenes/SignUp/Login/Login";
import ForgotPassword from "./scenes/SignUp/ForgotPassword/ForgotPassword";
import ResetPassword from "./scenes/SignUp/ResetPassword/ResetPassword";
import Home from "./scenes/Home/Home";
import PageNotFound from "./scenes/PageNotFound/PageNotFound";
import Account from "./scenes/Account/Account";
import Auth0Callback from "./scenes/Auth0Callback/Auth0Callback";
import Workout from "./scenes/Workout/Workout";
import PostWorkout from "./scenes/PostWorkout/PostWorkout";
import Feedback from "./scenes/Feedback/Feedback";
import HealthCheckIn from "./scenes/HealthCheckIn/HealthCheckIn";
import PreviewWorkoutScreen from "./scenes/PreviewWorkoutScreen/PreviewWorkoutScreen";
import ThankYou from "./scenes/ThankYou/ThankYou";
import BrowserNotSupported from "./scenes/BrowserNotSupported/BrowserNotSupported"
import { useAuth0 } from "./auth/Auth";

import LifeAssessment from "./scenes/LifeAssessment/LifeAssessment";
import Vitals from "./scenes/Vitals/Vitals";
import PrivateRoute from "./components/PrivateRoute";

import Profile from './scenes/SignUp/Profile/Profile';
import Terms from './scenes/SignUp/Terms/Terms';
import InputEmail from './scenes/SignUp/InputEmail/InputEmail';

import PrivateRouteAuth from "./components/PrivateRouteAuth";
import NavBarMain from "./components/NavBarMain/NavBarMain";
import NavBarBasic from "./components/NavBarBasic/NavBarBasic";
import NavBarBasicJustLogo from "./components/NavBarBasicJustLogo/NavBarBasicJustLogo";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// const Account = lazy(()=> import("./scenes/Account/Account"))
// const SignUp = lazy(()=> import("./scenes/SignUp/SignUp"))
// const Login = lazy(()=> import("./scenes/SignUp/Login/Login"))
// const ForgotPassword = lazy(()=> import("./scenes/SignUp/ForgotPassword/ForgotPassword"))
// const LifeAssessment = lazy(()=> import("./scenes/LifeAssessment/LifeAssessment"))
// const FallRisk = lazy(()=> import("./scenes/LifeAssessment/FallRisk"))




// const Workout = lazy(()=> import("./scenes/Workout/Workout"))

// private route for main part of application
const NavRoute = ({ exact, path, component: Component, user,
}) => {
  return (
    <PrivateRoute
      exact={exact}
      path={path}
      user={user}
      render={(props) => (
        <>
          <NavBarMain />
          <Component {...props} />
          <Footer />
        </>
      )} />
  )
}

// private route for main part of application
const NavRouteJustComponent = ({ exact, path, component: Component, user
}) => {
  return (
    <PrivateRoute
      exact={exact}
      path={path}
      user={user}
      render={(props) => (
        <>
          <Component {...props} />
        </>
      )} />
  )
}

// private route for signup since need basic navbar
const NavRouteSignUp = ({ exact, path, component: Component, styleName }) =>
(
  <PrivateRouteAuth
    exact={exact}
    path={path} render={(props) =>
    (
      <>
        <NavBarBasic />
        <Component {...props} />
      </>
    )} />
)

// basic route only showing logo top left login on right
const NavRouteBase = ({ exact, path, component: Component, styleName }) => (
  <>
    <NavBarBasic />
    <Component exact={exact} path={path} reset={true} />
  </>
)

// basic route only showing logo top left, nothing right side
const NavRouteBaseLogo = ({ exact, path, component: Component, styleName }) => (
  <>
    <NavBarBasicJustLogo />
    <Component exact={exact} path={path} reset={true} />
  </>
)

// basic route only showing logo top left, nothing right side
const NavRouteBaseNoHeader = ({ exact, path, component: Component, styleName }) => (
  <>
    <Component exact={exact} path={path} reset={true} />
  </>
)

// non protected route with plain navbar as well
const NavRouteWithBar = ({ exact, path, component: Component, styleName }) => (
  <>
    <NavBarBasicJustLogo />
    <Component exact={exact} path={path} reset={true} />
  </>
)

// route for 404 page, need to make not Exact
const NotFoundRoute = ({ path, component: Component, styleName }) => (
  <>
    <NavBarBasic />
    <Component path={path} />
    <Footer styleName={styleName} />
  </>
)

function App() {

  const { renewSession, authenticated, user } = useAuth0();
  const [loading, setLoading] = useState(true);

  // call as if componentDidMount to see if user is logged in
  // if so extend their session
  useEffect(() => {
    if (authenticated === "true" && loading === true) {
      renewSession().then(() => {
        setLoading(false)
      })
    }
    else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) return null;
  return (
    <div className="App">
      {/* <Suspense fallback={<h1>Loading...</h1>}> */}
      <Router history={history}>
        <ScrollToTop>
          {!isIE ?
            <Switch>
              <Route exact path="/" render={() => (
                authenticated ? (
                  <Route path="/" exact component={Home} />
                ) : (
                    <Route path='/' component={() => {
                      window.location.href = 'https://www.wellthon.com/';
                      return null;
                    }} />
                  )
              )} />
              {authenticated ? <NavRouteSignUp path="/signup" exact component={SignUp} /> : <NavRouteWithBar path="/signup" exact component={SignUp} />}
              {authenticated ? <NavRouteSignUp path="/login" exact component={Login} /> : <NavRouteBaseNoHeader path="/login" exact component={Login} styleName="noBorder" />}
              <NavRouteBase path="/forgot-password" exact component={ForgotPassword} styleName="noBorder" />
              <NavRouteBase path="/reset-password" exact component={ResetPassword} styleName="noBorder" />
              <Route path="/login/callback" render={(props) => {
                return <Auth0Callback {...props} />
              }} />
              <NavRoute exact component={HomeScreen} path="/home"
                user={user}
              />
              <NavRoute exact component={PreviewWorkoutScreen} path="/preview-workout"
                user={user}
              />
              <NavRoute exact component={Account} path="/account"
                user={user}
              />
              <NavRouteJustComponent exact component={Workout} path="/workout"
                user={user}
              />
              <NavRoute exact component={PostWorkout} path="/post-workout"
                user={user}
              />
              <NavRoute exact component={Feedback} path="/feedback"
                user={user}
              />
              <NavRoute exact component={ThankYou} path="/thank-you"
                user={user}
              />
              <NavRouteBase exact component={InputEmail} path="/input-email"
                user={user}
              />
              <NavRoute exact component={UpdateScreen} path="/update-health"
                user={user}
              />
              <NavRoute exact component={HealthCheckIn} path="/health-check-in"
                user={user}
              />
              <NavRouteSignUp exact component={Profile} path="/profile" />
              <NavRoute exact user={user} component={EditAccount} path="/change-login" />
              <NavRouteWithBar path="/agree-to-terms" exact component={Terms} styleName="noBorder" />
              {authenticated ? <NavRouteSignUp path="/pre-assessment" component={LifeAssessment} styleName="noBorder" /> : <NavRouteWithBar path="/pre-assessment" exact component={LifeAssessment} styleName="noBorder" />}
              {authenticated ? <NavRouteSignUp path="/vitals" component={Vitals} /> : <NavRouteWithBar path="/vitals" exact component={Vitals} />}
              {authenticated ? <NavRouteWithBar path="/health-assessment-update" exact component={LifeAssessment} styleName="noBorder" /> : <Redirect to="/login" />}

              <NotFoundRoute component={PageNotFound} />
            </Switch> :
            <NavRouteBaseLogo path="/browser-not-supported" component={BrowserNotSupported} />
          }
        </ScrollToTop>
      </Router>
      {/* </Suspense> */}
    </div>
  );
}

export default App;
