import React, {useEffect} from "react";
import { useAuth0 } from "../../auth/Auth";
import styles from "./Footer.module.scss";
import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser } from "../../redux/ducks/user";
import * as moment from "moment";

const Footer = (props) => {
  const { footerContainer, footerContent } = styles;

  const { user } = useAuth0();

  const {
    userReducer: { userData },
    fetchUser
  } = props;

  useEffect(() => {
    !userData && fetchUser(user.sub);
    // also need to call if reset redux
    // this comes from the health assessment
  }, [userData, fetchUser, user.sub]);

  const today = moment();
  const isAtLeastADayAgo = (dateToCheck) => {
    return today.diff(dateToCheck, "d") > 1;
  };


  return (
    <div className={classNames(footerContainer, styles[`${props.styleName}`])}>
      {userData && <div className={footerContent}>
        <NavLink
          className={styles.home}
          activeClassName={styles.homeActive}
          to="/home"
        >
          Home
        </NavLink>
        <NavLink
        className={(userData.AssessmentInfo.AssessmentDue ||
            !userData.LastCheckIn ||
            isAtLeastADayAgo(userData.LastCheckIn.date))?styles.updateHealthNotification:styles.updateHealth}
          activeClassName={styles.updateHealthActive}
          to="/update-health"
        >
          Health
        </NavLink>
      </div>}
    </div>
  );
};

const mapDispatchToProps = {
    fetchUser
  };
  
  const mapStateToProps = (state) => ({
    userReducer: state.userReducer
  });

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
