const validateAuth = (values) => {
    let errors = {};
    let validations = {};
    // Email Errors
    if (!values.email) {
      errors.email = "Please enter your email address first";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Please enter a valid email address";
    } else {
      validations.email = true
    }

    if(!values.emailConfirm) {
      errors.emailConfirm = "Please confirm your email address"
    } else if (values.emailConfirm !== values.email) {
      errors.emailConfirm = "Your emails don't match"
    } else {
      validations.emailConfirm = true
    }

    // Password Errors
    if (!values.password) {
      errors.password = "Please enter a password";
    } else if (values.password.length < 8) {
      errors.password = "Please enter a password that is at least 8 characters long";
    } else {
      validations.password = true
    }

    return {errors, validations};
  }
  

  export default validateAuth;