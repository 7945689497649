import React from "react";
import styles from './Rectangle.module.scss';
import classNames from 'classnames/bind';

import PropTypes from 'prop-types';

const Rectangle = (props) => {
    const { rectangle } = styles;
    return (
        <div className={classNames(styles[`${props.styleName}`], styles[`${props.styleName2}`], rectangle)}>
            {props.children}
        </div>
    )
};

Rectangle.propTypes = {
    styleName: PropTypes.string,
    styleName2: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
}

export default Rectangle;