import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from "../../auth/Auth";
// import { throwError } from '../../error/Error';
// import { fetchWorkouts } from '../../api/api';
import history
    from '../../history';
import PlayImg from '../../assets/Play.png';
import Rewind from '../../assets/Rewind.png';
import Stop from '../../assets/Stop.png';
import SkipForward from '../../assets/SkipForward.png';
import { Player, ControlBar, CurrentTimeDisplay, TimeDivider, FullscreenToggle, BigPlayButton } from 'video-react';
import HLSSource from '../../components/HLSSource/HLSSource';
import ErrorWorkoutModal from './VideoModals/ErrorWorkoutModal';
import RestartModal from './VideoModals/RestartModal';
import SkipModal from './VideoModals/SkipModal';
import QuitModal from './VideoModals/QuitModal';
// import Spinner from '../../components/Spinner/Spinner';
import "video-react/dist/video-react.css";
import { connect } from 'react-redux';
import { fetchUser } from '../../redux/ducks/user';
import { fetchWorkouts, resetWorkouts } from '../../redux/ducks/workouts';
import styles from './Workout.module.scss';

// video component
const Workout = props => {

    const ref = useRef(null);
    const [videoState, setVideoState] = useState(null);
    const [hasEnded, setHasEnded] = useState(false);

    const { user } = useAuth0();

    const [videoSrc, setVideoSrc] = useState(null);
    const [videoPlaying, setVideoPlaying] = useState(0);

    const { workoutsReducer: { max, workoutInfo, workoutInfoError, errorWorkoutModal }, fetchUser, fetchWorkouts, resetWorkouts } = props;

    // Copying the player's state to a local component state on every change
    useEffect(() => {
        ref.current.subscribeToStateChange(setVideoState);
    }, [setVideoState]);

    useEffect(() => {
        localStorage.removeItem("videos")
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'WorkoutScreen',
        });
    }, [])

    // Checking if the video has ended on every state update
    useEffect(() => {
        console.log("USE EFFECT")
        console.log("VIDEO STATE", videoState)
        console.log("WORKOUT INFOs", workoutInfo)
        if (videoState && videoState.ended && !hasEnded && !errorWorkoutModal) {
            console.log("inside hook")
            console.log("VP", videoPlaying, workoutInfo.Exercises.length)
            // Preventing onVideoEnd from being called multiple times
            setHasEnded(true);
            console.log("Finished!")

            let newVideo =
            {
              "id": workoutInfo.Exercises[videoPlaying].ID,
              "elapsed_time": Math.round(videoState.currentTime),
            }

            const videoObj = JSON.parse(localStorage.getItem('videos'));
            if (videoObj === null) {
                const arr = [];
                arr.push(newVideo);
                localStorage.setItem("videos", JSON.stringify(arr))
            } else {
                videoObj.push(newVideo)
                localStorage.setItem("videos", JSON.stringify(videoObj))
            }

            if (videoPlaying < (workoutInfo.Exercises.length) - 1) {
                console.log("set next video")
                setVideoPlaying(videoPlaying + 1)
                setVideoSrc(workoutInfo.Exercises[videoPlaying + 1].Url)
                console.log("video playing", workoutInfo.Exercises[videoPlaying + 1].Url)
                ref.current.load();
                // setHasEnded(false)
                setTimeout(function () { setHasEnded(false) }, 100);
            } else {
                history.push('/post-workout');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [videoState, hasEnded, setHasEnded, workoutInfo, videoPlaying, errorWorkoutModal]);

    // // need to fetch workouts for URLs for videos
    // const userWorkouts = useCallback(() => {
    //     fetchWorkouts(user.sub, max)
    //         .then(data => {
    //             if (!data.Id && JSON.parse(data).Error === true) {
    //                 setWorkoutFetchError(JSON.parse(data).Msg)
    //                 setErrorWorkoutModal(true)
    //             }

    //             setUserWorkoutsData(data)
    //             console.log("inside user workouts")
    //             setVideoSrc(data.Exercises[videoPlaying].Url)

    //         }
    //         )
    //         .catch((err) => {
    //             console.log("ERR here", JSON.stringify(err))
    //             throwError(err);
    //         });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [user.sub]);

    // useEffect(() => {
    //     userWorkouts();
    // }, [userWorkouts]);

    // need to fetch workouts for URLs for videos
    useEffect(() => {
        fetchWorkouts(user.sub, max)
    }, [user.sub, max, fetchWorkouts]);

    useEffect(() => {
        if (workoutInfo !== null && videoPlaying < (workoutInfo.Exercises.length) - 1) {
            setVideoSrc(workoutInfo.Exercises[videoPlaying].Url)
        }
    }, [workoutInfo, videoPlaying]);

    // useEffect(() => {
    //     userWorkouts();
    // }, [userWorkouts]);

    console.log("ref", ref.current)
    console.log("workoutInfo", workoutInfo)

    const play = () => {

        // var isPlaying = videoState.currentTime > 0 && !videoState.paused && !videoState.ended
        // && videoState.readyState > 2;

        // if (!isPlaying) {
        ref.current.play();
        // }


    }

    const [restartWorkoutModal, setRestartWorkoutModal] = useState(false);
    const [quitWorkoutModal, setQuitWorkoutModal] = useState(false);
    const [skipVideoModal, setSkipVideoModal] = useState(false);

    const restartVideo = () => {
        setRestartWorkoutModal(!restartWorkoutModal);
    }

    const quitVideo = () => {
        setQuitWorkoutModal(!quitWorkoutModal);
    }

    const restartSegment = () => {
        ref.current.seek(0);
        ref.current.play();
    }

    const restartVideos = () => {
        setVideoPlaying(0);
        setVideoSrc(workoutInfo.Exercises[0].Url)
        ref.current.seek(0);
        ref.current.play();
    }

    const skipVideo = () => {
        setSkipVideoModal(!skipVideoModal)
    }

    const nextSegment = () => {

        if (workoutInfo.Exercises[videoPlaying].Title !== "Cool-Down") {
            // debugger;

                setHasEnded(true);
                setVideoPlaying(videoPlaying + 1)
                setVideoSrc(workoutInfo.Exercises[videoPlaying + 1].Url)
                console.log("video playing", workoutInfo.Exercises[videoPlaying + 1].Url)
                ref.current.load();
                // setHasEnded(false)
                setTimeout(function () { setHasEnded(false) }, 100);
            // debugger;
        } else {
            history.push('/post-workout');
        }
    }

    const { VideoStyle, Overlay, PlaybarButton, PlaybarButtonBigger, Controls, EachButton, PlaybarButtonText, TopControls } = styles;

    videoState && videoState.ended && console.log("VS Stopped", videoState)
    console.log("VS", videoState)
    console.log("props", props)
    console.log("videoPlaying", videoPlaying)

    return (
        <>
            <ErrorWorkoutModal showModal={errorWorkoutModal} workoutFetchError={workoutInfoError} />
            {workoutInfo && <RestartModal showModal={restartWorkoutModal} toggleModal={restartVideo} restartSegment={restartSegment} restartVideos={restartVideos} play={play} type={workoutInfo.Exercises[videoPlaying].Type} />}
            {workoutInfo && <SkipModal showModal={skipVideoModal} toggleModal={skipVideo} nextSegment={nextSegment} play={play} title={workoutInfo.Exercises[videoPlaying].Title} videoState={videoState} id={workoutInfo.Exercises[videoPlaying].ID}/>}
            {workoutInfo && <QuitModal showModal={quitWorkoutModal} toggleModal={quitVideo} play={play} fetchUser={fetchUser} user={user} resetWorkouts={resetWorkouts} videoState={videoState} id={workoutInfo.Exercises[videoPlaying].ID}/>}
            {videoState && videoState.paused && <div className={Overlay} />}
            <Player
                fluid={false}
                ref={ref}
                className={VideoStyle}
                width="100%" height="100%"
                autoPlay={true}
                // position="absolute" 
                playsInline={false}>
                {workoutInfo !== null && videoSrc !== null && !hasEnded &&
                    <HLSSource
                        isVideoChild
                        src={videoSrc}
                    />}
                <ControlBar>
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <FullscreenToggle disabled />
                </ControlBar>
                <BigPlayButton disabled />
            </Player>
            {/* {videoState && videoState.paused && <div className={Controls}> */}
            {videoState && videoState.paused && ((!videoState.ended && videoState.hasStarted && videoState.currentTime !== 0) || videoPlaying === 0) && <div>
                {workoutInfo && <div className={TopControls}>
                    {workoutInfo.Exercises[videoPlaying].Title}
                </div>}
                <div className={Controls}>
                    <div className={EachButton}>
                        <img src={Rewind} alt="Rewind" onClick={restartVideo} className={PlaybarButton} />
                        <div className={PlaybarButtonText}>Start Over</div>
                    </div>
                    <div className={EachButton}>
                        <img src={PlayImg} alt="Play" onClick={play} className={PlaybarButtonBigger} />
                        <div className={PlaybarButtonText}>Resume</div>
                    </div>
                    <div className={EachButton}>
                        <img src={Stop} alt="Stop" onClick={quitVideo} className={PlaybarButton} />
                        <div className={PlaybarButtonText}>Quit</div>
                    </div>
                    {workoutInfo && <div className={EachButton}>
                        <img src={SkipForward} alt="Skip" onClick={skipVideo} className={PlaybarButton} />
                        <div className={PlaybarButtonText}>Skip</div>
                    </div>}
                </div>
            </div>}
            {/* {videoState && !videoState.paused && videoState.ended && <Spinner loading={true} />} */}
        </>
    )

}

const mapDispatchToProps = {
    fetchUser,
    fetchWorkouts,
    resetWorkouts
}


const mapStateToProps = state => ({
    workoutsReducer: state.workoutsReducer
})

export default connect(mapStateToProps, mapDispatchToProps)(Workout);

