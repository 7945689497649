import React from "react";
import { withRouter } from 'react-router-dom';

import styles from './NavBarBasicJustLogo.module.scss';
import WellthonLogoSVG from '../../assets/WellthonLogo.svg';

const NavBarBasicJustLogo = withRouter(() => {

    return (
        <div className={styles.border}>
            <div className={styles.container}>
                <div className={styles.leftContainer} >
                    <img src={WellthonLogoSVG} alt="wellthon logo" className={styles.basicLogo} />
                </div>
            </div>
        </div>
    );
});

export default NavBarBasicJustLogo;