import React from "react";
import styles from './RadioButton.module.scss';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const RadioButton = (props) => {
    const { choices, name, checked, onChange } = props;
    const { radioButton, container, radioItem, radioLabel, divider, selectionText, orangeText } = styles
    console.log("PROPS", props)
    console.log(choices.length)
    return (
        <div className={classNames(styles[`${props.styleName}`], styles[`${props.styleName2}`], container)}>
            {choices.map((option, index) => {

                let oc;
                if (option.CF === true) {
                    oc = option.Period
                } else if (option.Assessment) {
                    oc = option.Value
                }
                else {
                    oc = option.Choice
                }

                return (
                    <div key={option.Value} className={classNames(styles[`${props.styleName3}`], radioItem)}>
                        {name === "monthSelection" && <div className={selectionText}>{option.Period.charAt(0).toUpperCase() + option.Period.slice(1)} Membership</div>}
                        <div className={radioItem} key={option.Choice}>
                            <input type="radio" id={`${name}${oc}`} data-points={option.Value}
                                name={name} value={oc} onChange={onChange} checked={checked(props.selectedOption, oc)} className={classNames(styles[`${props.styleNameError}`], radioButton)} />
                            <label htmlFor={`${name}${oc}`} className={classNames(styles[`${props.styleNameError}`], styles[`${props.labelStyle}`], radioLabel)}>
                                <div className={styles.labelText}>
                                    {!option.Choice2 ?
                                        option.Choice.split("\n").map((i, key) => {
                                            return <div key={key}>{ReactHtmlParser(i)}</div>;
                                        }) :
                                        <>
                                            <div className={option.ExtraStr !== "" ? orangeText : undefined}>{option.Choice2}</div>
                                            {option.ExtraStr !== "" && <div className={orangeText}>{option.ExtraStr}</div>}
                                        </>
                                    }
                                </div>
                            </label>
                        </div>
                        {name === "monthSelection" && index !== choices.length - 1 && <div className={divider} />}
                    </div>
                )
            }
            )
            }
        </div>
    )
};

RadioButton.propTypes = {
    checked: PropTypes.func,
    choices: PropTypes.array,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    // selectedOption: PropTypes.oneOfType([
    //     PropTypes.string,
    //     PropTypes.bool
    // ]),
    styleName: PropTypes.string,
    styleName2: PropTypes.string,
}

export default RadioButton;