import React, { useState } from 'react';
import styles from './ForgotPassword.module.scss';
import { useAuth0 } from '../../../auth/Auth';
import Button from '../../../components/Button/Button';
import useFormValidation from "../../../hooks/useFormValidation";
import validatePasswordReset from "../../../hooks/validatePasswordReset";
import classNames from 'classnames/bind';
import MainHeader from '../../../components/Headers/MainHeader';
import { Link } from 'react-router-dom';

const INITIAL_STATE = {
    email: ""
};

// forgot password screen- reset your password
const ForgotPassword = () => {

    const { resetPassword } = useAuth0();
    const [sentPassword, setSentPassword] = useState(false);

    const authenticateUser = async () => {
        const { email } = values
        await resetPassword(email.toLowerCase());
        setSentPassword(true);
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
    } = useFormValidation(INITIAL_STATE, validatePasswordReset, authenticateUser);

    const { main, text, background, content, input, bottomButtons,
        headerContainer, errorInput, errorText, blueText, cancel } = styles;

    return (
        <div className={background}>
            <section className={main}>
                {sentPassword ?
                    <>
                        <div className={blueText}>We've sent an email to
                <br />
                            {values.email}
                            <br />
                            Please click the link in the email to reset your password.
                    </div>
                        <div className={text}>If you do not receive an email within 10 minutes please check your spam folder.</div>
                        <Link className={cancel}
                            to='/login'
                        >x Close Window</Link>
                    </>
                    :
                    <form onSubmit={handleSubmit}>
                        <div className={headerContainer}>
                            <MainHeader text="Reset Your Password" styleName="mainHeaderAltModal" />
                        </div>
                        <div className={text}>Please enter the email address that you use to sign
                                into Wellthon. We will send you an email to reset your
                            password.</div>
                        <div className={content}>
                        {errors.email && <p className={errorText}>*{errors.email}</p>}
                            <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="email"
                                value={values.email}
                                className={classNames(errors.email && errorInput, input)}
                                autoComplete="off"
                                id="login-email"
                                placeholder="Enter your email address here"
                            />
                            <div className={bottomButtons}>
                                <Button
                                    buttonType="submit"
                                    onBlur={handleBlur}
                                    text="Reset My Password"
                                    styleName="buttonSmaller"
                                />
                            </div>
                        </div>
                    </form>
                }
            </section>
        </div>
    )
}

export default ForgotPassword;