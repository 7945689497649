import React, { useState, useEffect, useCallback } from "react";
import { throwError } from "../../error/Error";
import { useAuth0 } from "../../auth/Auth";

import AnswerQuestions from "./AnswerQuestions";
import styles from "./LifeAssessment.module.scss";
import ScrollToTopOnMount from "../../ScrollRestoration";
import history from "../../history";
import {
  postData,
  fetchAssessmentQuestion,
  fetchAssessmentQuestionWithToken,
} from "../../api/api";
import { connect } from "react-redux";
import { fetchUser } from "../../redux/ducks/user";
import { setNotificationText } from "../../redux/ducks/notifications";
import Button from "../../components/Button/Button";
import MainHeader from "../../components/Headers/MainHeader";
import parse from "html-react-parser";

import _ from "lodash";

// see if object is empty
// {}
const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const LifeAssessment = (props) => {
  const { user } = useAuth0();

  const [data, setData] = useState(null);
  const [transition, setTransition] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [values, setValues] = useState({});
  const [key, setKey] = useState([]);
  const [assessmentError, setAssessmentError] = useState(null);
  const [assessmentFollowUp, setAssessmentFollowUp] = useState(null);
  const [assessmentFollowUpState, setAssessmentFollowUpState] = useState(false);
  const [finishedAssessment, setFinishedAssessment] = useState(false);

  const handler = (e) => {
    e.preventDefault();
    e.returnValue =
      "Leaving this page will cause your assessment responses to be lost.";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  const handleChangeCheckedNone = (event) => {
    if (event.target.name === "none") {
      setValues({ none: event.target.checked });
      event.target.checked ? setSubmitting(true) : setSubmitting(false);
      setKey([]);
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.checked,
        none: false,
      });
      const valuesNotCurrent = _.omit(values, event.target.name);
      // can't include current since state isn't updated yet
      event.target.checked
        ? setSubmitting(true)
        : isEmpty(_.pickBy(valuesNotCurrent)) && setSubmitting(false);
      event.target.checked
        ? setKey([...key, event.target.name])
        : setKey(key.filter((item) => item !== parseInt(event.target.name)));
    }
  };

  const fetchData = useCallback(() => {
    if (user) {
      fetchAssessmentQuestionWithToken(user.sub)
        .then((data) => {
          setSubmitting(true);
          setData(data.questions);
          if (data.previous_answers[0].choices.length === 0) {
            setValues({ none: true });
          } else {
            const trueChoices = {};
            const keyValues = [];

            if (
              data.questions[0].question_type === "choose_one" &&
              data.previous_answers !== null
            ) {
              setValues({
                choseOneAnswer:
                  data.previous_answers[0].choices[0].choice_id,
              });
              setKey([data.previous_answers[0].choices[0].choice_id]);
            }
            else if (
              data.questions[0].question_type === "range30" &&
              data.previous_answers !== null
            ) {
              setValues({
                range30: data.previous_answers[0].numeric_answer
              })
            }
            else {
              data.previous_answers[0].choices.forEach((item) => {
                keyValues.push(item.choice_id);
                trueChoices[item.choice_id] = true;
              });
              setValues({ ...trueChoices });
              setKey(keyValues);
            }
          }
        })
        .catch((error) => console.log("error fetching", error));
    } else {
      fetchAssessmentQuestion()
        .then((data) => {
          localStorage.setItem("tempToken", data.user_token);
          setData(data.questions);
          console.log("DATA", data);
        })
        .catch((error) => console.log("error fetching", error));
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const submitAnswer = () => {
    const params = {};
    let tempTok;
    if (((user || {}).sub || {}).length > 0) {
      tempTok = user.sub;
    } else {
      tempTok = localStorage.getItem("tempToken");
    }
    if (
      data[0].question_type === "range30" &&
      (values.range30 === "" || values.range30 === undefined || values.range30 === null || values.range30 < 0 || values.range30 > 30)
    ) {
      setAssessmentError("Please select a number between 0 and 30");
    } else {
      const postRequestBody = {
        user_token: tempTok,
        answers: [
          {
            question_id: data[0].question_id,
            choices: key.map((item) => {
              if (
                values[`clarifyingAnswer${item}`] !== null &&
                values[`clarifyingAnswer${item}`] !== undefined
              ) {
                return {
                  choice_id: parseInt(item),
                  clarifying_answer_id: parseInt(
                    values[`clarifyingAnswer${item}`]
                  ),
                };
              } else {
                if (values.choseOneAnswer !== undefined) {
                  return { choice_id: parseInt(values.choseOneAnswer) };
                } else {
                  return {
                    choice_id: parseInt(item),
                  };
                }
              }
            }),
          },
        ],
      };

      if (parseInt(values.range30) !== undefined) {
        postRequestBody.answers[0].numeric_answer = parseInt(values.range30)
      }

      let isEmpty = true;
      if (data[0].clarifying_answers !== undefined) {
        isEmpty =
          key.filter((item) => !values[`clarifyingAnswer${item}`]).length === 0;
      }

      if (isEmpty || (key.length === 0 && values["none"] === false)) {
        setTransition(false);
        postData(postRequestBody, params, "v2-assessments")
          .then((response) => {
            if (JSON.parse(response).Error) {
              // need to add in piece that checks where at in assessment
              // if returns an empty body back then turn off/ switch to next page
              console.log(JSON.parse(JSON.parse(response).Msg).message);
              throwError(
                "here is error from post response",
                JSON.parse(JSON.parse(response).Msg).message
              );
            } else {
              // need to store response in data
              // but first have to make sure not empty otherwise go to vitals
              if (JSON.parse(response).questions.length !== 0) {
                setTimeout(() => {
                  if (
                    window.location.pathname === "/health-assessment-update"
                  ) {
                    // assessent update
                    setSubmitting(false);
                    setData(JSON.parse(response).questions);
                    setAssessmentFollowUp(JSON.parse(response).follow_up);
                    setValues({ none: false, range30: "" });
                    const res = JSON.parse(response);
                    setAssessmentFollowUpState(true);
                    // setFinishedAssessment(false)
                    // when it's choose one and when it's previous answers is not nothing
                    if (
                      res.questions[0].question_type === "choose_one" &&
                      res.previous_answers !== null
                    ) {
                      setValues({
                        choseOneAnswer:
                          res.previous_answers[0].choices[0].choice_id,
                      });
                      setKey([res.previous_answers[0].choices[0].choice_id]);
                      setSubmitting(true);
                    } else if (
                      JSON.parse(response).questions[0].question_type === "range30"
                    ) {
                      const rangePrev = ((JSON.parse(response).previous_answers || {})[0] || {}).numeric_answer
                      if (rangePrev !== undefined) {
                        setValues({ range30: JSON.parse(response).previous_answers[0].numeric_answer });
                        setSubmitting(true);
                      }
                    }
                    else if (
                      (((res.previous_answers || {})[0] || {}).choices || {})
                        .length === 0
                    ) {
                      setValues({ none: true });
                      setSubmitting(true);
                      setKey([]);
                    } else if (res.previous_answers !== null) {
                      const trueChoices = {};
                      const keyValues = [];
                      res.previous_answers[0].choices.forEach((item) => {
                        keyValues.push(item.choice_id);
                        trueChoices[`clarifyingAnswer${item.choice_id}`] =
                          item.clarifying_answer_id;
                        trueChoices[item.choice_id] = true;
                      });
                      setValues({ ...trueChoices });
                      setKey(keyValues);
                      setSubmitting(true);
                    }
                  } else {
                    // pre-assessment then use this
                    debugger;
                    console.log("DATA 2", JSON.parse(response));
                    setAssessmentFollowUp(JSON.parse(response).follow_up);
                    setData(JSON.parse(response).questions);
                    setValues({ none: false, range30: "" });
                    setKey([]);
                    setSubmitting(false);
                    setAssessmentFollowUpState(true);
                  }

                  // need for regular and assessment update
                  setAssessmentError(null);
                  setTransition(true);
                  window.scrollTo(0, 0);
                }, 500);
              } else {
                if (
                  JSON.parse(response).follow_up === {} ||
                  JSON.parse(response).follow_up === undefined ||
                  JSON.parse(response).follow_up === null
                ) {
                  setFinishedAssessment(true);
                  setAssessmentFollowUpState(true);
                } else {
                  setAssessmentFollowUp(JSON.parse(response).follow_up);
                  setAssessmentFollowUpState(true);
                  setFinishedAssessment(true);
                }
              }
            }
          })
          .catch((error) => throwError(error));
      } else {
        setAssessmentError("Please choose an option above");
      }
    }
  };

  const continueAssessment = () => {
    if (!finishedAssessment || !assessmentFollowUp) {
      setAssessmentFollowUpState(false);
      setAssessmentFollowUp(null);
    } else {
      // setAssessmentFollowUpState(false)
      if (window.location.pathname === "/health-assessment-update") {
        props.fetchUser(user.sub);
        if ((history.location.state || {}).screen === "Account") {
          history.push("/account");
          props.setNotificationText("Your Assessment Has Been Updated");
        } else {
          history.push("/home");
          props.setNotificationText("Your Assessment Has Been Updated");
        }
      } else {
        history.push("vitals");
      }
    }
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "HealthAssessment",
    });
  }, []);

  useEffect(() => {
    const fbq = window.fbq || function () { };
    fbq("trackCustom", "HealthAssessment", {
      content_name: "HealthAssessment",
    });
  }, []);

  //  FIGURE OUT ANIMATION
  // // on select radio button change the result set and go to the next question
  // const handleOptionChange = (e) => {
  //     const { name, value, dataset } = e.target;
  //     setTransition(false);
  //     // setResponses(
  //     //     prevState => {
  //     //         return {
  //     //             ...prevState,
  //     //             [name]: { "response": value, "Score": parseInt(dataset.points), "Id": parseInt(name) }       // update the value of specific key
  //     //         }
  //     //     })
  //     setSingleRadioClick(value);
  //     setTimeout(() => {
  //         setQuestionNumber(
  //             questionNumber + 1
  //         )
  //         setSingleRadioClick("");
  //         setTransition(true);
  //     }, 500);
  // };

  // handle change is run every time an input changes
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setSubmitting(true);
  };

  // handle change is run every time an input changes
  const handleChangeRadio = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setSubmitting(true);
    setKey([event.target.name]);
  };

  const renderAssessment = () => {
    if (data !== null && data !== undefined) {
      return (
        <AnswerQuestions
          data={data}
          transition={transition}
          values={values}
          key={key}
          handleChangeChecked={handleChangeCheckedNone}
          submitAnswer={submitAnswer}
          handleChange={handleChange}
          handleChangeRadio={handleChangeRadio}
          submitting={submitting}
          assessmentError={assessmentError}
        />
      );
    }
  };

  const renderFollowUp = () => {
    if (data !== null && data !== undefined) {
      if (
        (assessmentFollowUp || {}).header === "" ||
        assessmentFollowUp === null ||
        assessmentFollowUp === undefined
      ) {
        continueAssessment();
        return null;
      } else {
        console.log(assessmentFollowUp);
        return (
          <div>
            <MainHeader
              text={assessmentFollowUp.header}
              styleName="orangeHeader"
              styleName2="smallMarginBottomWidth"
            />
            <div className={styles.paddingLeft}>
              <div className={styles.fadeInContainer}>
                <img
                  src={assessmentFollowUp.images[0].url}
                  className={styles.followUpImage}
                  alt="followUpImage"
                />
              </div>
              <div className={styles.followUpText}>
                {parse(assessmentFollowUp.text)}
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  name="followUp"
                  text="Continue"
                  onClick={() => continueAssessment()}
                />
              </div>
            </div>
          </div>
        );
      }
    }
  };

  console.log("dflkadskhjf;addfslk;d", assessmentFollowUpState);

  return (
    <>
      <ScrollToTopOnMount />
      <div className={styles.container}>
        <div className={styles.main}>
          {assessmentFollowUpState ? renderFollowUp() : renderAssessment()}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchUser,
  setNotificationText,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LifeAssessment);
