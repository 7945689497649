
import React, { useEffect } from 'react';
import { useAuth0 } from "../../auth/Auth";
import { postData } from '../../api/api';
import { throwError } from '../../error/Error';
import MainHeader from '../../components/Headers/MainHeader';
import IconThumbsDown from '../../assets/IconThumbsDown.png';
import IconThumbsUp from '../../assets/IconThumbsUp.png';
import { fetchUser } from '../../redux/ducks/user';
import { fetchWorkouts } from '../../redux/ducks/workouts';
// import { setNotificationText } from '../../redux/ducks/notifications';
import { connect } from 'react-redux';

import history from "../../history";


import styles from './PostWorkout.module.scss';

const PostWorkout = props => {

    const { user } = useAuth0();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'PostWorkoutScreen',
        });
    }, [])

    const { workoutsReducer: { max, workoutInfo }, fetchWorkouts
        // , setNotificationText 
    } = props;

    useEffect(() => {
        if (workoutInfo === null || workoutInfo === undefined) {
            fetchWorkouts(user.sub, max)
        }
    }, [fetchWorkouts, workoutInfo, max, user.sub]);

    const submitSelection = async (thumbs, route) => {

        const params = {
            token: user.sub,
            "thumbs": thumbs,
            "id": workoutInfo.Id,
            "platform": "web"
        };

        let postRequestBody = {};
        const videoObj = JSON.parse(localStorage.getItem('videos'));
        if (videoObj !== null){
            postRequestBody = {
                "videos": videoObj
            }
        }

        // setTimeout(() => {
        postData(postRequestBody, params, "workouts")
            .then(() => props.fetchUser(user.sub))
            .catch(error => throwError(error))
            .then(() => {
                if (thumbs === "up") {

                    if (localStorage.getItem('thumbsUp') !== null) {
                        // const appURL = `${process.env.REACT_APP_API_URL.slice(0, -4)}/user_metadata`
                        // const tempToken = user[appURL] !== undefined ? user[appURL]["tempToken"] : localStorage.getItem('tempToken')
                        const thumbs = parseInt(JSON.parse(localStorage.getItem('thumbsUp')));
                        localStorage.setItem('thumbsUp', thumbs + 1)
                    } else {
                        localStorage.setItem('thumbsUp', 1)
                    }
                } else {
                    localStorage.setItem('thumbsUp', 0)
                }
                history.push(`/${route}`)
            })
    };

    const {
        container, radioButtonsContainer, thumbUp, thumbDown, postWorkoutText
    } = styles;

    return (
        <div className={container}>
            <MainHeader text="Rate Your Workout" />
            <div className={postWorkoutText}>Did this workout meet your exercise needs and expectations?</div>
            <div className={radioButtonsContainer}>
                <img onClick={() => submitSelection('up', 'thank-you')} src={IconThumbsUp} alt="thumbs up" className={thumbUp} />
                <img onClick={() => submitSelection('down', 'feedback')} src={IconThumbsDown} alt="thumbs down" className={thumbDown} />
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    fetchUser,
    fetchWorkouts,
    // setNotificationText
}

const mapStateToProps = state => ({
    workoutsReducer: state.workoutsReducer,
    userReducer: state.userReducer
})


export default connect(mapStateToProps, mapDispatchToProps)(PostWorkout);
