import React, { Component } from 'react';
import styles from './BrowserNotSupported.module.scss';

class BrowserNotSupported extends Component {
    render() {
        const { container, header, link } = styles;
        return (
            <div className={container}>
                <div className={header}>Microsoft Internet Explorer is no longer a supported browser.<br />For best performance, Wellthon recommends </div>
                <a className={link} rel="noopener noreferrer" target="_blank" href="https://support.google.com/chrome/answer/95346" >Google Chrome</a>
            </div>
        )
    }
}

export default BrowserNotSupported;