import React, { useEffect, useState } from "react";
import history from "../../history";

import { connect } from "react-redux";
import { fetchUser } from "../../redux/ducks/user";
import { postData } from "../../api/api";
import { useAuth0 } from "../../auth/Auth";
import { throwError } from "../../error/Error";
import {
  setNotificationText,
  resetNotification,
} from "../../redux/ducks/notifications";

import styles from "./HealthCheckIn.module.scss";

import MainHeader from "../../components/Headers/MainHeader";
import SubHeader from "../../components/Headers/SubHeader";
import RadioButton from "../../components/RadioButton/RadioButton";
import Rectangle from "../../components/Rectangle/Rectangle";
import Button from "../../components/Button/Button";

const HealthCheckIn = (props) => {
  const { user } = useAuth0();

  const {
    checkInContainer,
    questionText,
    extraHeader,
    marginSides,
    container,
    cancel,
    bottomButtons,
  } = styles;

  const {
    userReducer: { userData },
    fetchUser,
  } = props;

  const {
    notificationsReducer: { notification },
    setNotificationText,
    resetNotification,
  } = props;

  useEffect(() => {
    !userData && fetchUser(user.sub);
  }, [userData, fetchUser, user.sub]);

  const [physical, setPhysical] = useState("");
  const [mental, setMental] = useState("");

  const handleChangePhysical = (event) => {
    setPhysical(event.target.value);
  };

  const handleChangeMental = (event) => {
    setMental(event.target.value);
  };

  const checked = (selectedOption, option) => {
    console.log("SELECTED: ", selectedOption, " OPTION: ", option);
    return parseInt(selectedOption) === option;
  };

  useEffect(() => {
    if (notification !== "") {
      const timer = setTimeout(() => {
        resetNotification();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification, resetNotification]);

  const submit = async () => {
    const params = {
      token: user.sub,
      physical: parseInt(physical),
      mental: parseInt(mental)
    };

    const postRequestBody = {

    };

    if (mental && physical) {
      postData(postRequestBody, params, "checkins")
        .then(() => {
          fetchUser()
          setNotificationText("Check-In Success!")
          history.push("/home")
        })
        .catch((error) => throwError(error));
    }
  };

  return (
    <>
      <div className={checkInContainer}>
        <MainHeader text="Health Check-Ins" />
        <SubHeader text="How You Feel is How We Succeed" />
        <div className={container}>
          <Rectangle styleName="account" styleName2="assessment">
            <div className={marginSides}>
              <div className={extraHeader}>
                Tell Us About Your Physical Health
              </div>
              <div className={questionText}>
                Accounting for physical illness and injury, how would you
                describe your <b>physical</b> health today:
              </div>
              <RadioButton
                choices={[
                  {
                    Choice: "Excellent",
                    Value: 1,
                    "Assessment": true
                  },
                  {
                    Choice: "Very good",
                    Value: 2,
                    "Assessment": true
                  },
                  {
                    Choice: "Good",
                    Value: 3,
                    "Assessment": true
                  },
                  {
                    Choice: "Fair",
                    Value: 4,
                    "Assessment": true
                  },
                  {
                    Choice: "Poor",
                    Value: 5,
                    "Assessment": true
                  },
                ]}
                name="physical"
                checked={checked}
                onChange={handleChangePhysical}
                styleName="vertical"
                styleName3="assessment"
                labelStyle="verticalPrices"
                selectedOption={physical}
              />
            </div>
          </Rectangle>
          <Rectangle styleName="account" styleName2="assessment">
            <div className={marginSides}>
              <div className={extraHeader}>
                Tell Us About Your Mental Health
              </div>
              <div className={questionText}>
                Accounting for stress, depression, and problems with emotions,
                how would you describe your <b>mental</b> health today:
              </div>
              <RadioButton
                choices={[
                  {
                    Choice: "Excellent",
                    Value: 1,
                    "Assessment": true
                  },
                  {
                    Choice: "Very good",
                    Value: 2,
                    "Assessment": true
                  },
                  {
                    Choice: "Good",
                    Value: 3,
                    "Assessment": true
                  },
                  {
                    Choice: "Fair",
                    Value: 4,
                    "Assessment": true
                  },
                  {
                    Choice: "Poor",
                    Value: 5,
                    "Assessment": true
                  },
                ]}
                name="mental"
                checked={checked}
                onChange={handleChangeMental}
                styleName="vertical"
                styleName3="assessment"
                labelStyle="verticalPrices"
                selectedOption={mental}
              />
            </div>
          </Rectangle>
          <div className={bottomButtons}>
            <Button
              text="Save"
              styleName="buttonSmaller"
              name="submit"
              styleName2={(!mental || !physical) && "Disabled"}
              onClick={() => submit()}
            />
            <div
              className={cancel}
              onClick={() => history.push("/update-health")}
            >
              Cancel
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchUser,
  setNotificationText,
  resetNotification,
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  notificationsReducer: state.notificationsReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthCheckIn);
