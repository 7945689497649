import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import Button from '../../components/Button/Button';
import RadioButton from '../../components/RadioButton/RadioButton';
// import { throwError } from '../../../error/Error';
import MainHeader from '../../components/Headers/MainHeader';
import SubHeader from '../../components/Headers/SubHeader';
import styles from './Vitals.module.scss';
import history from '../../history';
import useFormValidation from "../../hooks/useFormValidation";
import validateHealth from "../../hooks/validateHealth";
import ScrollToTopOnMount from '../../ScrollRestoration';
import classNames from 'classnames/bind';
import { useAuth0 } from "../../auth/Auth";
import { postData } from '../../api/api';
import { throwError } from '../../error/Error';

// pre life assessment- collecting demographic data
const Vitals = () => {

    const cmToInches = 2.54;

    const INITIAL_STATE = {
        dateOfBirthDay: "",
        dateOfBirthMonth: "",
        dateOfBirthYear: "",
        gender: "",
        currentWeight: "",
        heightFeet: "",
        heightIn: "",
    }

    const { user } = useAuth0();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Vitals'
        });
    }, [])

    useEffect(() => {
        const fbq = window.fbq || function () { };
        fbq('trackCustom', 'Vitals', { content_name: 'Vitals' });
    }, [])



    const updateProfileSubmit = async () => {
        const { dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear, gender, currentWeight, heightFeet, heightIn } = values

        let tempTok;
        if (((user || {}).sub || {}).length > 0) {
            tempTok = user.sub
        } else {
            tempTok = localStorage.getItem('tempToken')
        }

        const weightParam = {
            token: tempTok,
            "weight": currentWeight
        }

        const profileParams = {
            token: tempTok,
            "sex": gender === "Other / Decline to Answer" ? "Prefer Not to Say" : gender,
            "dateOfBirth": (new Date(dateOfBirthYear, dateOfBirthMonth - 1, dateOfBirthDay)).toISOString(),
            "height": Math.round((parseInt(heightFeet) * 12 + parseInt(heightIn)) * cmToInches)
        };

        const postRequestBody = {};

        postData(postRequestBody, weightParam, "weight").then(
            response => {
                if (JSON.parse(response).Error) {
                    console.log(JSON.parse(JSON.parse(response).Msg).message)
                }
                else {
                    postData(postRequestBody, profileParams, "profile").then(
                        response => {
                            if (JSON.parse(response).Error) {
                                console.log(JSON.parse(JSON.parse(response).Msg).message)
                            }
                            else {
                                history.push('/agree-to-terms');
                            }
                        })
                        .catch(
                            error =>
                                throwError(error)
                        )
                }
            })
            .catch(
                error =>
                    throwError(error)
            )
    }

    const checked = (selectedOption, option) => {
        return selectedOption === option
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, validateHealth, updateProfileSubmit);

    const { contentContainer, container, birthDate, birthDateYear, specificInputBirth, specificInputWeight,
        inputWeight, inputDescriptionWeight, fieldText, slash, birthFields, errorText,
        weight, height, inputHeight, inputDescriptionHeight, errorInput, errorDisabledButton, genderField } = styles;
    return (
        <>
            <Prompt
                message={(params) =>
                    params.pathname === '/pre-assessment' ? "Are you sure you want to go back to assessments?  You will lose all progress and start from the first question." : true}
            />

            <ScrollToTopOnMount />
            <div className={container}>
                <div className={styles.main}>
                    <MainHeader text="Vital Measurements" name="vitals" />
                    <SubHeader text="All fields are required" />
                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className={contentContainer}>
                            <div className={fieldText}>Height</div>
                            {errors.height && <p className={errorText}>*{errors.height}</p>}

                            <div className={height}>
                                <div className={inputDescriptionHeight}>Feet:</div>
                                <input
                                    className={classNames(errors.height && errorInput, inputHeight)}
                                    name="heightFeet"
                                    type="number"
                                    onChange={handleChange}
                                    value={values.heightFeet}
                                    onBlur={handleBlur}
                                />
                                <div className={inputDescriptionHeight}>Inches:</div>
                                <input
                                    className={classNames(errors.height && errorInput, inputHeight)}
                                    name="heightIn"
                                    type="number"
                                    onChange={handleChange}
                                    value={values.heightIn}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className={specificInputWeight}>
                                <div className={fieldText}>Current Weight</div>
                                {errors.currentWeight && <p className={errorText}>*{errors.currentWeight}</p>}
                                <div className={weight}>
                                    <input
                                        className={classNames(errors.currentWeight && errorInput, inputWeight)}
                                        name="currentWeight"
                                        onBlur={handleBlur}
                                        type="number"
                                        onChange={handleChange}
                                        value={values.currentWeight}
                                    />
                                    <div className={inputDescriptionWeight}>lb</div>
                                </div>
                            </div>
                            <div className={specificInputBirth}>
                                <div className={fieldText}>Date Of Birth</div>
                                {errors.dateOfBirth && <p className={errorText}>*{errors.dateOfBirth}</p>}
                                <div className={birthFields}>
                                    <input
                                        className={classNames(errors.dateOfBirth && errorInput, birthDate)}
                                        name="dateOfBirthMonth"
                                        placeholder="MM"
                                        type="number"
                                        onChange={handleChange}
                                        value={values.dateOfBirthMonth}
                                        onBlur={handleBlur}
                                    />
                                    <div className={slash}>
                                    </div>
                                    <input
                                        className={classNames(errors.dateOfBirth && errorInput, birthDate)}
                                        name="dateOfBirthDay"
                                        placeholder="DD"
                                        type="number"
                                        onChange={handleChange}
                                        value={values.dateOfBirthDay}
                                        onBlur={handleBlur}
                                    />
                                    <div className={slash}>
                                    </div>
                                    <input
                                        className={classNames(errors.dateOfBirth && errorInput, birthDateYear)}
                                        name="dateOfBirthYear"
                                        placeholder="YYYY"
                                        type="number"
                                        onChange={handleChange}
                                        value={values.dateOfBirthYear}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>
                            <div className={fieldText}>Gender</div>
                            <div className={genderField}>
                                {errors.gender && <p className={errorText}>*{errors.gender}</p>}
                                <RadioButton
                                    choices={[
                                        {
                                            "Choice": "Female",
                                            "Value": "Female"
                                        },
                                        {
                                            "Choice": "Male",
                                            "Value": "Male"
                                        },
                                        {
                                            "Choice": "Other / Decline to Answer",
                                            "Value": "Other / Decline to Answer"
                                        }
                                    ]}
                                    name="gender"
                                    checked={checked}
                                    onChange={handleChange}
                                    selectedOption={values.gender}
                                    styleName="vertical"
                                    styleNameError={errors.gender && "errorInput"}
                                    onBlur={handleBlur}
                                />
                            </div>

                            {Object.entries(errors).length === 0 && errors.constructor === Object
                                ?
                                <Button
                                    text="Submit Health Information"
                                    styleName="buttonSmaller"
                                    name="submitPreAssess"
                                    disabled={isSubmitting}
                                    buttonType="submit" /> :
                                <div className={errorDisabledButton}>
                                    <p className={styles.errorText2}>Please fix errors and resubmit</p>
                                    <Button
                                        text="Submit Health Information"
                                        styleName="buttonSmaller"
                                        name="submitPreAssess"
                                        styleName2="Disabled" />
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}


export default Vitals;
