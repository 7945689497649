import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../auth/Auth";
import * as moment from 'moment';
import MainHeader from '../../components/Headers/MainHeader';
import Rectangle from '../../components/Rectangle/Rectangle';
import MasonryLayout from '../../components/MasonryLayout/MasonryLayout';
import useWindowDimensions from '../../hooks/WindowDimensions';
import EditPasswordModal from './EditPasswordModal/EditPasswordModal';
import EmailChangedModal from './EditPasswordModal/EmailChangedModal';
import PasswordChangedModal from './EditPasswordModal/PasswordChangedModal';
import EditVitalsModal from './EditVitalsModal/EditVitalsModal';
import ConfirmSubscriptionChangeModal from './ConfirmSubscriptionChangeModal/ConfirmSubscriptionChangeModal';
import styles from './Account.module.scss';
import ReactRouterPropTypes from 'react-router-prop-types';
import history from "../../history";

import AppleIcon from "../../assets/AppleIcon.png";
import GoogleIcon from "../../assets/GoogleIcon.png";
import FacebookIcon from "../../assets/IconFacebook.png";

import Button from '../../components/Button/Button';

import { connect } from 'react-redux';
import { fetchUser } from '../../redux/ducks/user';
import { setNotificationText, resetNotification } from '../../redux/ducks/notifications';

import IconScale from '../../assets/IconScale.svg';
import IconAssessment from '../../assets/IconAssessment.svg';
import { dateFormattedTime, dateFormatted } from '../../api/sharedFunctions';

const Account = (props) => {

    const { user } = useAuth0();

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showPasswordChangedModal, setShowPasswordChangedModal] = useState(false);
    const [showEmailChangedModal, setShowEmailChangedModal] = useState(false);
    const [showVitalsModal, setShowVitalsModal] = useState();

    const toggleModalPassword = () => {
        setShowPasswordModal(!showPasswordModal);
    }

    const toggleModalVitals = () => {
        setShowVitalsModal(!showVitalsModal);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const togglePasswordModal = () => {
        setShowPasswordChangedModal(!showPasswordChangedModal);
    }
    const toggleEmailModal = () => {
        setShowEmailChangedModal(!showEmailChangedModal);
    }

    const { userReducer: { userData }, fetchUser } = props;
    const { notificationsReducer: { notification }, setNotificationText, resetNotification } = props;

    useEffect(() => {
        !userData && fetchUser(user.sub)
    }, [userData, fetchUser, user.sub]);

    useEffect(() => {
        if (notification !== "") {
            const timer = setTimeout(() => {
                resetNotification()
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [notification, resetNotification]);

    const { width } = useWindowDimensions();
    const { manageAccout, container, panelContainer, panelHeader, panelHeader2, panelField, line,
        panelResponseMember, panelResponseLogin,
        edit, panelText, panelText2, panelText3, panelResponseMember2, basicLogo,
        preButtonText, buttonContainer, notifyText, notificationContainer,
        loginButtonApple, loginButtonFacebook, loginButtonGoogle
    } = styles;

    const toFeet = (n) => {
        var realFeet = ((n * 0.393700) / 12);
        var feet = Math.floor(realFeet);
        var inches = Math.round((realFeet - feet) * 12);
        return feet + "'" + inches + '"';
    }


    return (
        <>
            {notification !== "" && <div className={notificationContainer}>
                <div className={notifyText}>{notification}</div>
            </div>}
            <div className={manageAccout}>
                {userData && <EditPasswordModal toggleModal={toggleModalPassword} showPasswordModal={showPasswordModal} userData={userData} fetchUser={fetchUser} togglePasswordModal={togglePasswordModal} toggleEmailModal={toggleEmailModal} token={user.sub} />}
                {userData && <EditVitalsModal toggleModal={toggleModalVitals} showVitalsModal={showVitalsModal} userData={userData} toggleModalVitals={toggleModalVitals} fetchUser={fetchUser} token={user.sub} setNotificationText={setNotificationText} />}
                <ConfirmSubscriptionChangeModal toggleModal={toggleConfirmModal} showConfirmModal={showConfirmModal} userData={userData} fetchUser={fetchUser} token={user.sub} setNotificationText={setNotificationText} />
                <PasswordChangedModal toggleModal={togglePasswordModal} showModal={showPasswordChangedModal} />
                <EmailChangedModal toggleModal={toggleEmailModal} showModal={showEmailChangedModal} />
                <MainHeader text="My Account" />
                <div className={container}>
                    <MasonryLayout columns={width <= 760 ? 1 : 2} gap={0}>
                        <Rectangle styleName="account" styleName2="member">
                            <div className={panelContainer}>
                                <div className={panelHeader}>My Membership</div>
                                {userData && <div className={panelField}><div className={panelText}>Name: </div><div className={panelResponseMember} name="fullName">{`${userData.Profile.FirstName} ${userData.Profile.MiddleName} ${userData.Profile.LastName} ${userData.Profile.Suffix}`}</div></div>}
                                {userData && <div className={panelField}><div className={panelText}>Member Since: </div><div className={panelResponseMember}>{moment(new Date(userData.Profile.MemberSince)).format("MM/DD/YYYY")}</div></div>}
                            </div>
                        </Rectangle>
                        <Rectangle styleName="account" styleName2="member">
                            <div className={panelContainer}>
                                <div className={panelHeader2}>My Vital Measurements</div>
                                <img src={IconScale} alt="vital logo" className={basicLogo} onClick={toggleModalVitals} />
                                {userData && userData.Profile.DateOfBirth !== "" && <div className={panelField}><div className={panelText3}>Date of Birth: </div><div className={panelResponseMember}>{moment(new Date(userData.Profile.DateOfBirth)).format("MM/DD/YYYY")}</div></div>}
                                {userData && <div className={panelField}><div className={panelText3}>Gender: </div><div className={panelResponseMember}>{userData.Profile.Sex}</div></div>}
                                {userData && <div className={panelField}><div className={panelText3}>Height: </div><div className={panelResponseMember2}>{toFeet(userData.Profile.Height)}</div></div>}
                                {userData && <div className={panelField}><div className={panelText3}>Weight: </div><div className={panelResponseMember2}>{userData.Weight.Current} lbs.</div></div>}
                                <div className={edit} onClick={toggleModalVitals} name="editLogin">Update Measurements</div>
                            </div>
                        </Rectangle>
                        <Rectangle styleName="account" styleName2="assessment">
                            <div className={panelContainer}>
                                <div className={panelHeader2}>My Health Assessment</div>
                                <img src={IconAssessment} alt="assessment logo" className={basicLogo}
                                    onClick={() => {
                                        history.push({ pathname: `/health-assessment-update`, state: { screen: "Account" } })
                                    }} />
                                {userData && <div className={panelField}><div className={panelText3}>Last Assessment: </div><div className={panelResponseMember}>{dateFormatted(userData.AssessmentInfo.LastCompleteAssessmentDate)} at {dateFormattedTime(userData.AssessmentInfo.LastCompleteAssessmentDate)}</div></div>}
                                {userData && userData.AssessmentInfo.Diagnoses !== null && <div className={line} />}
                                {userData && userData.AssessmentInfo.Diagnoses !== null && <div className={panelField}><div className={panelText3}>Diagnoses: </div><div className={panelResponseMember}>{userData.AssessmentInfo.Diagnoses.map(
                                    (item, index) => <div key={index}>{item}{index !== (userData.AssessmentInfo.Diagnoses.length - 1) ? ',' : ''}</div>
                                )}</div></div>}
                                {userData && userData.AssessmentInfo.AreasOfDiscomfort !== null && <div className={line} />}
                                {userData && userData.AssessmentInfo.AreasOfDiscomfort !== null && <div className={panelField}><div className={panelText3}>Areas of Discomfort: </div><div className={panelResponseMember}>{userData.AssessmentInfo.AreasOfDiscomfort.map(
                                    (item, index) => <div key={index}>{item}{index !== (userData.AssessmentInfo.AreasOfDiscomfort.length - 1) ? ',' : ''}</div>
                                )}</div></div>}
                                {userData && userData.AssessmentInfo.Conditioning !== null && <div className={line} />}
                                {userData && userData.AssessmentInfo.Conditioning !== null && <div className={panelField}><div className={panelText3}>Conditioning: </div><div className={panelResponseMember}>{userData.AssessmentInfo.Conditioning}</div></div>}
                                {userData && userData.AssessmentInfo.FallRisk !== null && <div className={line} />}
                                {userData && userData.AssessmentInfo.FallRisk !== null && <div className={panelField}><div className={panelText3}>Fall Risk(s): </div>
                                
                                
                                <div className={panelResponseMember}>
                                Fall Risk Score: {userData.AssessmentInfo.FallRiskScore > 5 ? 5 : userData.AssessmentInfo.FallRiskScore}/5
                                <br/>
                                <br/>
                                    {userData.AssessmentInfo.FallRisk.map(
                                    (item, index) => <div key={index}>{item}{index !== (userData.AssessmentInfo.FallRisk.length - 1) ? ',' : ''}</div>
                                )}</div></div>}
                                <div className={line} />
                                <div className={preButtonText}>Had a health change? Update your Health Assessment so we can adjust your Wellthon workouts.</div>
                                <div className={buttonContainer}>
                                    <Button
                                        text="Update Health Assessment"
                                        buttonType="submit"
                                        styleName="buttonSmallerLeftRight"
                                        name="updateHealthAssessment"
                                        onClick={() => {
                                            history.push({ pathname: `/health-assessment-update`, state: { screen: "Account" } })
                                        }}
                                    />
                                </div>
                            </div>
                        </Rectangle>
                        {(user.sub.slice(0, 5) !== "apple" && user.sub.slice(0, 6) !== "google" && user.sub.slice(0, 8) !== "facebook") ? <Rectangle styleName="account" styleName2="login">
                            <div className={panelContainer}>
                                <div className={panelHeader}>My Login</div>
                                {/* {user.sub.slice(0, 5) !== "apple" ? <img src={appleContinue} alt="apple continue" className={styles.appleContinue} /> : */}
                                {userData && <div className={panelField}>
                                    <div className={panelText2}>Email:</div>
                                    <div className={panelResponseLogin}>{userData.Profile.Email}</div >
                                </div>}
                                <div className={panelField}><div className={panelText2}>Password:</div><div className={panelResponseLogin}>&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;</div></div>
                                <div className={edit} onClick={toggleModalPassword} name="editLogin">Edit</div>
                                {/* } */}
                            </div>
                        </Rectangle>:
                        <Rectangle styleName="account" styleName2="login">
                        <div className={panelContainer}>
                            <div className={panelHeader}>My Login</div>
                            {user.sub.slice(0, 5) === "apple" && <div className={loginButtonApple}><img src={AppleIcon} alt="apple continue" className={styles.logoContinue} />Sign In With Apple</div>}
                            {user.sub.slice(0, 6) === "google" && <div className={loginButtonGoogle}><img src={GoogleIcon} alt="google continue" className={styles.logoContinue} />Sign In With Google</div>}
                            {user.sub.slice(0, 8) === "facebook" && <div className={loginButtonFacebook}><img src={FacebookIcon} alt="facebook continue" className={styles.logoContinue} />Sign In With Facebook</div>}
                            <div className={edit} onClick={toggleModalPassword} name="editLogin">Edit</div>
                        </div>
                    </Rectangle>
                        }
                        {user.sub.slice(0, 8) === "facebook" && <Rectangle styleName="account" styleName2="login">
                            <div className={panelContainer}>
                                <div className={panelHeader}>Update Your Login</div>
                                {/* {user.sub.slice(0, 5) !== "apple" ? <img src={appleContinue} alt="apple continue" className={styles.appleContinue} /> : */}
                                {userData && <div className={panelField}>
                                    <div className={panelText2}>Email:</div>
                                    <div className={panelResponseLogin}>{userData.Profile.Email}</div >
                                </div>}
                                <div className={edit} onClick={toggleModalPassword} name="editLogin">Update Email</div>
                                {/* } */}
                            </div>
                        </Rectangle>}
                    </MasonryLayout>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    fetchUser,
    setNotificationText,
    resetNotification
}

const mapStateToProps = state => ({
    userReducer: state.userReducer,
    notificationsReducer: state.notificationsReducer
})

Account.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
