import React, { useState } from 'react';
import RadioButton from '../../../components/RadioButton/RadioButton';
import styles from './VideoModals.module.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const RestartModal = (props) => {

    const { showModal, toggleModal, restartSegment, restartVideos, play, type } = props;
    const { main, headerText, text, modal, overlay } = styles;

    const [selection, setSelection] = useState("");

    const typeText = "Restart " + type

    const submitSelection = async (selectedValue) => {
        if (selectedValue === typeText) {
            toggleModal()
            setSelection("")
            restartSegment()
        }
        else if (selectedValue === "Restart from the beginning") {
            toggleModal()
            setSelection("")
            restartVideos()
        }
        else if (selectedValue === "Resume workout") {
            toggleModal()
            setSelection("")
            play()
        }
    }

    // handle change is run every time an input changes
    const handleChange = (event) => {
        setSelection(event.target.value);
        const selectedValue = event.target.value;
        setTimeout(() => {
            submitSelection(selectedValue)
        }, 700);
    }

    const checked = (selectedOption, option) => {
        console.log("SELECTED: ", selectedOption, " OPTION: ", option)
        return selectedOption === option
    }

    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <section className={main}>
                <div className={headerText}>Restart Workout?</div>
                <div className={text}>
                    Do you want to:
                    </div>
                <RadioButton
                    choices={[
                        {
                            "Choice": typeText,
                            "Value": 1
                        },
                        {
                            "Choice": "Restart from the beginning",
                            "Value": 2
                        },
                        {
                            "Choice": "Resume workout",
                            "Value": 3
                        }
                    ]}
                    name="selection"
                    checked={checked}
                    onChange={handleChange}
                    styleName="verticalFlexStart"
                    selectedOption={selection}
                />
            </section>
        </Modal>
    )
}

export default RestartModal;


