import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import history from './history';

// scroll to top is used for navigating to different pages
// put the user on the top of the page
export const ScrollToTop = ({ children, location: { pathname } }) => {
    useEffect(() => {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      }
    }, []);
  
    return <Fragment>{children}</Fragment>;
  };
  
export default withRouter(ScrollToTop);

// import {useEffect} from 'react';
// import { withRouter } from 'react-router-dom';

// export const ScrollToTop = ({ children, location: { pathname } }) => {
//     useEffect(() => {
//       window.scrollTo(0, 0);
//     }, [pathname]);
  
//     return children || null;
//   };
  
// export default withRouter(ScrollToTop);