import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../auth/Auth";
import Button from "../../components/Button/Button";
import Rectangle from "../../components/Rectangle/Rectangle";
import MainHeader from "../../components/Headers/MainHeader";
import ReactRouterPropTypes from "react-router-prop-types";
import history from "../../history";
import IconStrengthRed from "../../assets/IconStrengthRed.png";
import IconCardioRed from "../../assets/IconCardioRed.png";

import styles from "./PreviewWorkoutScreen.module.scss";

import { connect } from "react-redux";
import { fetchUser } from "../../redux/ducks/user";
import { resetNotification } from "../../redux/ducks/notifications";
import { setTime } from "../../redux/ducks/workouts";

const formatTime = (time) => {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

const PreviewWorkoutScreen = (props) => {
  const { user } = useAuth0();
  const {
    notificationsReducer: { notification },
    resetNotification,
  } = props;

  useEffect(() => {
    if (notification !== "") {
      const timer = setTimeout(() => {
        resetNotification();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification, resetNotification]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "PreviewWorkout",
    });
  }, []);

  const {
    userReducer: { userData },
    fetchUser,
    setTime,
  } = props;

  useEffect(() => {
    !userData && fetchUser(user.sub);
    // also need to call if reset redux
    // this comes from the health assessment
  }, [userData, fetchUser, user.sub]);

  const submit = async () => {
    setTime(minutes);
    history.push("/workout");
  };

  const workoutPreview = JSON.parse(localStorage.getItem('workoutPreview'));
  const minutes = localStorage.getItem('previewMinutes');

  const [workoutType, ] = useState(
    userData && userData.Workouts.NextWorkoutType
  );

  const returnHome = async () => {
	  history.push('/home');
  };

  const {
    dashboard,
    preview,
    notifyText,
    notificationContainer,
    greyTextUnderlined,
    workoutPreviewText,
    workoutPreviewTextBold,
    panelContainer,
    bullet,
    timeText,
    timeTextOrange,
    listOfWhat,
    workoutPreviewMainTextLessMargin,
    iconFlex,
  } = styles;

  return (
    <>
      {notification !== "" && (
        <div className={notificationContainer}>
          <div className={notifyText}>{notification}</div>
        </div>
      )}

      {userData && (
        <div className={dashboard}>
          {(
            <div className={preview}>
              <div className={iconFlex}>
                <img
                  src={workoutType === "Cardio" ? IconCardioRed : IconStrengthRed}
                  alt="cardio"
                  className={styles.workoutIcon2}
                />
                <MainHeader
                  text={`Preview ${userData.Workouts.NextWorkoutType} Workout`}
                  name="previewWorkout"
                  styleName="mainHeaderPreviewWorkout"
                />
              </div>
              <Button
                text="Start Workout"
                styleName="triangle"
                onClick={() => submit()}
                name="startWorkout"
              />
              <div className={workoutPreviewText}>
                This{" "}
                <span className={workoutPreviewTextBold}>
                  {minutes}-minute {userData.Workouts.NextWorkoutType}
                </span>{" "}
                workout includes
                <br />
                safe-for-you versions of the following exercises.
              </div>

              {workoutPreview.Exercises.map((workout, index) => {
                  return (
                    <Rectangle styleName="previewWorkout" key={index}>
                      <div className={panelContainer}>
                        <div className={workoutPreviewMainTextLessMargin}>
                          {workout.Title} ({formatTime(workout.Duration)})
                        </div>
                        {workout.ExerciseDescriptions.map(
                          (description, index) => {
                            return (
                              <div className={bullet} key={index}>
                                {description}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </Rectangle>
                  );
                })}

              <div className={timeText}>
                Total time:{" "}
                <span className={timeTextOrange}>
                  {Math.round(
                    workoutPreview.Exercises.map(
                      (workout) => workout.Duration
                    ).reduce((a, b) => a + b, 0) / 60
                  )}{" "}
                  minutes
                </span>
              </div>
              <div className={workoutPreviewMainTextLessMargin}>
                Suggested Equipment:
              </div>

              {workoutType === "Cardio" ? (
                <div className={listOfWhat}>
                  <div className={bullet}>A sturdy chair with no arms</div>
                  <div className={bullet}>
                    Multiple pairs of light dumbbells of different weights
                  </div>
                  <div className={bullet}>An exercise mat</div>
                </div>
              ) : (
                <div className={listOfWhat}>
                  <div className={bullet}>A sturdy chair with no arms</div>
                  <div className={bullet}>
                    Multiple pairs of heavy dumbbells of different weights
                  </div>
                  <div className={bullet}>An exercise mat</div>
                </div>
              )}
              <Button
                text="Start Workout"
                styleName="triangle"
                onClick={() => submit()}
                name="startWorkout"
              />

              <div className={greyTextUnderlined} onClick={() => returnHome()}>
                Adjust Workout Type or Length
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  fetchUser,
  setTime,
  resetNotification,
};

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  notificationsReducer: state.notificationsReducer,
  workoutsReducer: state.workoutsReducer,
});

PreviewWorkoutScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewWorkoutScreen);
