import React, { useState, useEffect} from 'react';
import styles from './Login.module.scss';
import { useAuth0 } from '../../../auth/Auth';
import WellthonLogoSVG from '../../../assets/WhiteLogo.svg';
import { Link } from 'react-router-dom'
import Button from '../../../components/Button/Button';
import useFormValidation from "../../../hooks/useFormValidation";
import validateLogin from "../../../hooks/validateLogin";
import classNames from 'classnames/bind';
import AppleIcon from "../../../assets/AppleIcon.png";
import GoogleIcon from "../../../assets/GoogleIcon.png";
import FacebookIcon from "../../../assets/IconFacebook.png";
import history from "../../../history";


const INITIAL_STATE = {
    email: "",
    password: ""
};

const Login = (props) => {

    const [error, setError] = useState("")

    useEffect(()=> {
        debugger;
        if (((history.location.state || {}).Error || {}).length > 0) {
            setError(history.location.state.Error)
        }
    },[])

    console.log("LOGIN", props);

    const { login, signUpApple, signUpGoogle,
        signUpFacebook,
        authLoginError } = useAuth0();

    const authenticateUser = async () => {
        const { email, password } = values
        await login(email, password);
    }

    const [passwordIsMasked, setPasswordIsMasked] = useState(true);
    const [thirdParty, setThirdParty] = useState(false);

    const togglePasswordMask = () => {
        setPasswordIsMasked(!passwordIsMasked)
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting
    } = useFormValidation(INITIAL_STATE, validateLogin, authenticateUser);

    const { main, headerText, background, content, input, bottomButtons,
        loginHelp, logoContainer, loginLogo,
        errorInput, errorText, passwordInputContainer, horizontal, or, flexRow, inlineText, loginButtonApple, loginButtonFacebook, loginButtonGoogle } = styles;

    return (
        <div className={background}>
            <section className={main}>
                <form onSubmit={handleSubmit}>
                    <div className={logoContainer}>
                        <img src={WellthonLogoSVG} alt="wellthon logo" className={loginLogo} />
                        <div className={styles.loginText}>Member Sign In</div>
                    </div>
                    
                    <div className={content}>
                        {error !== "" && <p className={styles.errorText}>{error}</p>}
                        {authLoginError !== null && <p className={styles.errorText}>*{authLoginError[0]}</p>}
                        {authLoginError !== null && authLoginError[1] !== undefined && <p className={styles.errorText}>*{authLoginError[0]}</p>}
                        {errors.email && !thirdParty && <p className={styles.errorText}>*{errors.email}</p>}
                        <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            value={values.email}
                            className={classNames(errors.email && !thirdParty && errorInput, input)}
                            autoComplete="off"
                            id="login-email"
                            placeholder="Enter your email address here"
                        />
                        {errors.password && !thirdParty && <p className={errorText}>*{errors.password}</p>}
                        <div className={passwordInputContainer}>
                            <input
                                onChange={handleChange}
                                type={passwordIsMasked ? 'password' : 'text'}
                                onBlur={handleBlur}
                                value={values.password}
                                className={classNames(errors.password && !thirdParty && errorInput, input)}
                                name="password"
                                placeholder="Enter your password"
                                id="login-password"
                            />
                            {passwordIsMasked ?
                                <i className={classNames("icon-eye-off", styles.iconStyle)} onClick={() => togglePasswordMask()} /> :
                                <i className={classNames("icon-eye", styles.iconStyle)} onClick={() => togglePasswordMask()} />
                            }
                        </div>
                        <div className={bottomButtons}>
                            <Button
                                disabled={isSubmitting}
                                name="login"
                                buttonType="submit"
                                text="Sign In"
                                styleName="buttonSmaller"
                            />
                            <Link to="/forgot-password">
                                <div className={loginHelp}>Forgot Password?</div>
                            </Link>
                        </div>

                        <div className={flexRow}>
                            <div className={horizontal} />
                            <div className={or}>OR</div>
                            <div className={horizontal} />
                        </div>

                        <div className={loginButtonApple} onClick={() => signUpApple()}><img src={AppleIcon} alt="apple continue" className={styles.logoContinue} />Sign In With Apple</div>

                        <div className={loginButtonFacebook} onClick={() => {
                            setThirdParty(true)
                            signUpFacebook()
                        }}><img src={FacebookIcon} alt="facebook continue" className={styles.logoContinue} />Sign In With Facebook</div>

                        <div className={loginButtonGoogle} onClick={() => {
                            setThirdParty(true)
                            signUpGoogle()
                        }}><img src={GoogleIcon} alt="google continue" className={styles.logoContinue} />Sign In With Google</div>
                    </div>
                    <div className={headerText}>Need an account?  <a className={inlineText} href="https://www.wellthon.com/">Tap here</a></div>
                </form>
            </section>
        </div>
    )
}

export default Login;