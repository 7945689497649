import React from 'react';
import styles from './ConfirmSubscriptionChangeModal.module.scss';
import Button from '../../../components/Button/Button';
import MainHeader from '../../../components/Headers/MainHeader';
import { postData } from '../../../api/api';
import { throwError } from '../../../error/Error';
import * as moment from 'moment';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

const ConfirmSubscriptionChangeModal = (props) => {

    const { token, fetchUser, setNotificationText } = props;

    const submit = async (ev) => {
        ev.preventDefault();

        const params = {
            token,
            autorenew: false
        };
        const postRequestBody = {};
        postData(postRequestBody, params, "payment-info").then(
            response => {
                JSON.parse(response).Error ?
                    console.log(JSON.parse(JSON.parse(response).Msg).message) :
                    fetchUser(token);
                toggleModal();
                setNotificationText("Membership has been updated")

            }).catch(
                error =>
                    throwError(error)
            )
    }

    const { toggleModal,
        showConfirmModal, userData } = props;

    const { content, bottomButtons,
        headerContainer, modal, overlay, cancel, text } = styles;

    return (
        <Modal isOpen={showConfirmModal} className={modal} overlayClassName={overlay}>
            <div className={styles.previewComponent}>
                <div className={content}>
                    <form
                        onSubmit={submit}
                    >
                        <div className={headerContainer}>
                            <MainHeader text="Automatic Membership Renewal" styleName="mainHeaderAltModal" />
                        </div>
                        <div className={text}>{userData && `You’re about cancel Wellthon, which means you’ll lose 
                        access to Wellthon workouts on ${moment(new Date(userData.Subscription.RenewalDate)).format("MM/DD/YYYY")}, the end of current subscription cycle. Are you sure?`}</div>
                        <div className={content}>
                            <div className={bottomButtons}>
                                <Button
                                    buttonType="submit"
                                    text="Yes, Cancel My Membership"
                                    styleName="buttonSmallerSub"
                                />
                                <div className={cancel} onClick={toggleModal}>
                                    No, I want to keep my membership
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

ConfirmSubscriptionChangeModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    showConfirmModal: PropTypes.bool.isRequired
}

export default ConfirmSubscriptionChangeModal;