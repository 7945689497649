import React from "react";
import { useAuth0 } from '../../auth/Auth';
import { withRouter } from 'react-router-dom';
import styles from './NavBarMain.module.scss';
import { animated } from "react-spring";

import PropTypes from 'prop-types';

const NavBarModalRight = withRouter((props) => {

    console.log("NAVBAR MODAL", props);

    const { logout } = useAuth0();

    const { toggleMenu, style } = props;
    const { accountDropdown, accountDropdownItem } = styles;
    return (
        <animated.div className={accountDropdown} style={style}>
            <div className={accountDropdownItem} onClick={() => {
                toggleMenu()
                props.history.push('/home')
            }}>Home</div>
            <div className={accountDropdownItem} onClick={() => {
                toggleMenu()
                props.history.push('/account')
            }}>Manage Account</div>
            <a rel="noopener noreferrer"
                target="_blank"
                className={accountDropdownItem}
                href="https://wellthon.com/help"
                onClick={() => { toggleMenu() }}
            >Help</a>
            <div className={accountDropdownItem}
                onClick={(e) => {
                    e.preventDefault();
                    logout()
                }}>
                Sign Out
            </div>
        </animated.div>
    );
});

NavBarModalRight.propTypes = {
    toggleMenu: PropTypes.func.isRequired
}

export default NavBarModalRight;