import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err) {
      // Display fallback UI
    this.setState({ hasError: true });
    console.log("error", err);
    // You can also log the error to an error reporting service
    Sentry.captureException(err);
  }

  render() {
    if (this.state.hasError) {
    // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}