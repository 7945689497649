import React, { useState } from 'react';
import RadioButton from '../../../components/RadioButton/RadioButton';
import history from '../../../history';
import styles from './VideoModals.module.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const QuitModal = (props) => {

    const { showModal, toggleModal, play, fetchUser, user, resetWorkouts, videoState, id } = props;
    const { main, headerText, text, modal, overlay } = styles;

    const [selection, setSelection] = useState("");

    const submitSelection = async (selectedValue) => {


        if (selectedValue === "<b>Resume</b> your workout") {
            toggleModal()
            setSelection("")
            play()
        }
        else if (selectedValue === "Quit and <b>save</b> your data") {

            let newVideo =
            {
              "id": id,
              "elapsed_time": Math.round(videoState.currentTime),
            }

            const videoObj = JSON.parse(localStorage.getItem('videos'));
            if (videoObj === null) {
                const arr = [];
                arr.push(newVideo);
                localStorage.setItem("videos", JSON.stringify(arr))
            } else {
                videoObj.push(newVideo)
                localStorage.setItem("videos", JSON.stringify(videoObj))
            }
            
            toggleModal()
            setSelection("")
            history.push('/post-workout')
   
        }
        else if (selectedValue === "Quit but <b>discard</b> your data") {
            toggleModal()
            setSelection("")
            fetchUser(user.sub)
            resetWorkouts()
            history.push('/home')
            // window.location.href = '/home'
        }
    }

    // handle change is run every time an input changes
    const handleChange = (event) => {
        setSelection(event.target.value);
        const selectedValue = event.target.value;
        setTimeout(() => {
            submitSelection(selectedValue)
        }, 700);
    }

    const checked = (selectedOption, option) => {
        console.log("SELECTED: ", selectedOption, " OPTION: ", option)
        return selectedOption === option
    }

    return (
        <Modal isOpen={showModal} className={modal} overlayClassName={overlay}>
            <section className={main}>
                <div className={headerText}>Workout Stopped!</div>
                <div className={text}>
                    Do you want to:
                    </div>
                <RadioButton
                    choices={[
                        {
                            "Choice": "<b>Resume</b> your workout",
                            "Value": 1
                        },
                        {
                            "Choice": "Quit and <b>save</b> your data",
                            "Value": 2
                        },
                        {
                            "Choice": "Quit but <b>discard</b> your data",
                            "Value": 3
                        }
                    ]}
                    name="selection"
                    checked={checked}
                    onChange={handleChange}
                    styleName="verticalFlexStart"
                    selectedOption={selection}
                />
            </section>
        </Modal>
    )
}

export default QuitModal;
