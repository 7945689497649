import React from 'react';
import Button from '../../../components/Button/Button';
import styles from './VideoModals.module.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ToggleModal = props => {

    const { showModal, toggleToggleModal } = props;

    const { noWorkoutText, center, modalSmaller, overlay, main2, cancel } = styles;

    return (
        <Modal isOpen={showModal} className={modalSmaller} overlayClassName={overlay}>
            <section className={main2}>
                <div className={noWorkoutText}>
                    Wellthon recommends you alternate between Strength and Cardio workouts. Use this control to choose your own workout focus.
            </div>
                <div className={center}>
                    <Button text="OK" styleName="buttonSmaller" onClick={() =>
                        toggleToggleModal()
                    } name="ok" />
                    <div className={cancel} onClick={() => {
                        toggleToggleModal()
                        localStorage.setItem('toggleOff', true)
                    }}>Don't show again</div>
                </div>
            </section>
        </Modal>
    )
}

export default ToggleModal;