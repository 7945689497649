import React from 'react';
import PropTypes from 'prop-types';


const MasonryLayout = props => {
    // columnWrapper object will enable us to dynamically create arrays 
    // that we can use as “buckets” for holding items in each column
    const columnWrapper = {};
    const result = [];

    // create columns
    for (let i = 0; i < props.columns; i++) {
        columnWrapper[`column${i}`] = [];
    }

    //Divide the children into columns
    for (let i = 0; i < props.children.length; i++) {
        const columnIndex = i % props.columns;
        columnWrapper[`column${columnIndex}`].push(
            <div style={{ marginBottom: `${props.gap}px` }} key={i}>
                {props.children[i]}
            </div>
        );
    }

    //   wrapping the items in each column with a div and pushing it into the result array

    for (let i = 0; i < props.columns; i++) {
        result.push(
            <div
                style={{
                    marginLeft: `${i > 0 ? props.gap : 0}px`,
                    flex: 1,
                    width: `${i === 0 && "96%"}`
                }}
                key={i}
                >
                {columnWrapper[`column${i}`]}
            </div>
        );
    }


    return (
        <div style={{ display: 'flex' }}>
            {result}
        </div>
    );


}

MasonryLayout.propTypes = {
    columns: PropTypes.number.isRequired,
    gap: PropTypes.number.isRequired,
};
MasonryLayout.defaultProps = {
    columns: 2,
    gap: 20,
};

export default MasonryLayout;