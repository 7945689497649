import React from "react";
import styles from './Checkbox.module.scss';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
  ''
  console.log("COFFEE", props.name, " ", props.checkboxValue)
  return (
    <label className={classNames(styles[`${props.styleName}`], styles.checkboxContainer)}>
      <input
        type="checkbox"
        id={props.id}
        name={props.name}
        value={props.checkboxValue || {}}
        onChange={props.handleChangeChecked}
        className={styles.checkbox}
        checked={props.checked ? props.checked : props.checkboxValue}
      />
      <span className={styles.checkmark} name={props.name} />
      {props.label}</label>
  )
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),

  name: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  checkboxValue: PropTypes.bool.isRequired,
  setCheckboxValue: PropTypes.func,
};

export default Checkbox;